export enum NoticeStatus {
  ERASED = "ERASED",
  PUBLISHED = "PUBLISHED",
  SCHEDULED = "SCHEDULED",
  PENDING = "PENDING",
  REPUBLISHED = "REPUBLISHED",
}

export enum SectionType {
  PARTNERS = "PARTNERS",
  PODCAST = "PODCAST",
  VIDEO = "VIDEO",
}

export enum templateNews {
  GENERAL = "GENERAL",
  SPECIAL = "SPECIAL",
  ADVERTISER = "ADVERTISER",
  PODCAST = "PODCAST",
}
