import signatureConstants from "./constants";

const { SET_SIGNATURE, SET_SIGNATURES, LOADING, SET_ERROR } =
  signatureConstants;

export const initialState = {
  signature: null,
  signatures: null,
};

const signatureReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SIGNATURE:
      return {
        ...state,
        signature: action.signature,
      };
    case SET_SIGNATURES:
      return {
        ...state,
        signatures: action.signatures,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default signatureReducer;
