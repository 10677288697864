import contactConstants from "./constants";

const { SET_CONTACT, UPDATE_CONTACT, LOADING, SET_ERROR } = contactConstants;

export const initialState = {
  contact: null,
};

const contactReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CONTACT:
      return {
        ...state,
        contact: action.contact,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default contactReducer;
