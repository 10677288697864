import { Button, Form, Input, Modal, Pagination, Select, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
  showAlertSuccesQuestion,
} from "../../../components/Alerts";
import trash from "../../../assets/img/papelera.svg";
import edit from "../../../assets/img/editar.svg";
import styles from "./styles.module.scss";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, singUp, updateUser } from "../../../redux/auth/action";
import moment from "moment";
import Password from "antd/lib/input/Password";

const AdminUsers = () => {
  const { Option } = Select;

  const dispatch = useDispatch();
  const [formCreate] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<any>({});
  const [dataPage, setDataPage] = useState<any>({
    page: 1,
    per_page: 15,
  });

  const { users } = useSelector((state: any) => state.auth);

  useEffect(() => {
    dispatch(getUsers(dataPage));
  }, [dataPage]);

  const showTotal = (from: number, to: number, total: number) => {
    return `${from} - ${to} de ${total}`;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalEdit = () => {
    setIsModalVisibleEdit(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelEdit = () => {
    setIsModalVisibleEdit(false);
  };

  const columns = [
    {
      title: "Nombre",
      render: (row: any) => `${row.firstName} ${row.lastName}`,
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Rol",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Creación",
      render: (row: any) => moment(row.createdAt).format("L"),
    },
    {
      title: "Estado",
      render: (row: any) => (row.status ? "Activo" : "Inactivo"),
    },
    {
      title: "Opciones",
      key: "action",
      render: (row: any) => (
        <>
          <img
            src={edit}
            onClick={() => onHandleEdit(row)}
            className={styles.icons_table}
          />
        </>
      ),
    },
  ];

  const onHandleEdit = (data: any) => {
    setDataEdit(data);
    formEdit.setFieldsValue(data);
    showModalEdit();
  };

  const onFinishCreate = (value: any) => {
    showAlertQuestion(
      "¿Estás seguro que desea crear un nuevo administrador?",
      "Crear",
      () => {
        dispatch(
          singUp(
            value,
            () => {
              formCreate.resetFields();
              handleCancel();
              dispatch(getUsers(dataPage));
              showAlertSucces(
                "¡Administrador creado!",
                "El administrador ha sido creado correctamente"
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };
  const onFinishEdit = (value: any) => {
    if (!value?.firstName || value?.firstName.trim() === "")
      delete value.firstName;
    if (!value?.lastName || value?.lastName.trim() === "")
      delete value.lastName;
    if (!value?.email || value?.email.trim() === "") delete value.email;
    if (!value?.password || value?.password.trim() === "")
      delete value.password;
    if (!value?.role || value?.role.trim() === "") delete value.role;

    value.id = dataEdit.id;
    showAlertQuestion(
      "¿Estás seguro que desea los cambios realizados en el administrador?",
      "Guardar",
      () => {
        dispatch(
          updateUser(
            value,
            () => {
              formEdit.resetFields();
              handleCancelEdit();
              dispatch(getUsers(dataPage));
              showAlertSucces(
                "Administrador actualizado",
                "El administrador ha sido actualizado correctamente"
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  /* size table */
  const divHeight = useRef<any>(null);
  const divOptionsHeight = useRef<any>(null);
  const [height, setHeight] = useState<any>(100);
  const [optionsHeight, setOptionsHeight] = useState<any>(34);

  useEffect(() => {
    setHeight(divHeight?.current?.clientHeight);
  }, []);

  window.addEventListener("resize", (v: any) => {
    setHeight(divHeight?.current?.clientHeight);
    setOptionsHeight(divOptionsHeight?.current?.clientHeight);
  });

  return (
    <>
      <div className={styles.container_signature} ref={divHeight}>
        {/* <div className={styles.line}></div> */}
        <div className={styles.container_header_search} ref={divOptionsHeight}>
          <div>
            <Button
              onClick={showModal}
              htmlType="submit"
              className={styles.button}
              block
            >
              Crear nuevo administrador
            </Button>
          </div>
          <div className={styles.container_search}>
            <div className={styles.container_options}>
              <span className={styles.text_search}>Buscar:</span>
              <Form>
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input_search}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form>
              <Button htmlType="submit" className={styles.search_button} block>
                Buscar
              </Button>
            </div>
            <Pagination
              className={styles.pagination}
              size="small"
              showTotal={() => showTotal(users?.from, users?.to, users?.total)}
              defaultCurrent={users?.current_page}
              defaultPageSize={users?.per_page}
              pageSize={users?.per_page || 15}
              total={users?.total}
              onChange={(page) => setDataPage({ ...dataPage, page })}
            />
          </div>
        </div>

        <div
          className={styles.container_table}
          style={{
            height: `${height - optionsHeight}px`,
            overflowY: "auto",
          }}
        >
          <Table
            columns={columns}
            dataSource={users?.data}
            pagination={false}
            className={styles.information_table}
            rowKey={"id"}
          />
        </div>
        {/* modal create */}
        <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
          <div className={styles.container_content_modal}>
            <span className={styles.title_table}>Nuevo usuario</span>
            <span className={styles.text_table}>Nombre</span>
            <Form form={formCreate}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un nombre",
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>

            <span className={styles.text_table}>Apellido</span>
            <Form form={formCreate}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un nombre",
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>

            <span className={styles.text_table}>Correo</span>
            <Form form={formCreate}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un correo",
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Rol</span>
            <Form form={formCreate}>
              <Form.Item
                name="role"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione un rol",
                  },
                ]}
              >
                <Select
                  placeholder="Elegir Rol"
                  className={styles.select_seccion}
                >
                  <Option className={styles.option_select} value="SUPER">
                    Super
                  </Option>
                  <Option className={styles.option_select} value="PUBLISHER">
                    Redactor/Publicador
                  </Option>
                  <Option className={styles.option_select} value="EDITOR">
                    Redactor
                  </Option>
                </Select>
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Contraseña</span>
            <Form form={formCreate}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba una contraseña",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Form>
            <div className={styles.container_button}>
              <Form form={formCreate} onFinish={onFinishCreate} layout="inline">
                <Form.Item>
                  <Button
                    className={styles.save_button}
                    block
                    htmlType="submit"
                  >
                    Guardar
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={handleCancel}
                    className={styles.cancel_button}
                    block
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>

        {/* modal edit */}
        <Modal
          visible={isModalVisibleEdit}
          footer={null}
          onCancel={handleCancelEdit}
        >
          <div className={styles.container_content_modal}>
            <span className={styles.title_table}>Editar usuario</span>
            <span className={styles.text_table}>Nombre</span>
            <Form form={formEdit}>
              <Form.Item name="firstName">
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Apellido</span>
            <Form form={formEdit}>
              <Form.Item name="lastName">
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>

            <span className={styles.text_table}>Correo</span>
            <Form form={formEdit}>
              <Form.Item name="email">
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Rol</span>
            <Form form={formEdit}>
              <Form.Item name="role">
                <Select
                  placeholder="Elegir Rol"
                  className={styles.select_seccion}
                >
                  <Option className={styles.option_select} value="SUPER">
                    Super
                  </Option>
                  <Option className={styles.option_select} value="PUBLISHER">
                    Redactor/Publicador
                  </Option>
                  <Option className={styles.option_select} value="EDITOR">
                    Redactor
                  </Option>
                </Select>
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Contraseña</span>
            <Form form={formEdit}>
              <Form.Item name="password">
                <Input.Password
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Estado</span>
            <Form form={formEdit}>
              <Form.Item name="status">
                <Select
                  placeholder="Elegir estado"
                  className={styles.select_seccion}
                >
                  <Option className={styles.option_select} value={true}>
                    Activo
                  </Option>
                  <Option className={styles.option_select} value={false}>
                    Inactivo
                  </Option>
                </Select>
              </Form.Item>
            </Form>
            <div className={styles.container_button}>
              <Form form={formEdit} onFinish={onFinishEdit} layout="inline">
                <Form.Item>
                  <Button
                    className={styles.save_button}
                    block
                    htmlType="submit"
                  >
                    Guardar
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={handleCancelEdit}
                    className={styles.cancel_button}
                    block
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AdminUsers;
