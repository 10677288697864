import settingsConstants from "./constants";

const { SET_MENU, SET_SUB_MENU } = settingsConstants;

export const setMenu = (state: boolean) => ({
  type: SET_MENU,
  state,
});

export const setSubMenu = (state: any) => ({
  type: SET_SUB_MENU,
  state,
});
