import bannerConstants from "./constants";

const { SET_BANNER, LOADING, SET_ERROR } = bannerConstants;

export const inicialState = {
    banner: null,
}

const bannerReducer = (state = inicialState, action: any) => {
    switch (action.type) {
        case SET_BANNER:
            return {
                ...state,
                banner: action.banner,
            }
        case LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
            }
        default:
            return state;
    }
}

export default bannerReducer;