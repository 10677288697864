const sectionConstants = {
  SET_SECTION: "SET_SECTION",
  SET_SECTIONS: "SET_SECTIONS",
  UPDATE_SECTION: "UPDATE_SECTION",
  DELETE_SECTION: "DELETE_SECTION",
  LOADING: "LOADING",
  SET_ERROR: "SET_ERROR",
};

export default sectionConstants;
