import React, { useEffect, useState } from 'react'
import { UploadFile } from 'antd/lib/upload/interface';
import { Collapse, Select, Form, Button, Switch, Row, Col, Input, Upload, Typography, Modal } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { getBase64 } from '../../functions/utils/getBase64';
const { Text } = Typography;

interface ModelUpload {
    fileList: Array<UploadFile>,
    setFileList: (newFileList: Array<UploadFile>) => void,
    message: string,
    limit?: number
}

export default function UploadImage({ fileList, setFileList, message, limit = 1 }: ModelUpload) {

    const onChange = ({ fileList: newFileList, file }: { fileList: Array<UploadFile>, file: UploadFile }) => {
        newFileList = newFileList.map(newFile => {
            if (newFile.uid === file.uid) {
                newFile = { ...newFile, status: 'success' }
            }
            return newFile
        })
        console.log({ newFileList, file });
        setFileList(newFileList);
    };
    const [ previewVisible, setPreviewVisible ] = useState(false);
    const [ previewImage, setPreviewImage ] = useState('');
    const [ previewTitle, setPreviewTitle ] = useState('');

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) file.preview = await getBase64(file.originFileObj);
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    };

    return (
        <div>
            <Upload
                name="avatar"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
            >
                {fileList.length < limit &&
                    <div>
                        <PlusOutlined />
                        <Text type="secondary" style={{ marginTop: 8, display: 'inline-block' }}>{message}</Text>
                    </div>
                }
            </Upload>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    )
}