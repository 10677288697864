import React from "react";
import { Image, Radio } from "antd";
import styles from "./styles.module.scss";

/* img module position */
import m1EspacioUno from "../../../assets/notices/module1/espacio_uno.svg";
import m1EspacioDos from "../../../assets/notices/module1/espacio_dos.svg";
import m1EspacioTres from "../../../assets/notices/module1/espacio_tres.svg";

import m2EspacioUno from "../../../assets/notices/module2/m2EspacioUno.svg";
import m2EspacioDos from "../../../assets/notices/module2/m2EspacioDos.svg";

import m3EspacioUno from "../../../assets/notices/module3/m3EspacioUno.svg";

import m4EspacioUno from "../../../assets/notices/module4/m4EspacioUno.svg";
import m4EspacioDos from "../../../assets/notices/module4/m4EspacioDos.svg";
import m4EspacioTres from "../../../assets/notices/module4/m4EspacioTres.svg";
import m4EspacioCuatro from "../../../assets/notices/module4/m4EspacioCuatro.svg";

import m5EspacioUno from "../../../assets/notices/module5/m5EspacioUno.svg";
import m5EspacioDos from "../../../assets/notices/module5/m5EspacioDos.svg";

import m6EspacioUno from "../../../assets/notices/module6/m6EspacioUno.svg";
import m6EspacioDos from "../../../assets/notices/module6/m6EspacioDos.svg";
import m6EspacioTres from "../../../assets/notices/module6/m6EspacioTres.svg";

import m8EspacioUno from "../../../assets/notices/module8/m8EspacioUno.svg";
import m8EspacioDos from "../../../assets/notices/module8/m8EspacioDos.svg";
import m8EspacioTres from "../../../assets/notices/module8/m8EspacioTres.svg";

import m9EspacioUno from "../../../assets/notices/module9/m9EspacioUno.svg";
import m9EspacioDos from "../../../assets/notices/module9/m9EspacioDos.svg";
import m9EspacioTres from "../../../assets/notices/module9/m9EspacioTres.svg";
import m9EspacioCuatro from "../../../assets/notices/module9/m9EspacioCuatro.svg";

import m10EspacioUno from "../../../assets/notices/module10/m10EspacioUno.svg";
import m10EspacioDos from "../../../assets/notices/module10/m10EspacioDos.svg";
import m10EspacioTres from "../../../assets/notices/module10/m10EspacioTres.svg";

import m11EspacioUno from "../../../assets/notices/module11/m11EspacioUno.svg";
import m11EspacioDos from "../../../assets/notices/module11/m11EspacioDos.svg";

import m12EspacioUno from "../../../assets/notices/module12/m12EspacioUno.svg";
import m12EspacioDos from "../../../assets/notices/module12/m12EspacioDos.svg";
import m12EspacioTres from "../../../assets/notices/module12/m12EspacioTres.svg";
import m12EspacioCuatro from "../../../assets/notices/module12/m12EspacioCuatro.svg";

import m13EspacioUno from "../../../assets/notices/module13/m13EspacioUno.svg";

import m15EspacioUno from "../../../assets/notices/module15/m15EspacioUno.svg";
import m15EspacioDos from "../../../assets/notices/module15/m15EspacioDos.svg";
import m15EspacioCuatro from "../../../assets/notices/module15/m15EspacioCuatro.svg";
import m15EspacioCinco from "../../../assets/notices/module15/m15EspacioCinco.svg";

import m18EspacioUno from "../../../assets/notices/module18/m18EspacioUno.svg";
import m18EspacioDos from "../../../assets/notices/module18/m18EspacioDos.svg";
import m18EspacioTres from "../../../assets/notices/module18/m18EspacioTres.svg";
import m18EspacioCuatro from "../../../assets/notices/module18/m18EspacioCuatro.svg";
import m18EspacioCinco from "../../../assets/notices/module18/m18EspacioCinco.svg";
import m18EspacioSeis from "../../../assets/notices/module18/m18EspacioSeis.svg";

import m19EspacioUno from "../../../assets/notices/module19/m19EspacioUno.svg";
import m19EspacioDos from "../../../assets/notices/module19/m19EspacioDos.svg";
import m19EspacioCuatro from "../../../assets/notices/module19/m19EspacioCuatro.svg";
import m19EspacioTres from "../../../assets/notices/module19/m19EspacioTres.svg";

export const OptionsPositionModules = (module: number) => {
  switch (module) {
    case 1:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m1EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m1EspacioDos} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m1EspacioTres} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
        </>
      );
    case 2:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m2EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m2EspacioDos} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m2EspacioDos} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
          <Radio.Button value={4}>
            <div>
              <Image src={m2EspacioDos} preview={false} />
              <p className={styles.text_module}>Espacio Cuatro</p>
            </div>
          </Radio.Button>
          <Radio.Button value={5}>
            <div>
              <Image src={m2EspacioDos} preview={false} />
              <p className={styles.text_module}>Espacio Cinco</p>
            </div>
          </Radio.Button>
        </>
      );
    case 4:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m4EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m4EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m4EspacioTres} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
          <Radio.Button value={4}>
            <div>
              <Image src={m4EspacioCuatro} preview={false} />
              <p className={styles.text_module}>Espacio Cuatro</p>
            </div>
          </Radio.Button>
          <Radio.Button value={5}>
            <div>
              <Image src={m4EspacioCuatro} preview={false} />
              <p className={styles.text_module}>Espacio Cinco</p>
            </div>
          </Radio.Button>
          <Radio.Button value={6}>
            <div>
              <Image src={m4EspacioCuatro} preview={false} />
              <p className={styles.text_module}>Espacio Seis</p>
            </div>
          </Radio.Button>
        </>
      );
    case 5:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m5EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m5EspacioDos} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
        </>
      );
    case 6:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m6EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m6EspacioTres} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m6EspacioDos} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
        </>
      );
    case 8:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m8EspacioDos} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m8EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m8EspacioTres} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
        </>
      );
    case 9:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m9EspacioUno} width={"80px"} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m9EspacioDos} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m9EspacioTres} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
          <Radio.Button value={4}>
            <div>
              <Image src={m9EspacioCuatro} preview={false} />
              <p className={styles.text_module}>Espacio Cuatro</p>
            </div>
          </Radio.Button>
          <Radio.Button value={5}>
            <div>
              <Image src={m9EspacioCuatro} preview={false} />
              <p className={styles.text_module}>Espacio Cinco</p>
            </div>
          </Radio.Button>
        </>
      );
    case 10:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m10EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m10EspacioTres} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
          <Radio.Button value={4}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Cuatro</p>
            </div>
          </Radio.Button>
        </>
      );
    case 11:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m11EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m11EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m11EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
        </>
      );
    case 12:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m12EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m12EspacioDos} width={"80px"} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m12EspacioTres} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
          <Radio.Button value={4}>
            <div>
              <Image src={m12EspacioCuatro} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Cuatro</p>
            </div>
          </Radio.Button>
        </>
      );
    case 13:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m13EspacioUno} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m13EspacioUno} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m13EspacioUno} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
        </>
      );
    case 15:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m15EspacioUno} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m15EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m15EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
          <Radio.Button value={4}>
            <div>
              <Image src={m15EspacioCuatro} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Cuatro</p>
            </div>
          </Radio.Button>
          <Radio.Button value={5}>
            <div>
              <Image src={m15EspacioCinco} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Cinco</p>
            </div>
          </Radio.Button>
          <Radio.Button value={6}>
            <div>
              <Image src={m15EspacioCinco} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Seis</p>
            </div>
          </Radio.Button>
          <Radio.Button value={7}>
            <div>
              <Image src={m15EspacioCinco} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Siete</p>
            </div>
          </Radio.Button>
        </>
      );
    case 16:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio tres</p>
            </div>
          </Radio.Button>
        </>
      );
    case 17:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio tres</p>
            </div>
          </Radio.Button>
          <Radio.Button value={4}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio cuatro</p>
            </div>
          </Radio.Button>
          <Radio.Button value={5}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio cinco</p>
            </div>
          </Radio.Button>
          <Radio.Button value={6}>
            <div>
              <Image src={m10EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio seis</p>
            </div>
          </Radio.Button>
        </>
      );
    case 18:
      return (
        <>
          <Radio.Button value={1}>
            <div style={{ paddingTop: "10px" }}>
              <Image src={m18EspacioUno} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div style={{ paddingTop: "10px" }}>
              <Image src={m18EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div style={{ paddingTop: "10px" }}>
              <Image src={m18EspacioTres} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
          <Radio.Button value={4}>
            <div style={{ paddingTop: "10px" }}>
              <Image src={m18EspacioCuatro} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Cuatro</p>
            </div>
          </Radio.Button>
          <Radio.Button value={5}>
            <div style={{ paddingTop: "10px" }}>
              <Image src={m18EspacioCinco} preview={false} />
              <p className={styles.text_module}>Espacio Cinco</p>
            </div>
          </Radio.Button>
          <Radio.Button value={6}>
            <div style={{ paddingTop: "10px" }}>
              <Image src={m18EspacioSeis} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Seis</p>
            </div>
          </Radio.Button>
        </>
      );
    case 19:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m19EspacioUno} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Uno</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m19EspacioDos} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Dos</p>
            </div>
          </Radio.Button>
          <Radio.Button value={3}>
            <div>
              <Image src={m19EspacioTres} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Tres</p>
            </div>
          </Radio.Button>
          <Radio.Button value={4}>
            <div>
              <Image src={m19EspacioCuatro} width={"90px"} preview={false} />
              <p className={styles.text_module}>Espacio Cuatro</p>
            </div>
          </Radio.Button>
        </>
      );
    default:
      break;
  }
};
