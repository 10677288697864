import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import facebook from "../../../src/assets/img/facebook.svg";
import instagram from "../../../src/assets/img/instagram.svg";
import linkedln from "../../../src/assets/img/linkedln.svg";
import twitter from "../../../src/assets/img/twitter.svg";
import whatsapp from "../../../src/assets/img/whatsapp.svg";
import { imgNoExist } from "../../helpers/img";
import ReactPlayer from "react-player";

const BannerThree = ({ news }: any) => {
  const [imgVideo, setImgVideo] = useState<any>();
  const [imgBanner, setImgBanner] = useState<any>();

  const convertImgVideo = async () => {
    let src: any;
    if (typeof news?.imgVideo === "string") {
      setImgVideo(news?.imgVideo);
      return;
    }

    if (news?.imgVideo) {
      const { imgVideo } = news;
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgVideo);
        reader.onload = () => resolve(reader.result);
      });
    }
    const img = new Image();
    img.src = src;
    setImgVideo(img.src);
  };
  const convertImgBanner = async () => {
    let src: any;
    if (news?.imgBanner) {
      const { imgBanner } = news;
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgBanner);
        reader.onload = () => resolve(reader.result);
      });
    }
    const img = new Image();
    img.src = src;
    setImgBanner(img.src);
  };

  useEffect(() => {
    convertImgVideo();
    convertImgBanner();
  }, [news.imgVideo]);

  return (
    <>
      <div>
        <div className={styles.container_background}>
          <div className={styles.container_banner_especial}>
            <div className={styles.container_text_banner_especial}>
              <span
                style={{ color: "#7D7D7D" }}
                className={styles.text_seccion}
              >
                {news?.section?.title || "Sección"}
              </span>
              {news?.preTitle && (
                <p className={styles.antetitulo_three}>{news?.preTitle}</p>
              )}
              {news?.title && (
                <p className={styles.titulo_three}>{news?.title}</p>
              )}
              {news?.subTitle && (
                <p className={styles.subtitulo_three}>{news?.subTitle}</p>
              )}

              {/* Redes */}
              <div className={styles.container_share}>
                <span className={styles.text_share}>Compartir</span>
                <img
                  src={facebook}
                  height={22}
                  alt="logo_facebook"
                  className={styles.icon_logo}
                />
                <img
                  src={instagram}
                  height={22}
                  alt="logo_instagram"
                  className={styles.icon_logo}
                />
                <img
                  src={linkedln}
                  height={22}
                  alt="logo_linkedln"
                  className={styles.icon_logo}
                />
                <img
                  src={twitter}
                  height={22}
                  alt="logo_twitter"
                  className={styles.icon_logo}
                />
                <img
                  src={whatsapp}
                  height={22}
                  alt="logo_whatsapp"
                  className={styles.icon_logo}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container_img_especial}>
          {news?.video ? (
            <ReactPlayer
              url={news?.video}
              light={news?.imgVideo ? imgVideo : true}
              className={styles.container_foto_pie}
              controls={true}
              playing={news?.imgVideo ? true : false}
            />
          ) : (
            <>
              <div className={styles.container_foto_pie}>
                <img
                  src={(news?.imgBanner && imgBanner) || imgNoExist}
                  alt="img_content"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BannerThree;
