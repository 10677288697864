import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { Card, Col, Row } from "antd";
import spotify from "../../../src/assets/img/spotify.svg";
import image_text from "../../../src/assets/img/image_cigarro.svg";
import twitter from "../../../src/assets/img/twitter_flotante.svg";
import interesar_uno from "../../../src/assets/img/interesar_uno.svg";
import interesar_dos from "../../../src/assets/img/interesar_dos.svg";
import interesar_tres from "../../../src/assets/img/interesar_tres.svg";
import avatar from "../../../src/assets/img/avatar.svg";
import Related from "../Related/Related";
import moment from "moment";
import "moment/locale/es";
import Parser from "html-react-parser";
import { imgNoExist } from "../../helpers/img";

const ContentThree = ({ news }: any) => {
  return (
    <>
      <div className={styles.container_content}>
        <Row>
          <Row gutter={[20, 20]}>
            <Col md={24} lg={18} xl={18} xxl={18}>
              <div className={styles.container_text}>
                <div className={styles.container_firma_especial}>
                  <div className={styles.container_firma_avatar}>
                    <div
                      style={{
                        backgroundImage: `url(${
                          news?.signature1?.img || imgNoExist
                        })`,
                      }}
                      className={styles.container_avatar}
                    ></div>
                    <div>
                      <p className={styles.firma}>
                        Por{" "}
                        <span className={styles.underline}>
                          {news?.signature1?.fullName || `Autor`}
                        </span>
                        {news?.signature2 && (
                          <span className={styles.underline}>
                            {", "}
                            {news?.signature2?.fullName || `Autor`}
                          </span>
                        )}
                        {news?.signature3 && (
                          <span className={styles.underline}>
                            {", "}
                            {news?.signature3?.fullName || `Autor`}
                          </span>
                        )}
                      </p>
                      <span className={styles.firma_noticia}>
                        {moment(news?.createdAt).format("LL")}
                      </span>
                    </div>
                  </div>
                  <span className={styles.firma_noticia}>
                    {moment(news?.createdAt)
                      .locale("es")
                      .startOf("day")
                      .fromNow() || `Lectura: 5 minutos`}
                  </span>
                </div>
                <div id={styles.container_parrafos}>
                  {news?.text && Parser(news?.text)}

                  {/* file */}
                  {news?.file && (
                    <p className={styles.underline}>
                      <a href={news.file} target="_blank" rel="noreferrer">
                        Click Aquí para descargar contenido
                      </a>
                    </p>
                  )}

                  <hr className={styles.hr_spacing} />
                </div>
              </div>
            </Col>

            {/* Relacionadas */}
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <Related />
            </Col>
          </Row>

          {/* te puede interesar */}
          <div className={styles.container_interes}>
            <div className={styles.container_tex_interes}>
              <span className={styles.title_interes}>Te puede interesar</span>
            </div>
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Card
                  className={styles.card_module}
                  cover={<img src={imgNoExist} alt="noticia_relacionada" />}
                >
                  <div className={styles.container_text_card}>
                    <span className={styles.text_card}>
                      Lorem ipsum dolor sit amer, consectetu adipiscing elit
                    </span>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Card
                  className={styles.card_module}
                  cover={<img src={imgNoExist} alt="noticia_relacionada" />}
                >
                  <div className={styles.container_text_card}>
                    <span className={styles.text_card}>
                      Lorem ipsum dolor sit amer, consectetu adipiscing elit
                    </span>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Card
                  className={styles.card_module}
                  cover={<img src={imgNoExist} alt="noticia_relacionada" />}
                >
                  <div className={styles.container_text_card}>
                    <span className={styles.text_card}>
                      Lorem ipsum dolor sit amer, consectetu adipiscing elit
                    </span>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </>
  );
};

export default ContentThree;
