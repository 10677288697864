import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import { setMenu } from "../../redux/settings/action";
import { useNavigate } from "react-router-dom";
import { Drawer, Button, Space, Radio } from "antd";
import { DrawerProps } from "antd/es/drawer";

const Menu = ({ children, ...props }: any) => {
  const { menu } = useSelector((state: any) => state.settings);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setMenu(false));
  };

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("left");

  return (
    <>
      <Drawer
        visible={menu}
        placement={placement}
        onClose={handleClose}
        {...props}
      >
        {children}
      </Drawer>
    </>
  );
};

export default Menu;
