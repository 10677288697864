import api from "../../../helpers/api";

const sliderServices = {
  getAll: () => {
    return api.get("/slider/getAll");
  },

  update: (data: any) => {
    return api.put("/slider/update", {
      data,
    });
  },
};

export default sliderServices;
