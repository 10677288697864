import React from "react";
import { Image, Radio } from "antd";
import styles from "./styles.module.scss";

/* img module position */
import m1EspacioUno from "../../../assets/notices/module1/espacio_uno.svg";
import m1EspacioDos from "../../../assets/notices/module1/espacio_dos.svg";
import m1EspacioTres from "../../../assets/notices/module1/espacio_tres.svg";
import m1EspacioCuatro from "../../../assets/notices/module1/espacio_cuatro.svg";
import m1EspacioCinco from "../../../assets/notices/module1/espacio_cinco.svg";

export const OptionsPositionStaticNews = (module: number) => {
  switch (module) {
    case 1:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m1EspacioCinco} preview={false} />
              <p className={styles.text_module}>Espacio 1er</p>
            </div>
          </Radio.Button>
          <Radio.Button value={2}>
            <div>
              <Image src={m1EspacioCinco} preview={false} />
              <p className={styles.text_module}>Espacio 2do</p>
            </div>
          </Radio.Button>
        </>
      );
    case 12:
      return (
        <>
          <Radio.Button value={1}>
            <div>
              <Image src={m1EspacioCinco} preview={false} />
              <p className={styles.text_module}>Espacio 1to</p>
            </div>
          </Radio.Button>
        </>
      );
    default:
      break;
  }
};
