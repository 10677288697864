import {showAlertError} from "../../components/Alerts";
import authConstants from "./constants";
import authServices from "./services";

const {
    SET_USER,
    SET_USERS,
    SET_LOGIN_ERROR,
    LOADING,
    SET_EMAIL_RECOVER,
    SET_ERROR,
} = authConstants;

export const setUser = (user: any) => ({
    type: SET_USER,
    user,
});

export const setUsers = (users: any) => ({
    type: SET_USERS,
    users,
});

export const setEmailRecover = (email: string) => ({
    type: SET_EMAIL_RECOVER,
    email,
});

export const setLogginError = (loginError: any | null) => ({
    type: SET_LOGIN_ERROR,
    loginError,
});

export const setLoading = (loading: boolean) => ({
    type: LOADING,
    loading,
});

export const setError = (error: any) => ({
    type: SET_ERROR,
    error,
});

export const loginUser = (
    data: { email: string; password: string; remember: boolean },
    callback?: any,
    err?: any
) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        dispatch(setLogginError(null));

        try {
            const {email, password} = data;
            /* peticion con el service */
            const response = await authServices.login(email, password);
            dispatch(setUser(response.data));
            dispatch(setLoading(false));
            localStorage.setItem('authTokenPanel', response.data.authToken);
            if (callback) callback();
        } catch (error: any) {
            console.log("err", error.response.data);
            const {code} = error.response.data;
            if (code === "400-002") {
                dispatch(setLogginError("Usuario o contraseña incorrecto"));
                showAlertError(
                    "Ha ocurrido un error",
                    "Usuario o contraseña incorrecto"
                );
            }
            showAlertError("Ha ocurrido un error", "Intente mas tarde");
        }
    };
};

export const singUp = (data: any, callback?: any, err?: any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        dispatch(setError(false));
        try {
            const response = await authServices.singUp(data);
            dispatch(setUsers(response.data));
            dispatch(setLoading(false));
            if (callback) callback();
        } catch (error: any) {
            dispatch(setLoading(false));
            dispatch(setError(error));
            if (err) err();
        }
    };
};

export const logoutUser = (callback?: any) => {
    return async (dispatch: any) => {
        dispatch(setUser(null));
        dispatch(setLogginError(null));
        dispatch(setLoading(false));
        if (callback) callback();
    };
};

export const getUsers = (dataPage: any, callback?: any, err?: any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        dispatch(setError(false));
        try {
            const response = await authServices.getAllActive(
                dataPage?.page,
                dataPage?.per_page
            );

            dispatch(setUsers(response.data));
            dispatch(setLoading(false));
            if (callback) callback();
        } catch (error: any) {
            dispatch(setLoading(false));
            dispatch(setError(error));
            if (err) err();
        }
    };
};

export const updateUser = (data: any, callback?: any, err?: any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        dispatch(setError(null));

        try {
            const response = await authServices.update(data);
            dispatch(setLoading(false));
            if (callback) callback();
        } catch (error) {
            dispatch(setError(error));
            if (err) err();
        }
    };
};

/* recover */
export const requestRecover = (email: string, callback?: any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        dispatch(setEmailRecover(email));
        //send email with token
        await authServices
            .requestRecovery(email)
            .then(() => {
                dispatch(setLoading(false));
                if (callback) callback();
            })
            .catch((err) => {
                dispatch(setLoading(false));
                console.log("error en requestRecover");
                console.log("err: ", err);
            });
    };
};

export const updatePasswordWithToken = (
    token: any,
    password: string,
    callback?: any
) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        await authServices
            .updatePassword(token, password)
            .then(() => {
                dispatch(setLoading(false));
                if (callback) callback();
            })
            .catch((err) => {
                dispatch(setLoading(false));
                console.log("error en la peticion updatePassword");
                console.log("err: ", err);
            });
    };
};
