import legalWarningConstants from "./constants";

const { SET_LEGAL_WARNING, UPDATE_LEGAL_WARNING, LOADING, SET_ERROR } =
  legalWarningConstants;

export const initialState = {
  legalWarning: null,
};

const legalWarningReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LEGAL_WARNING:
      return {
        ...state,
        legalWarning: action.legalWarning,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default legalWarningReducer;
