import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Menu from "../../../components/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CaretRightOutlined, CloseOutlined } from "@ant-design/icons";
import { Collapse, Select, Button, Input, Form, Switch, Modal } from "antd";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import {
  getSections,
  getSectionsWithOutType,
} from "../../../redux/dashboard/section/action";
import UploadFile from "../../../components/Notice/uploadFile";
import {
  getNewss,
  saveNews,
  setNews,
} from "../../../redux/dashboard/news/action";
import Spinner from "../../../components/Spinner";
import { getSignatures } from "../../../redux/dashboard/signature/action";
import {
  showAlertQuestion,
  showAlertSucces,
  showAlertError,
  showAlertSuccesQuestion,
} from "../../../components/Alerts";
import BannerSecond from "../../../components/Banner/BannerSecond";
import Banner from "../../../components/Banner/Banner";
import Content from "../../../components/Content/Content";
import ContentSecond from "../../../components/Content/ContentSecond";
import BannerThree from "../../../components/Banner/BannerThree";
import ContentThree from "../../../components/Content/ContentThree";
import { getAnnouncers } from "../../../redux/dashboard/announcer/action";
import ContentFour from "../../../components/Content/ContentFour";
import BannerFour from "../../../components/Banner/BannerFour";
import EditorNews from "./EditorNews";
import newsConstants from "../../../redux/dashboard/news/constants";
import UploadImgs from "../../../components/Notice/uploadImgs";
import BannerPodcast from "../../../components/Banner/BannerPodcast";
import ContentPodcast from "../../../components/Content/ContentPodcast";
import { getSpecificSubSection } from "../../../redux/dashboard/subsection/action";

const NewNotices = () => {
  const { Panel } = Collapse;
  const { Option } = Select;
  const { TextArea, Search } = Input;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [textState, setTextState] = useState<any>();
  const [dataForm, setDataForm] = useState<any>({
    titleOnBanner: false,
    newsRelatedIsAuto: true,
    newsInterestedIsAuto: true,
  });
  const [dataPage, setDataPage] = useState<any>({
    page: 1,
    per_page: 15,
  });

  const { user } = useSelector((state: any) => state.auth.user);
  const { sections } = useSelector((state: any) => state.sections);
  const { subSection } = useSelector((state: any) => state.subSection);
  const { signatures } = useSelector((state: any) => state.signature);
  const { loading, news } = useSelector((state: any) => state.news);
  const { announcers } = useSelector((state: any) => state.announcer);
  const { newss } = useSelector((state: any) => state.news);

  useEffect(() => {
    dispatch(getSectionsWithOutType({ page: 1, per_page: 999 }));
    dispatch(getSignatures({ page: 1, per_page: 999 }));
    dispatch(getAnnouncers());
  }, []);

  useEffect(() => {
    if (news) {
      form.setFieldsValue(news);
      setDataForm({ ...dataForm, ...news });
    }
  }, []);

  useEffect(() => {
    if (dataForm) dispatch(setNews({ ...news, ...dataForm }));
  }, [dataForm]);

  useEffect(() => {
    if (dataForm?.text) {
      const blocksFromHtml = convertFromHTML(dataForm?.text);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );

      setTextState(EditorState.createWithContent(contentState));
    }
  }, [textState]);

  useEffect(() => {
    if (dataForm?.section) {
      dispatch(getSpecificSubSection(dataForm?.section));
    }
  }, [dataForm?.section]);

  /* Text and editor */
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    //validaciones
    if (!dataForm?.template) {
      showAlertError(
        "Ha ocurrido un error",
        "Por favor seleccione una plantilla"
      );
      return;
    } else if (
      dataForm?.template === "general" ||
      dataForm?.template === "special"
    ) {
      if (!dataForm?.section) {
        showAlertError("Ha ocurrido un error", "Seleccione una sección");
        return;
      }
    }

    if (dataForm?.template === "general") {
      if (!dataForm?.title) {
        showAlertError("Ha ocurrido un error", "Por favor escriba un título");
        return;
      } else if (!dataForm?.imgBanner) {
        showAlertError("Ha ocurrido un error", "Agregue una imagen de banner");
        return;
      } else if (!dataForm?.signature1) {
        showAlertError(
          "Ha ocurrido un error",
          "Por favor seleccione al menos una firma"
        );
        return;
      }
    }

    if (dataForm?.template === "general" && dataForm?.titleOnBanner === true) {
      if (!dataForm?.title) {
        showAlertError("Ha ocurrido un error", "Por favor escriba un título");
        return;
      } else if (!dataForm?.imgBanner) {
        showAlertError("Ha ocurrido un error", "Agregue una imagen de banner");
        return;
      } else if (
        !dataForm?.signature1 ||
        !dataForm?.signature2 ||
        !dataForm?.signature3
      ) {
        showAlertError("Ha ocurrido un error", "Agregue todas las firmas");
        return;
      }
    }

    if (dataForm?.template === "special") {
      if (!dataForm?.title) {
        showAlertError("Ha ocurrido un error", "Por favor escriba un título");
        return;
      } else if (!dataForm?.video) {
        showAlertError("Ha ocurrido un error", "Agregue un video");
        return;
      } else if (!dataForm?.signature1) {
        showAlertError(
          "Ha ocurrido un error",
          "Por favor seleccione al menos una firma"
        );
        return;
      }
    }
    if (dataForm?.template === "advertiser") {
      if (!dataForm?.announcer) {
        showAlertError("Ha ocurrido un error", "Seleccione un anunciante");
        return;
      }
      if (!dataForm?.title) {
        showAlertError("Ha ocurrido un error", "Por favor escriba un título");
        return;
      } else if (!dataForm?.signature1) {
        showAlertError(
          "Ha ocurrido un error",
          "Por favor seleccione al menos una firma"
        );
        return;
      }
      if (dataForm.section === "no") {
        delete dataForm.section;
      }
    } else {
      if (dataForm?.announcer) {
        delete dataForm.announcer;
      }
    }

    /*if(!dataForm.newsInterestedIsAuto){
      
      if(!dataForm.interNews1 || !dataForm.interNews2 || !dataForm.interNews3){
        showAlertError("Ha ocurrido un error", "tiene que elegir todas las 3 noticias");
        
        console.log(dataForm); return;
      }
    }*/

    //run
    //add user that create
    setDataForm({ ...dataForm, createdBy: user?.id || null });

    showAlertQuestion(
      "¿Estás seguro que desea guardar la noticia creada?",
      "Guardar",
      () => {
        dispatch(
          saveNews(
            dataForm,
            () => {
              showAlertSucces(
                "¡Noticia creada!",
                "La noticia se ha creado exitosamente"
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              dispatch(setNews({}));
              navigate("/dashboard/post_news");
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido crear la noticia exitosamente"
              );
            }
          )
        );
      }
    );
  };

  const handleCancelNews = () => {
    showAlertSuccesQuestion(
      "¿Estas seguro que deseas cancelar la creacion de esta noticia?",
      "Confirmar",
      "Noticia cancelada",
      "La noticia se ha cancelado correctamente",
      () => {
        dispatch(setNews({ titleOnBanner: false }));
        navigate("/dashboard/");
      }
    );
  };

  /* Editor */
  const updateEditor = (value: any) => {
    setDataForm({ ...dataForm, text: value });
  };

  return (
    <>
      {loading && <Spinner />}
      <Menu>
        <div className="">
          <Collapse
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            expandIconPosition="right"
            bordered={false}
          >
            {/* template */}
            <Panel
              header="Plantilla"
              className={styles.header_panel}
              key={"news1"}
            >
              <Form form={form}>
                <Form.Item
                  name="template"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona una plantilla",
                    },
                  ]}
                >
                  <Select
                    placeholder="Elegir plantilla"
                    className={styles.select_seccion}
                    onChange={(e) => setDataForm({ ...dataForm, template: e })}
                  >
                    <Option className={styles.option_select} value="general">
                      Plantilla general
                    </Option>
                    <Option className={styles.option_select} value="special">
                      Plantilla especial
                    </Option>
                    <Option className={styles.option_select} value="advertiser">
                      Plantilla anunciante
                    </Option>
                    <Option className={styles.option_select} value="podcast">
                      Plantilla podcast
                    </Option>
                  </Select>
                </Form.Item>
              </Form>
            </Panel>

            {/* anunciante */}
            {dataForm?.template === "advertiser" && (
              <Panel
                header="Anunciante"
                className={styles.header_panel}
                key={"newsanouncer"}
              >
                <Form form={form}>
                  <Form.Item
                    name="announcer"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecciona un anunciante",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Elegir anunciante"
                      className={styles.select_seccion}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, announcer: e })
                      }
                    >
                      {announcers &&
                        announcers?.data.map((announcer: any, index: any) => (
                          <Option
                            key={index}
                            className={styles.option_select}
                            value={announcer.brand}
                          >
                            {announcer.brand}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Form>
              </Panel>
            )}
            {/* section */}
            <Panel
              header="Sección"
              className={styles.header_panel}
              key={"news2"}
            >
              <Form form={form}>
                <Form.Item
                  className={styles.custom_form_item}
                  name="section"
                  rules={[
                    {
                      required: dataForm?.template === "podcast" ? false : true,
                      message: "Por favor selecciona una sección",
                    },
                  ]}
                >
                  <Select
                    placeholder="Elegir sección"
                    className={styles.select_seccion}
                    onChange={(e) => {
                      setDataForm({
                        ...dataForm,
                        section: e,
                        subSection: null,
                      });
                    }}
                  >
                    <Option className={styles.option_select}>
                      Sin Seccion
                    </Option>
                    {sections?.data.map((section: any) => (
                      <Option
                        key={section?.id}
                        className={styles.option_select}
                        value={section?.id}
                      >
                        {section.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Panel>

            {/* subSection */}
            {subSection && subSection?.length > 0 && (
              <Panel
                header="Subsección (Opcional)"
                className={styles.header_panel}
                key={"newsSubseccion1"}
              >
                <Form form={form}>
                  {subSection && subSection?.length > 0 && (
                    <Form.Item
                      className={styles.custom_form_item}
                      name="subSection"
                    >
                      <Select
                        placeholder="Elegir subsección"
                        className={styles.select_seccion}
                        onChange={(e) =>
                          setDataForm({ ...dataForm, subSection: e })
                        }
                      >
                        <Option className={styles.option_select}>
                          Sin subsección
                        </Option>
                        {subSection?.map((item: any) => (
                          <Option
                            key={item?.id}
                            className={styles.option_select}
                            value={item?.id}
                          >
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form>
              </Panel>
            )}

            {/* antetitle */}
            <Panel
              header="Antetítulo"
              className={styles.header_panel}
              key={"news3"}
            >
              <Form form={form}>
                {news?.template === "general" && (
                  <Form.Item
                    label="Ante titulo sobre banner"
                    name="titleOnBanner"
                  >
                    <Switch
                      size="small"
                      checked={dataForm?.titleOnBanner}
                      onChange={(e) =>
                        setDataForm({
                          ...dataForm,
                          titleOnBanner: e,
                        })
                      }
                    />
                  </Form.Item>
                )}
                <Form.Item name="preTitle">
                  <TextArea
                    placeholder="Escribe aquí"
                    rows={2}
                    onChange={(e) =>
                      setDataForm({
                        ...dataForm,
                        preTitle: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Form>
            </Panel>

            {/* title */}
            <Panel
              header="Título"
              className={styles.header_panel}
              key={"news4"}
            >
              <Form form={form}>
                {dataForm?.template === "general" && (
                  <Form.Item
                    label="Titulo sobre banner"
                    name="titleOnBanner"
                    rules={[
                      {
                        required: true,
                        message: "Por favor escriba un titulo",
                      },
                    ]}
                    required={true}
                  >
                    <Switch
                      size="small"
                      checked={dataForm?.titleOnBanner}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, titleOnBanner: e })
                      }
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Por favor escribe un título",
                    },
                  ]}
                  required={true}
                >
                  <TextArea
                    placeholder="Escribe aquí"
                    rows={2}
                    onChange={(e) =>
                      setDataForm({
                        ...dataForm,
                        title: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Form>
            </Panel>

            {/* subtitle */}
            <Panel
              header="Subtítulo"
              className={styles.header_panel}
              key={"news5"}
            >
              <Form form={form}>
                <Form.Item name="subTitle">
                  <TextArea
                    placeholder="Escribe aquí"
                    rows={2}
                    onChange={(e) =>
                      setDataForm({
                        ...dataForm,
                        subTitle: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Form>
            </Panel>

            {/* img Banner */}
            {dataForm?.template === "general" && (
              <Panel
                header="Imagen banner y pie de foto"
                className={styles.header_panel}
                key={"news6"}
              >
                <UploadImgs
                  form={form}
                  dataForm={dataForm}
                  setDataForm={setDataForm}
                  nameFile={"imgBanner"}
                  nameTextImg={"textImgBanner"}
                />
              </Panel>
            )}

            {/* video */}
            {dataForm?.template === "special" && (
              <Panel
                header="Video"
                className={styles.header_panel}
                key={"news12"}
              >
                <Form form={form}>
                  <Form.Item
                    name="video"
                    rules={
                      news?.template === "advertiser"
                        ? []
                        : [
                            {
                              required: true,
                              message: "Por favor escriba un link de Youtube",
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Link de Youtube"
                      className={styles.inputs}
                      style={{ color: "#ababab" }}
                      autoComplete="off"
                      onChange={(e) =>
                        setDataForm({
                          ...dataForm,
                          video: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </Form>

                <UploadImgs
                  form={form}
                  dataForm={dataForm}
                  setDataForm={setDataForm}
                  nameFile={"imgVideo"}
                />
              </Panel>
            )}
            {/* video */}
            {dataForm?.template === "advertiser" && (
              <Panel
                header="Video o Imagen"
                className={styles.header_panel}
                key={"news12"}
              >
                <Form form={form}>
                  <Form.Item
                    name="video"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Link de Youtube"
                      className={styles.inputs}
                      style={{ color: "#ababab" }}
                      autoComplete="off"
                      onChange={(e) =>
                        setDataForm({
                          ...dataForm,
                          video: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </Form>

                <UploadImgs
                  form={form}
                  dataForm={dataForm}
                  setDataForm={setDataForm}
                  nameFile={"imgVideo"}
                />
              </Panel>
            )}

            {/* firmas */}
            <Panel
              header="Firma redactor 1"
              className={styles.header_panel}
              key={"news7"}
            >
              <Form form={form}>
                <Form.Item
                  name="signature1"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una firma",
                    },
                  ]}
                  required={true}
                >
                  <Select
                    placeholder="Elegir firma"
                    className={styles.select_seccion}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, signature1: e })
                    }
                  >
                    {signatures &&
                      signatures?.data.map((signature: any) => (
                        <Option
                          key={signature.key}
                          className={styles.option_select}
                          value={signature.id}
                        >
                          {signature.fullName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            </Panel>

            <Panel
              header="Firma redactor 2"
              className={styles.header_panel}
              key={"news8"}
            >
              <Form form={form}>
                <Form.Item
                  name="signature2"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una firma",
                    },
                  ]}
                >
                  <Select
                    placeholder="Elegir firma"
                    className={styles.select_seccion}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, signature2: e })
                    }
                  >
                    {signatures &&
                      signatures?.data.map((signature: any) => (
                        <Option
                          key={signature.key}
                          className={styles.option_select}
                          value={signature.id}
                        >
                          {signature.fullName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            </Panel>
            <Panel
              header="Firma redactor 3"
              className={styles.header_panel}
              key={"news9"}
            >
              <Form form={form}>
                <Form.Item
                  name="signature3"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una firma",
                    },
                  ]}
                >
                  <Select
                    placeholder="Elegir firma"
                    className={styles.select_seccion}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, signature3: e })
                    }
                  >
                    {signatures &&
                      signatures?.data.map((signature: any) => (
                        <Option
                          key={signature.key}
                          className={styles.option_select}
                          value={signature?.id}
                        >
                          {signature.fullName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            </Panel>

            {/* podcast */}
            {dataForm?.template === "podcast" && (
              <Panel
                header="Podcast"
                className={styles.header_panel}
                key={"news6"}
              >
                <Form form={form}>
                  <Form.Item
                    name="podcast"
                    rules={[
                      {
                        required: true,
                        message: "Por favor escribe el podcast",
                      },
                    ]}
                    required={true}
                    className={styles.custom_form_item}
                  >
                    <TextArea
                      placeholder="Inserte aquí el podcast"
                      rows={4}
                      onChange={(e) =>
                        setDataForm({
                          ...dataForm,
                          podcast: e.target.value,
                        })
                      }
                    />
                  </Form.Item>

                  <Form.Item className={styles.custom_form_item}>
                    <UploadImgs
                      form={form}
                      dataForm={dataForm}
                      setDataForm={setDataForm}
                      nameFile={"imgPodcast"}
                      message={'Recommanded resolution is 1080*1080 with file size less than 2MB, keep visual elements centered'}
                    />
                  </Form.Item>
                </Form>
              </Panel>
            )}

            {/* Text */}
            <Panel
              header="Texto"
              className={styles.header_panel}
              key={"news10"}
            >
              <span className={styles.edit_tex_notices}>Editar textos</span>
              <div className={styles.container_button}>
                <Button
                  block
                  htmlType="submit"
                  onClick={showModal}
                  className={styles.save_button_notices}
                >
                  Editar
                </Button>
              </div>
            </Panel>

            {/* noticias relacionadas */}
            <Panel
              key={"news15"}
              collapsible="header"
              header="Noticias relacionadas"
              className={styles.header_panel}
              extra={
                <div>
                  <span className={styles.text_color_switch}>Auto</span>
                  <Switch
                    size="small"
                    checked={dataForm?.newsRelatedIsAuto}
                    onChange={(checked) =>
                      setDataForm({ ...dataForm, newsRelatedIsAuto: checked })
                    }
                  />
                </div>
              }
            >
              <Form form={form}>
                <Form.Item
                  name="relatedNotice1"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una noticia relacionada",
                    },
                  ]}
                  className={styles.spacing_item}
                >
                  <Select
                    className={styles.inputs_select}
                    dropdownClassName={styles.dropdown_select}
                    placeholder="Seleccionar noticia relacionada 1"
                    suffixIcon={<CaretRightOutlined rotate={90} />}
                    dropdownRender={(menu) => (
                      <div>
                        <div className={styles.iconClose}>
                          <CloseOutlined style={{ cursor: "pointer" }} />
                        </div>
                        <div>
                          <div className={styles.container_search}>
                            <Input
                              placeholder="Escribe aquí"
                              size="middle"
                              allowClear
                              className={styles.search_select}
                            />
                            <Button
                              htmlType="submit"
                              block
                              className={styles.search_button}
                            >
                              Buscar
                            </Button>
                          </div>
                          <div className={styles.container_selects}>
                            <div className={styles.spacing_select_fecha}>
                              <span className={styles.span_select}>Fecha:</span>
                              <Select
                                placeholder="Cualquiera"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_claves}>
                              <span className={styles.span_select}>
                                Palabras claves:
                              </span>
                              <Select
                                mode="tags"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_seccion}>
                              <span className={styles.span_select}>
                                Sección:
                              </span>
                              <Select
                                placeholder="Seleccionar sección"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                        {menu}
                      </div>
                    )}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name="relatedNotice2"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una noticia relacionada",
                    },
                  ]}
                  className={styles.spacing_item}
                >
                  <Select
                    className={styles.inputs_select}
                    dropdownClassName={styles.dropdown_select}
                    placeholder="Seleccionar noticia relacionada 2"
                    suffixIcon={<CaretRightOutlined rotate={90} />}
                    dropdownRender={(menu) => (
                      <div>
                        <div className={styles.iconClose}>
                          <CloseOutlined style={{ cursor: "pointer" }} />
                        </div>
                        <div>
                          <div className={styles.container_search}>
                            <Input
                              placeholder="Escribe aquí"
                              size="middle"
                              allowClear
                              className={styles.search_select}
                            />
                            <Button
                              htmlType="submit"
                              block
                              className={styles.search_button}
                            >
                              Buscar
                            </Button>
                          </div>
                          <div className={styles.container_selects}>
                            <div className={styles.spacing_select_fecha}>
                              <span className={styles.span_select}>Fecha:</span>
                              <Select
                                placeholder="Cualquiera"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_claves}>
                              <span className={styles.span_select}>
                                Palabras claves:
                              </span>
                              <Select
                                mode="tags"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_seccion}>
                              <span className={styles.span_select}>
                                Sección:
                              </span>
                              <Select
                                placeholder="Seleccionar sección"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                        {menu}
                      </div>
                    )}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name="relatedNotice3"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una noticia relacionada",
                    },
                  ]}
                  className={styles.spacing_item}
                >
                  <Select
                    className={styles.inputs_select}
                    dropdownClassName={styles.dropdown_select}
                    placeholder="Seleccionar noticia relacionada 3"
                    suffixIcon={<CaretRightOutlined rotate={90} />}
                    dropdownRender={(menu) => (
                      <div>
                        <div className={styles.iconClose}>
                          <CloseOutlined style={{ cursor: "pointer" }} />
                        </div>
                        <div>
                          <div className={styles.container_search}>
                            <Input
                              placeholder="Escribe aquí"
                              size="middle"
                              allowClear
                              className={styles.search_select}
                            />
                            <Button
                              htmlType="submit"
                              block
                              className={styles.search_button}
                            >
                              Buscar
                            </Button>
                          </div>
                          <div className={styles.container_selects}>
                            <div className={styles.spacing_select_fecha}>
                              <span className={styles.span_select}>Fecha:</span>
                              <Select
                                placeholder="Cualquiera"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_claves}>
                              <span className={styles.span_select}>
                                Palabras claves:
                              </span>
                              <Select
                                mode="tags"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_seccion}>
                              <span className={styles.span_select}>
                                Sección:
                              </span>
                              <Select
                                placeholder="Seleccionar sección"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                        {menu}
                      </div>
                    )}
                  ></Select>
                </Form.Item>
              </Form>
            </Panel>

            {/* Noticias te puede interezar */}
            <Panel
              key={"news16"}
              collapsible="header"
              className={styles.header_panel}
              header="Noticias “Te puede interesar”"
              extra={
                <div>
                  <span className={styles.text_color_switch}>Auto</span>
                  <Switch
                    size="small"
                    checked={dataForm?.newsInterestedIsAuto}
                    onChange={(checked) =>
                      setDataForm({
                        ...dataForm,
                        newsInterestedIsAuto: checked,
                      })
                    }
                  />
                </div>
              }
            >
              <Form form={form}>
                <Form.Item
                  name="interestNotice1"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una noticia",
                    },
                  ]}
                  className={styles.spacing_item}
                >
                  <Select
                    className={styles.inputs_select}
                    dropdownClassName={styles.dropdown_select}
                    placeholder="Seleccionar noticia 1"
                    suffixIcon={<CaretRightOutlined rotate={90} />}
                    onChange={(value) => (dataForm.interNews1 = value)}
                    dropdownRender={(menu) => (
                      <div>
                        <div className={styles.iconClose}>
                          <CloseOutlined style={{ cursor: "pointer" }} />
                        </div>
                        <div>
                          <div className={styles.container_search}>
                            <Search
                              placeholder="Escribe aquí"
                              size="middle"
                              allowClear
                              className={styles.search_select}
                            />
                            <Button
                              htmlType="submit"
                              block
                              className={styles.search_button}
                              onClick={() => {
                                dispatch(getNewss());
                                console.log(newss.data);
                              }}
                            >
                              Buscar
                            </Button>
                          </div>
                          <div className={styles.container_selects}>
                            <div className={styles.spacing_select_fecha}>
                              <span className={styles.span_select}>
                                Fecha de publicación:
                              </span>
                              <Select
                                placeholder="Cualquiera"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_claves}>
                              <span className={styles.span_select}>
                                Palabras claves:
                              </span>
                              <Select
                                mode="tags"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_seccion}>
                              <span className={styles.span_select}>
                                Sección:
                              </span>
                              <Select
                                placeholder="Seleccionar sección"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                        {menu}
                      </div>
                    )}
                  >
                    {/*newss?.data.map((n: any, index: any) => (
                      <Option
                        key={n.id}
                        className={styles.option_select}
                        value={n.id}
                      >
                        {n.title}
                      </Option>
                    ))*/}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="interestNotice2"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una noticia",
                    },
                  ]}
                  className={styles.spacing_item}
                >
                  <Select
                    className={styles.inputs_select}
                    dropdownClassName={styles.dropdown_select}
                    placeholder="Seleccionar noticia 2"
                    suffixIcon={<CaretRightOutlined rotate={90} />}
                    onChange={(value) => (dataForm.interNews2 = value)}
                    dropdownRender={(menu) => (
                      <div>
                        <div className={styles.iconClose}>
                          <CloseOutlined style={{ cursor: "pointer" }} />
                        </div>
                        <div>
                          <div className={styles.container_search}>
                            <Search
                              placeholder="Escribe aquí"
                              size="middle"
                              allowClear
                              className={styles.search_select}
                            />
                            <Button
                              htmlType="submit"
                              block
                              className={styles.search_button}
                              onClick={() => {
                                dispatch(getNewss());
                                console.log(newss.data);
                              }}
                            >
                              Buscar
                            </Button>
                          </div>
                          <div className={styles.container_selects}>
                            <div className={styles.spacing_select_fecha}>
                              <span className={styles.span_select}>
                                Fecha de publicación:
                              </span>
                              <Select
                                placeholder="Cualquiera"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_claves}>
                              <span className={styles.span_select}>
                                Palabras claves:
                              </span>
                              <Select
                                mode="tags"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_seccion}>
                              <span className={styles.span_select}>
                                Sección:
                              </span>
                              <Select
                                placeholder="Seleccionar sección"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                        {menu}
                      </div>
                    )}
                  >
                    {/*newss?.data.map((n: any, index: any) => (
                      <Option
                        key={n.id}
                        className={styles.option_select}
                        value={n.id}
                      >
                        {n.title}
                      </Option>
                    ))*/}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="interestNotice3"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una noticia",
                    },
                  ]}
                  className={styles.spacing_item}
                >
                  <Select
                    className={styles.inputs_select}
                    dropdownClassName={styles.dropdown_select}
                    placeholder="Seleccionar noticia 3"
                    suffixIcon={<CaretRightOutlined rotate={90} />}
                    onChange={(value) => (dataForm.interNews3 = value)}
                    dropdownRender={(menu) => (
                      <div>
                        <div className={styles.iconClose}>
                          <CloseOutlined style={{ cursor: "pointer" }} />
                        </div>
                        <div>
                          <div className={styles.container_search}>
                            <Search
                              placeholder="Escribe aquí"
                              size="middle"
                              allowClear
                              className={styles.search_select}
                            />
                            <Button
                              htmlType="submit"
                              block
                              className={styles.search_button}
                              onClick={() => {
                                dispatch(getNewss());
                                console.log(newss.data);
                              }}
                            >
                              Buscar
                            </Button>
                          </div>
                          <div className={styles.container_selects}>
                            <div className={styles.spacing_select_fecha}>
                              <span className={styles.span_select}>
                                Fecha de publicación:
                              </span>
                              <Select
                                placeholder="Cualquiera"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_claves}>
                              <span className={styles.span_select}>
                                Palabras claves:
                              </span>
                              <Select
                                mode="tags"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                            <div className={styles.spacing_select_seccion}>
                              <span className={styles.span_select}>
                                Sección:
                              </span>
                              <Select
                                placeholder="Seleccionar sección"
                                className={styles.select_seccion}
                              >
                                <Option
                                  className={styles.option_select}
                                  value="1"
                                >
                                  Firma 1
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                        {menu}
                      </div>
                    )}
                  >
                    {/*newss?.data.map((n: any, index: any) => (
                      <Option
                        key={n.id}
                        className={styles.option_select}
                        value={n.id}
                      >
                        {n.title}
                      </Option>
                    ))*/}
                  </Select>
                </Form.Item>
              </Form>
            </Panel>

            {/* Keywords */}
            <Panel
              header="Palabras claves"
              className={styles.header_panel}
              key={"news17"}
            >
              <Form form={form}>
                <Form.Item
                  name="keywords"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione una palabra clave",
                    },
                  ]}
                >
                  <Select
                    mode="tags"
                    className={styles.select_seccion}
                    onChange={(e) =>
                      setDataForm({
                        ...dataForm,
                        keywords: e,
                      })
                    }
                  >
                    <Option className={styles.option_select} value="1">
                      Firma 1
                    </Option>
                  </Select>
                </Form.Item>
              </Form>
            </Panel>

            {/* File */}
            <Panel
              header="Agregar archivo"
              className={styles.header_panel}
              key={"news18"}
            >
              <UploadFile
                form={form}
                dataForm={dataForm}
                setDataForm={setDataForm}
                nameFile={"file"}
                type={"file"}
              />
            </Panel>

            <div className={styles.container_button_menu}>
              <Button
                onClick={onFinish}
                className={styles.save_button_notices}
                block
              >
                Guardar
              </Button>
              <Button
                className={styles.cancel_button_notices}
                block
                onClick={handleCancelNews}
              >
                Cancelar
              </Button>
            </div>
          </Collapse>
        </div>
      </Menu>

      <Modal
        centered
        title="Editor"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={isModalVisible}
        footer={[
          <Button
            key="submit"
            loading={loading}
            onClick={handleOk}
            className={styles.save_button_notices}
          >
            Guardar
          </Button>,
        ]}
        width={751}
      >
        <EditorNews text={dataForm?.text} updateEditor={updateEditor} />
      </Modal>

      <div className={styles.container_1440}>
        {news?.template === "general" && (
          <>
            {news?.titleOnBanner && (
              <>
                <BannerSecond news={news} />
                {news && <ContentSecond news={news} />}
              </>
            )}
            {!news?.titleOnBanner && (
              <>
                <Banner news={news} /> <Content news={news} />
              </>
            )}
          </>
        )}

        {news?.template === "podcast" && (
          <>
            <BannerPodcast news={news} />
            <ContentPodcast news={news} />
          </>
        )}

        {news?.template === "special" && (
          <>
            <BannerThree news={news} /> <ContentThree news={news} />
          </>
        )}
        {news?.template === "advertiser" && (
          <>
            <BannerFour news={news} /> <ContentFour news={news} />
          </>
        )}
      </div>
    </>
  );
};

export default NewNotices;
