import sliderConstants from "./constants";

const { SET_SLIDER, SET_SLIDERS, LOADING, SET_ERROR } = sliderConstants;

export const initialState = {
  sliders: null,
};

const sliderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SLIDER:
      return {
        ...state,
        slider: action.slider,
      };
    case SET_SLIDERS:
      return {
        ...state,
        sliders: action.sliders,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default sliderReducer;
