import React from "react";
import Banner from "../Banner/Banner";
import BannerFour from "../Banner/BannerFour";
import BannerSecond from "../Banner/BannerSecond";
import BannerThree from "../Banner/BannerThree";
import Content from "../Content/Content";
import ContentFour from "../Content/ContentFour";
import ContentSecond from "../Content/ContentSecond";
import ContentThree from "../Content/ContentThree";

const Preview = (props: any) => {
  console.log(props.news.template);

  return (
    <>
      <div style={{marginTop:"20px"}}>
        {props?.news?.template === "general" && (
          <>
            {props?.news?.titleOnBanner && (
              <>
                <BannerSecond news={props?.news} />
                {props?.news && <ContentSecond news={props?.news} />}
              </>
            )}
            {!props?.news?.titleOnBanner && (
              <>
                <Banner news={props?.news} /> <Content news={props?.news} />
              </>
            )}
          </>
        )}
        {props?.news?.template === "special" && (
          <>
            <BannerThree news={props?.news} /> <ContentThree news={props?.news} />
          </>
        )}
        {props?.news?.template === "advertiser" && (
          <>
            <BannerFour news={props?.news} /> <ContentFour news={props?.news} />
          </>
        )}
      </div>
    </>
  );
};

export default Preview;
