import React, { useEffect, useRef, useState } from "react";
import { Button, Switch } from "antd";
import styles from "./styles.module.scss";
import logo from "../../assets/img/logo_img.svg";
import { logoutUser } from "../../redux/auth/action";
import { setMenu, setSubMenu } from "../../redux/settings/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconCerrarSesion from "../../assets/img/logo_cerrarSesion.svg";
import { Layout } from "antd";
import { getThermometer } from "../../redux/dashboard/thermometer/action";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const { subMenu, menu } = useSelector((state: any) => state.settings);
  const { user } = useSelector((state: any) => state.auth);
  const { editNews } = useSelector((state: any) => state.news);
  const { user: dataUser } = user;

  const routesNotInitMenu = ["/dashboard/post_news"];

  useEffect(() => {
    dispatch(setMenu(false));
    const { pathname } = location;
    const redirect = routesNotInitMenu.some((route) =>
      pathname.startsWith(route)
    );
    if (!redirect) {
      setTimeout(() => {
        dispatch(setMenu(true));
      }, 1);
      return;
    }
  }, [navigate]);

  const toggleMenu = () => {
    const sideBar = document.getElementsByClassName(styles.list_show);
    sideBar[0].classList.toggle(styles.activate);
    setMenuOpen(!menuOpen);
    if (menuOpen !== false) {
      sideBar[0].classList.remove(styles.activate);
    } else {
      sideBar[0].classList.add(styles.activate);
    }
  };

  const onChangeSwitch = (checked: boolean) => {
    if (checked === true) {
      dispatch(setMenu(true));
      navigate("/dashboard/banner");
    } else {
      dispatch(setMenu(false));
      navigate("/dashboard");
    }
  };

  /* size container  */
  const divHeight = useRef<any>(null);
  const [height, setHeight] = useState<any>(100);

  useEffect(() => {
    setHeight(divHeight?.current?.clientHeight);
  }, []);

  window.addEventListener("resize", (v: any) => {
    setHeight(divHeight?.current?.clientHeight);
  });

  return (
    <>
      <header className={styles.header}>
        <div className={styles.menu}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <div className={styles.cerrarSesion}>
            <img src={iconCerrarSesion} className={styles.iconSesion} />
            <span className={styles.cerrarSesion_text}>
              {`${
                String(dataUser?.firstName).charAt(0).toUpperCase() +
                String(dataUser?.firstName).slice(1)
              } ${
                String(dataUser?.lastName).charAt(0).toUpperCase() +
                String(dataUser?.lastName).slice(1)
              }`}
            </span>
            <Button htmlType="submit" className={styles.btnCerrarSesion} block>
              <span
                className={styles.btnSesion_text}
                onClick={() =>
                  dispatch(
                    logoutUser(() => {
                      navigate("/login");
                    })
                  )
                }
              >
                Cerrar Sesion
              </span>
            </Button>
          </div>
        </div>
      </header>
      <div className={styles.container}>
        <div ref={divHeight} className={styles.menuList}>
          <ul className={styles.list}>
            <li>
              <div
                onClick={() => toggleMenu()}
                className={`${styles.list_button} ,  ${styles.list_item}`}
              >
                <a className={styles.list_link}>
                  Home
                  <FontAwesomeIcon
                    icon={faSortDown}
                    className={styles.list_arrow}
                  />
                </a>
              </div>

              <ul
                className={`${styles.list_show} ${styles.list_sub} , ${styles.ease}`}
              >
                <Link to="banner">
                  <li className={styles.list_item_home}>
                    <a
                      className={styles.list_sub_link}
                      onClick={() => dispatch(setMenu(true))}
                    >
                      Barra Banner
                    </a>
                  </li>
                </Link>

                <Link to="slider">
                  <li className={styles.list_item_home}>
                    <a
                      className={styles.list_sub_link}
                      onClick={() => dispatch(setMenu(true))}
                    >
                      Slider
                    </a>
                  </li>
                </Link>

                <Link to="menubar">
                  <li className={styles.list_item_home}>
                    <a
                      className={styles.list_sub_link}
                      onClick={() => dispatch(setMenu(true))}
                    >
                      Barra menú
                    </a>
                  </li>
                </Link>

                <Link to="modules">
                  <li className={styles.list_item_home}>
                    <a className={styles.list_sub_link}>Módulos</a>
                  </li>
                </Link>
                <Link to="thermometer">
                  <li className={styles.list_item_home}>
                    <a
                      className={styles.list_sub_link}
                      onClick={() => dispatch(setMenu(true))}
                    >
                      El Termómetro
                    </a>
                  </li>
                </Link>

                <Link to="footer">
                  <li className={styles.list_item_home}>
                    <a className={styles.list_sub_link}>Pie de página</a>
                  </li>
                </Link>
              </ul>
            </li>

            <Link to="sections">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a className={styles.list_link}>Secciones</a>
                </div>
              </li>
            </Link>

            <Link to="subsections">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a className={styles.list_link}>Subsecciones</a>
                </div>
              </li>
            </Link>

            <Link to="new_news">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a
                    className={styles.list_link}
                    onClick={() => dispatch(setMenu(true))}
                  >
                    Crear noticia
                  </a>
                </div>
              </li>
            </Link>

            {editNews && (
              <Link to="editNews">
                <li className={styles.list_item}>
                  <div className={styles.list_button}>
                    <a
                      className={styles.list_link}
                      onClick={() => dispatch(setMenu(true))}
                    >
                      Editar noticia
                    </a>
                  </div>
                </li>
              </Link>
            )}
            <Link to="news">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a className={styles.list_link}>Noticias</a>
                </div>
              </li>
            </Link>

            <Link to="post_news">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a
                    className={styles.list_link}
                    onClick={() => dispatch(setMenu(true))}
                  >
                    Publicar noticia
                  </a>
                </div>
              </li>
            </Link>

            <Link to="signature">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a className={styles.list_link}>Firmas</a>
                </div>
              </li>
            </Link>
            <Link to="social">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a
                    className={styles.list_link}
                    onClick={() => dispatch(setMenu(true))}
                  >
                    Redes Sociales
                  </a>
                </div>
              </li>
            </Link>
            <Link to="subscribers">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a className={styles.list_link}>Suscriptores</a>
                </div>
              </li>
            </Link>
            <Link to="announcer">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a className={styles.list_link}>Anunciantes</a>
                </div>
              </li>
            </Link>

            <Link to="burger">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a
                    className={styles.list_link}
                    onClick={() => dispatch(setMenu(true))}
                  >
                    Menú burger
                  </a>
                </div>
              </li>
            </Link>

            <Link to="admin-user">
              <li className={styles.list_item}>
                <div className={styles.list_button}>
                  <a className={styles.list_link}>Administradores</a>
                </div>
              </li>
            </Link>
          </ul>
        </div>
        <div className={styles.scroll_outlet}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
