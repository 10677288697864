import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import styles from "./styles.module.scss";
import banner_principal from "../../../src/assets/img/banner_general.svg";
import publicidad_uno from "../../../src/assets/img/publicidad_uno.svg";
import facebook from "../../../src/assets/img/facebook.svg";
import instagram from "../../../src/assets/img/instagram.svg";
import linkedln from "../../../src/assets/img/linkedln.svg";
import twitter from "../../../src/assets/img/twitter.svg";
import whatsapp from "../../../src/assets/img/whatsapp.svg";
import bannerGeneral from "../../assets/img/banner_general.svg";
import { imgNoExist } from "../../helpers/img";

const BannerPodcast = ({ news }: any) => {
  const [imgBanner, setImgBanner] = useState<any>();

  const convertImg = async () => {
    let src: any;

    if (typeof news?.imgBanner === "string") {
      setImgBanner(news?.imgBanner);
      return;
    }

    if (news?.imgBanner) {
      const { imgBanner } = news;
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgBanner);
        reader.onload = () => resolve(reader.result);
      });
    }
    const img = new Image();
    img.src = src;
    setImgBanner(img.src);
  };

  useEffect(() => {
    convertImg();
  }, [news.imgBanner]);

  return (
    <>
      <div>
        <div className={styles.container_text_banner}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a>Home</a>
            </Breadcrumb.Item>
            {news?.section && (
              <Breadcrumb.Item>
                <a>{news?.section?.title || "Podcast"}</a>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>

          {news?.preTitle && (
            <p className={styles.antetitulo_banner}>{news.preTitle}</p>
          )}
          {news?.title && <p className={styles.title_banner}>{news.title}</p>}
          {news?.subTitle && (
            <p className={styles.subtitulo_banner}>{news.subTitle}</p>
          )}
          {/* Share */}
          <div className={styles.container_share}>
            <span className={styles.text_share}>Compartir</span>
            <img
              src={facebook}
              height={22}
              alt="logo_facebook"
              className={styles.icon_logo}
            />
            <img
              src={instagram}
              height={22}
              alt="logo_instagram"
              className={styles.icon_logo}
            />
            <img
              src={linkedln}
              height={22}
              alt="logo_linkedln"
              className={styles.icon_logo}
            />
            <img
              src={twitter}
              height={22}
              alt="logo_twitter"
              className={styles.icon_logo}
            />
            <img
              src={whatsapp}
              height={22}
              alt="logo_whatsapp"
              className={styles.icon_logo}
            />
          </div>
        </div>

        <div className={styles.container_publicidad_uno}>
          <img src={publicidad_uno} width={728} alt="publicidad_uno" />
        </div>
      </div>
    </>
  );
};

export default BannerPodcast;
