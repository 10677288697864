import { Button, Pagination, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";
import { getSubscribers } from "../../../redux/dashboard/subscriber/action";
import { CSVLink } from "react-csv";
import api from "../../../helpers/api";
const Subscribers = () => {
  const dispatch = useDispatch();
  const [subs, setSubs] = useState<any>();
  const [dataPage, setDataPage] = useState<any>({
    page: 1,
    per_page: 15,
  });

  const { subscribers } = useSelector((state: any) => state.subscriber);
  useEffect(() => {
    const getSubs = async () => {
      const response = await api
        .get("/subscriber/getSubs")
        .then((res: any) => {
          setSubs(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    dispatch(getSubscribers(dataPage));
    getSubs();
  }, [dataPage]);

  const showTotal = (from: number, to: number, total: number) => {
    return `${from} - ${to} de ${total}`;
  };
  const columns = [
    {
      title: "Coreo",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Fecha de inscripcion",
      dataIndex: "subDate",
      key: "subDate",
    },
  ];

  /* size table */
  const divHeight = useRef<any>(null);
  const divOptionsHeight = useRef<any>(null);
  const [height, setHeight] = useState<any>(100);
  const [optionsHeight, setOptionsHeight] = useState<any>(34);

  useEffect(() => {
    setHeight(divHeight?.current?.clientHeight);
  }, []);

  window.addEventListener("resize", (v: any) => {
    setHeight(divHeight?.current?.clientHeight);
    setOptionsHeight(divOptionsHeight?.current?.clientHeight);
  });

  return (
    <>
      <div className={styles.container_subscribers} ref={divHeight}>
        {/* <div className={styles.line}></div> */}
        <div className={styles.container_header_search} ref={divOptionsHeight}>
          {subs && (
            <div>
              <CSVLink
                data={subs}
                filename={"suscriptores.csv"}
                target="_blank"
              >
                <Button
                  htmlType="submit"
                  className={styles.button}
                  block
                  icon={<DownloadOutlined />}
                >
                  Descargar CSV
                </Button>
              </CSVLink>
            </div>
          )}

          <div className={styles.container_search}>
            <Pagination
              className={styles.pagination}
              size="small"
              showTotal={() =>
                showTotal(
                  subscribers?.from,
                  subscribers?.to,
                  subscribers?.total
                )
              }
              showSizeChanger={false}
              defaultPageSize={subscribers?.per_page}
              pageSize={subscribers?.per_page || 15}
              total={subscribers?.total}
              onChange={(page) => {
                setDataPage({ ...dataPage, page });
              }}
              current={subscribers?.current_page || 1}
            />
          </div>
        </div>
        <div
          className={styles.container_table}
          style={{
            height: `${height - optionsHeight}px`,
            overflowY: "auto",
          }}
        >
          <Table
            columns={columns}
            dataSource={subscribers?.data}
            pagination={false}
            className={styles.information_table}
            rowKey={"id"}
          />
        </div>
      </div>
    </>
  );
};

export default Subscribers;
