import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import trash from "../../../assets/img/papelera.svg";
import edit from "../../../assets/img/editar.svg";
import { Button, Input, Modal, Table, Form, Select, Pagination } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  showAlertSuccesQuestion,
  showAlertSucces,
  showAlertError,
} from "../../../components/Alerts";
import {
  deleteSubSection,
  getSubSection,
  saveSubSection,
  updateSubSection,
} from "../../../redux/dashboard/subsection/action";
import { getSections } from "../../../redux/dashboard/section/action";
import { showAlertQuestion } from "../../../components/Alerts/index";
import TextArea from "antd/lib/input/TextArea";

const Subsections = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { subSection }: any = useSelector((state: any) => state.subSection);
  const { sections }: any = useSelector((state: any) => state.sections);
  const [formCreate] = Form.useForm();
  const [formEdit] = Form.useForm();

  useEffect(() => {
    dispatch(getSections({ page: 1, per_page: 999 }));
    dispatch(getSubSection({ page: 1, per_page: 999 }));
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<any>({
    title: "",
    section: "",
  });
  const [dataPage, setDataPage] = useState<any>({
    page: 1,
    per_page: 15,
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelEdit = () => {
    setIsModalVisibleEdit(false);
  };

  const onFinishCreate = (data: any) => {
    showAlertQuestion(
      "¿Estas seguro que desea crear una nueva subsección?",
      "Crear",
      () => {
        dispatch(
          saveSubSection(
            data,
            () => {
              formCreate.resetFields();
              setIsModalVisible(false);
              dispatch(getSubSection({ page: 1, per_page: 999 }));
              showAlertSucces(
                "¡Subsección creada!",
                "La subsección ha sido creada exitosamente."
              );
            },
            () => {
              showAlertError(
                "Ha currido un error",
                "No se ha podido completar correctamente la accion, intentalo nuevamente"
              );
            }
          )
        );
      }
    );
  };

  const onFinishEdit = (data: any) => {
    setIsModalVisibleEdit(false);
    showAlertSuccesQuestion(
      "¿Estás seguro que desea editar la sección actual?",
      "Guardar",
      "¡Sección editada!",
      "La sección actual ha sido editada correctamente.",
      () => {
        dispatch(
          updateSubSection({
            ...dataEdit,
            title: data.title,
            section: data.section,
            description: data.description,
          })
        );
        formEdit.resetFields();
      }
    );
  };

  const onHandleDelete = (data: any) => {
    showAlertSuccesQuestion(
      "¿Estás seguro que desea eliminar la subsección actual?",
      "Eliminar",
      "Sección eliminada",
      "La sección actual ha sido eliminada correctamente.",
      () => {
        dispatch(deleteSubSection(data));
      }
    );
  };

  const onHandleEdit = (data: any) => {
    setDataEdit(data);
    setIsModalVisibleEdit(true);
    formEdit.setFieldsValue({
      title: data?.title,
      description: data?.description,
      section: data?.section?.id,
    });
  };

  const columns = [
    {
      title: "Nombre Subsección",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Sección de origen",
      render: (data: any) => data?.section?.title,
    },
    {
      title: "descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Fecha de creación",
      render: (data: any) => moment(data.createdAt).format("L"),
    },
    {
      title: "Opciones",
      key: "action",
      render: (data: any) => (
        <>
          <img
            src={trash}
            onClick={() => onHandleDelete({ id: data.id })}
            className={styles.icons_table}
          />
          <img
            src={edit}
            onClick={() => onHandleEdit(data)}
            className={styles.icons_table}
          />
        </>
      ),
    },
  ];

  const showTotal = (from: number, to: number, total: number) => {
    return `${from} - ${to} de ${total}`;
  };

  /* size table */
  const divHeight = useRef<any>(null);
  const divOptionsHeight = useRef<any>(null);
  const [height, setHeight] = useState<any>(100);
  const [optionsHeight, setOptionsHeight] = useState<any>(32);

  useEffect(() => {
    setHeight(divHeight?.current?.clientHeight);
    setOptionsHeight(divOptionsHeight?.current?.clientHeight);
  }, []);

  window.addEventListener("resize", (v: any) => {
    setHeight(divHeight?.current?.clientHeight);
    setOptionsHeight(divOptionsHeight?.current?.clientHeight);
  });

  return (
    <>
      <div className={styles.container_subsections} ref={divHeight}>
        <div className={styles.container_search_table} ref={divOptionsHeight}>
          <Button
            onClick={showModal}
            htmlType="submit"
            className={styles.button}
            block
          >
            Crear nueva subsección
          </Button>

          <Pagination
            className={styles.pagination}
            size="small"
            showSizeChanger={false}
            total={subSection?.total}
            showTotal={() =>
              showTotal(subSection?.from, subSection?.to, subSection?.total)
            }
            defaultCurrent={subSection?.current_page}
            defaultPageSize={subSection?.per_page}
            pageSize={subSection?.per_page || 15}
            onChange={(page) => setDataPage({ ...dataPage, page })}
            current={subSection?.current_page || 1}
          />
        </div>

        <div
          className={styles.container_table}
          style={{
            height: `${height - optionsHeight}px`,
            overflowY: "auto",
          }}
        >
          <Table
            columns={columns}
            dataSource={subSection?.data}
            pagination={false}
            className={styles.information_table}
          />
        </div>

        {/* modal create */}
        <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
          <div className={styles.container_content_modal}>
            <span className={styles.title_table}>Nueva subsección</span>
            <span className={styles.text_table}>Nombre</span>
            <Form form={formCreate} onFinish={onFinishCreate}>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un nombre",
                  },
                  {
                    validator: (rule, value) => {
                      if (
                        subSection?.data.filter(
                          (item: any) =>
                            item.title === value && item.id !== dataEdit.id
                        ).length > 0
                      ) {
                        return Promise.reject(
                          "Ya existe una subsección con este nombre."
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>

              <span className={styles.text_table}>Sección de origen</span>
              <Form.Item name="section">
                <Select
                  placeholder="Elegir sección"
                  className={styles.select_seccion}
                >
                  <Option className={styles.option_select}>
                    Elegir sección
                  </Option>
                  {sections?.data &&
                    sections.data.map((section: any) => (
                      <Option
                        key={section.id}
                        value={section.id}
                        className={styles.option_select}
                      >
                        {section.title || `section`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              {/* description */}
              <Form.Item className={styles.custom_form_item}>
                <span className={styles.text_seccion}>Descripción</span>
                <Form.Item
                  name="description"
                  className={styles.space_item}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Form.Item>

              <div className={styles.container_button}>
                <Button className={styles.save_button} htmlType="submit">
                  Guardar
                </Button>
                <Button
                  onClick={handleCancel}
                  className={styles.cancel_button}
                  block
                >
                  Cancelar
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        {/* modal edit */}
        <Modal
          visible={isModalVisibleEdit}
          footer={null}
          onCancel={handleCancelEdit}
        >
          <div className={styles.container_content_modal}>
            <span className={styles.title_table}>Editar subsección</span>
            <span className={styles.text_table}>Nombre</span>
            <Form form={formEdit} onFinish={onFinishEdit}>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un nombre",
                  },
                  {
                    validator: (rule, value) => {
                      if (
                        subSection?.data.filter(
                          (item: any) =>
                            item.title === value && item.id !== dataEdit.id
                        ).length > 0
                      ) {
                        return Promise.reject(
                          "Ya existe una subsección con este nombre."
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>

              <span className={styles.text_table}>Sección de origen</span>
              <Form.Item name="section">
                <Select
                  placeholder="Elegir sección"
                  className={styles.select_seccion}
                >
                  <Option className={styles.option_select}>
                    Elegir sección
                  </Option>
                  {sections?.data &&
                    sections.data.map((section: any) => (
                      <Option
                        key={section.id}
                        value={section.id}
                        className={styles.option_select}
                      >
                        {section.title || `section`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              {/* description */}
              <Form.Item className={styles.custom_form_item}>
                <span className={styles.text_seccion}>Descripción</span>
                <Form.Item
                  name="description"
                  className={styles.space_item}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Form.Item>

              <div className={styles.container_button}>
                <Button className={styles.save_button} block htmlType="submit">
                  Guardar
                </Button>
                <Button
                  onClick={handleCancelEdit}
                  className={styles.cancel_button}
                  block
                >
                  Cancelar
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Subsections;
