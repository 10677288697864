import React, { useState, useEffect } from "react";
import {
  updateAboutUs,
  getAboutUs,
} from "../../redux/dashboard/aboutUs/action";
import {
  showAlertQuestion,
  showAlertSucces,
  showAlertError,
} from "../Alerts/index";
import { Form, Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import UploadFile from "../Notice/uploadFile";
import JoditEditor from "jodit-react";
import EditorFooter from "../../pages/dashboard/home/footer/EditorFooter";

const AboutUs = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataForm, setDataForm] = useState<any>({
    imgBanner: "",
  });
  const { aboutUs } = useSelector((state: any) => state.aboutUs);

  useEffect(() => {
    if (aboutUs?.length > 0) {
      setDataForm({
        ...dataForm,
        ...aboutUs[0],
      });
    }
  }, [aboutUs]);

  useEffect(() => {
    dispatch(getAboutUs());
  }, []);

  /* Text and editor */
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    showAlertQuestion("¿Está seguro de guardar los cambios?", "Guardar", () => {
      dispatch(
        updateAboutUs(
          dataForm,
          () => {
            showAlertSucces(
              "¡Quienes somos!",
              "Quienes somos se ha creado correctamente"
            );
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
          () => {
            showAlertError(
              "Ha ocurrido un error",
              "No se ha podido crear Quienes somos exitosamente"
            );
          }
        )
      );
    });
  };

  const handleCancelOptions = () => {
    showAlertSucces(
      "Pie de página",
      "La edicion del pie de página ha sido cancelada"
    );
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  /* Editor */

  const updateEditor = (value: any) => {
    setDataForm({ ...dataForm, text: value });
  };

  return (
    <>
      <Form form={form}>
        <Form.Item name="quienesSomos">
          <span className={styles.edit_tex_notices}>Editar texto</span>
          <div className={styles.container_button}>
            <Button
              block
              htmlType="submit"
              onClick={showModal}
              className={styles.save_button}
            >
              Editar
            </Button>
          </div>
          <UploadFile
            dataForm={dataForm}
            setDataForm={setDataForm}
            nameFile={"imgBanner"}
          />
          <div className={styles.container_button_menu}>
            <Button
              block
              htmlType="submit"
              onClick={onFinish}
              className={styles.save_button}
            >
              Guardar
            </Button>
            <Button
              block
              htmlType="submit"
              onClick={handleCancelOptions}
              className={styles.save_button}
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Modal
        title="Editor"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            onClick={handleOk}
            className={styles.save_button}
          >
            Guardar
          </Button>,
        ]}
        width={1000}
      >
        <div className="App">
          <EditorFooter text={dataForm?.text} updateEditor={updateEditor} />
        </div>
      </Modal>
    </>
  );
};

export default AboutUs;
