import subscriberServices from "./services";
import subscriberConstants from "./constants";

const { LOADING, SET_ERROR, SET_SUBSCRIBERS } = subscriberConstants;

export const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

export const setError = (error: any) => ({
  type: SET_ERROR,
  error,
});

export const setSubscribers = (subscribers: any) => ({
  type: SET_SUBSCRIBERS,
  subscribers,
});

export const getSubscribers = (dataPage?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    try {

      const response = await subscriberServices.getAll(
        dataPage?.page,
        dataPage?.per_page
      );
      console.log(response);
      
      dispatch(setSubscribers(response.data));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};
