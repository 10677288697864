const authConstants = {
  SET_USER: "SET_USER",
  SET_USERS: "SET_USERS",
  CHECKING_SESSION: "USER_CHECKING_SESSION",
  LOGGING_IN: "USER_LOGGING_IN",
  SET_LOGIN_ERROR: "SET_LOGIN_ERROR",
  LOGGING_OUT: "USER_LOGGING_OUT",
  LOADING: "LOADING",
  SET_ERROR: "SET_ERROR",
  RESET: "RESET",
  SET_EMAIL_RECOVER: "SET_EMAIL_RECOVER",
};

export default authConstants;
