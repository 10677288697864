import React from "react";
import styles from "./styles.module.scss";
import img_404 from "../../assets/img/404.svg";
import { Col, Row } from "antd";

const NotFound = () => {
  return (
    <>
      <div className={styles.container}>
        <Row gutter={[0, 0]}>
          <Col span={12}>
            <div className={styles.container_img}>
              <img src={img_404} />
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.container_text}>
              <p className={styles.numero_404}>404</p>
              <p className={styles.text_404}>
                La página que intenta solicitar no está en el servidor
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NotFound;
