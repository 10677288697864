import React, { useState } from "react";
import { Input, Upload, Form } from "antd";
import styles from "../../pages/dashboard/notices/styles.module.scss";
import ImgCrop from "antd-img-crop";

const { TextArea } = Input;

const UploadImgs = (props: any) => {
  const { dataForm, setDataForm, nameFile } = props;
  const [dataFile, setDataFile] = useState<any>(null);

  return (
    <Form form={props?.form}>
      <Form.Item className={styles.custom_form_item}>
        <div className={styles.container_upload}>
          <Form.Item name={nameFile} className={styles.custom_form_item}>
            <ImgCrop
              rotate
              aspect={
                nameFile === "imgBanner"
                  ? 3.56 / 1
                  : nameFile === "imgPodcast"
                  ? 1920 / 1080
                  : 1097 / 700
              }
            >
              <Upload
                name="introductionImg"
                onRemove={() => {
                  setDataFile(null);
                  setDataForm({ ...dataForm, [nameFile]: null });
                }}
                customRequest={({
                  file,
                  onError,
                  onSuccess,
                  onProgress,
                }: any) => {
                  onSuccess();
                  setDataFile(file);
                  setDataForm({ ...dataForm, [nameFile]: file });
                }}
                listType="picture-card"
              >
                {dataFile ? null : "+ Upload"}
              </Upload>
            </ImgCrop>
          </Form.Item>
          {!props?.type && (
            <span className={styles.span_text}>
              {props?.message ? props?.message : 'Recommanded resolution is 1440*404 with file size less than 2MB, keep visual elements centered' }
              
            </span>
          )}
        </div>
      </Form.Item>
      {props?.nameTextImg && (
        <Form.Item name="textImgBanner">
          <TextArea
            placeholder="Escribe aquí"
            rows={2}
            onChange={(e) =>
              setDataForm({
                ...dataForm,
                [props?.nameTextImg]: e.target.value,
              })
            }
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default UploadImgs;
