import blockConstants from "./constants";

const { SET_BLOCK, SET_BLOCKS, LOADING, SET_ERROR, SET_SECTIONS } =
  blockConstants;

export const initialState = {
  blocks: null,
};

const blockReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_BLOCK:
      return {
        ...state,
        block: action.block,
      };
    case SET_BLOCKS:
      return {
        ...state,
        blocks: action.blocks,
      };
    case SET_SECTIONS:
      return {
        ...state,
        sections: action.sections,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default blockReducer;
