import api from "../../../helpers/api";

const barMenuServices = {
  getAll: () => {
    return api.get("/barMenu/getAll");
  },

  update: (data: any) => {
    return api.put("/barMenu/update", {
      data,
    });
  },
};

export default barMenuServices;
