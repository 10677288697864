import privacyPolicyConstants from "./constants";

const { SET_PRIVACY_POLICY, UPDATE_PRIVACY_POLICY, LOADING, SET_ERROR } =
  privacyPolicyConstants;

export const initialState = {
  privacyPolicy: null,
};

const privacyPolicyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PRIVACY_POLICY:
      return {
        ...state,
        privacyPolicy: action.privacyPolicy,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default privacyPolicyReducer;
