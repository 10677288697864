import api from "../../../helpers/api";

const privacyPolicyServices = {
  getAll: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get("/privacyPolicy/getAll", {
      params: {
        page,
        per_page,
      },
    });
  },

  update: (data: any) => {
    return api.put("/privacyPolicy/update", {
      data,
    });
  },
};

export default privacyPolicyServices;
