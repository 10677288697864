import subscriberConstants from "./constants";

const { LOADING, SET_ERROR, SET_SUBSCRIBERS } = subscriberConstants;

export const initialState = {
    subscriber: null,
    subscribers: null,
  };


  const subscriberReducer =  (state = initialState, action: any) => {
    switch (action.type) {

        case SET_SUBSCRIBERS:
            return{
                ...state,
                subscribers: action.subscribers,
            }
        case LOADING:
          return {
            ...state,
            loading: action.loading,
          };
        case SET_ERROR:
          return {
            ...state,
            error: action.error,
          };
        default:
          return state;
      }
  };

  
export default subscriberReducer;