import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Menu from "../../../../components/Menu/index";
import { Select, Form, Input, Button, Switch, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getBanner,
  updateBanner,
} from "../../../../redux/dashboard/banner/action";
import { getNewsWhere } from "../../../../redux/dashboard/news/action";
import moment from "moment";
import { getSections } from "../../../../redux/dashboard/section/action";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
} from "../../../../components/Alerts";

const Banner = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [formFilter] = Form.useForm();

  const [rowSelected, setRowSelected] = useState<any>([]);
  const [optionsTable, setOptionsTable] = useState<any>([]);
  const [dataFilter, setDataFilter] = useState<any>({
    title: null,
    publicationDate: null,
    keywords: [],
    section: null,
  });

  const { newss } = useSelector((state: any) => state.news);
  const { banner } = useSelector((state: any) => state.barBanner);
  const { sections } = useSelector((state: any) => state.sections);

  useEffect(() => {
    dispatch(getBanner());
    dispatch(getNewsWhere({}));
    dispatch(getSections({ page: 1, per_page: 999 }));
  }, []);

  /* tablet */
  useEffect(() => {
    if (banner?.length > 0)
      setRowSelected(banner[0]?.news?.id ? [banner[0]?.news?.id] : []);
  }, [banner]);

  useEffect(() => {
    if (banner) {
      if (newss?.data?.length > 0 && banner) {
        

        let newsFilter = [];
        newsFilter = newss.data.filter(
          (news: any) => news?.id !== banner[0]?.news?.id
        );
        if (banner[0]?.news) {
          setOptionsTable([banner[0]?.news, ...newsFilter]);
        } else {
          setOptionsTable([...newsFilter]);
        }
      } else {
        if (newss?.length > 0 && banner[0]?.news) {
          setOptionsTable([banner[0]?.news]);
        } else {
          setOptionsTable(newss.data);
        }
      }
    }
  }, [newss]);

  useEffect(() => {
    dispatch(getNewsWhere(dataFilter));
  }, [dataFilter]);

  const columns = [
    {
      title: "Noticia",
      render: (row: any) => (
        <div>
          <span className={styles.span_select}>
            {`${row?.section?.title} | ${moment(row.createdAt).format("LLL")}`}{" "}
          </span>
          <p className={styles.notice_title}>{row.title}</p>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setRowSelected([...selectedRowKeys]);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onHandleSubmitFilter = (value: any) => {
    dispatch(getNewsWhere(value));
  };

  const onHandleUpdate = () => {
    showAlertQuestion(
      "¿Estás seguro que desea guardar los cambios en barra banner?",
      "Guardar",
      () => {
        dispatch(
          updateBanner(
            { id: banner[0]?.id, news: rowSelected[0] },
            () => {
              showAlertSucces(
                "¡Barra banner editada!",
                "La barra banner ha sido editada correctamente."
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  return (
    <>
      <Menu>
        <div>
          <div>
            {banner?.length > 0 && (
              <div className={styles.container_search}>
                <span className={styles.span_select}>Es visible: </span>
                <Switch
                  size={"small"}
                  className={styles.switch}
                  checked={banner && banner[0]?.status}
                  onChange={(checked) =>
                    dispatch(
                      updateBanner({ id: banner[0]?.id, status: checked })
                    )
                  }
                />
              </div>
            )}

            <Form
              form={formFilter}
              className={styles.container_search}
              onFinish={onHandleSubmitFilter}
            >
              <Form.Item noStyle name={"title"}>
                <Input
                  placeholder="Escribe aquí"
                  size="middle"
                  allowClear
                  className={styles.search_select}
                  value={dataFilter.title}
                />
              </Form.Item>

              <Form.Item noStyle>
                <Button
                  htmlType={"submit"}
                  block
                  className={styles.search_button}
                >
                  Buscar
                </Button>
              </Form.Item>
            </Form>
            <div className={styles.container_search}>
              <Form form={formFilter} className={styles.spacing_select}>
                <Form.Item noStyle>
                  <span className={styles.span_select}>
                    Fecha de publicación:
                  </span>
                  <Form.Item noStyle name="publicationDate">
                    <Select
                      placeholder="Cualquiera"
                      className={styles.select_seccion}
                      value={dataFilter.publicationDate}
                      onChange={(e) =>
                        setDataFilter({ ...dataFilter, publicationDate: e })
                      }
                    >
                      <Option className={styles.option_select} value={7}>
                        últimos 7 días
                      </Option>
                      <Option className={styles.option_select} value={30}>
                        últimos 30 días
                      </Option>
                      <Option className={styles.option_select} value={30 * 6}>
                        últimos 6 meses
                      </Option>
                      <Option className={styles.option_select} value={30 * 12}>
                        último año
                      </Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
              </Form>

              <Form form={formFilter} className={styles.spacing_select}>
                <Form.Item noStyle>
                  <span className={styles.span_select}>Palabras claves:</span>
                  <Form.Item noStyle name="keywords">
                    <Select
                      mode="tags"
                      className={styles.select_seccion}
                      value={dataFilter.keywords}
                      onChange={(e) =>
                        setDataFilter({ ...dataFilter, keywords: e })
                      }
                    >
                      <Option className={styles.option_select} value="notice">
                        notice
                      </Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
              </Form>
            </div>
            <div className={styles.container_search}>
              <Form form={formFilter} className={styles.spacing_select}>
                <Form.Item noStyle>
                  <span className={styles.span_select}>Sección:</span>
                  <Form.Item noStyle name="section">
                    <Select
                      placeholder="Seleccionar sección"
                      className={styles.select_seccion}
                      value={dataFilter.section}
                      onChange={(e) =>
                        setDataFilter({ ...dataFilter, section: e })
                      }
                    >
                      <Option className={styles.option_select}>
                        Seleccione una opcion
                      </Option>
                      {sections?.data &&
                        sections.data.map((section: any) => (
                          <Option
                            className={styles.option_select}
                            value={section.id}
                            key={section.id}
                          >
                            {section.title}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
              </Form>
            </div>
            <Form
              hidden
              form={formFilter}
              className={styles.container_search}
              onFinish={onHandleSubmitFilter}
            >
              <Form.Item noStyle>
                <Button
                  className={styles.save_button_notices}
                  htmlType={"submit"}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Form>
          </div>

          <Table
            rowSelection={{
              type: "radio",
              selectedRowKeys: rowSelected,
              ...rowSelection,
            }}
            showHeader={false}
            columns={columns}
            dataSource={optionsTable || []}
            pagination={false}
            className={styles.container_table}
            rowKey="id"
          />
          <div className={`${styles.container_buttons}`}>
            <Button
              className={styles.save_button_notices}
              block
              onClick={onHandleUpdate}
            >
              Guardar
            </Button>
            <Button
              className={styles.cancel_button_notices}
              /* onClick={() => {
                dispatch(setMenu(false));
                navigate("/dashboard/post_news");
              }} */
              block
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Menu>
    </>
  );
};

export default Banner;
