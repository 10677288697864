import authConstants from "./constants";
const {
  CHECKING_SESSION,
  LOGGING_IN,
  LOGGING_OUT,
  SET_USER,
  SET_USERS,
  SET_LOGIN_ERROR,
  LOADING,
  SET_EMAIL_RECOVER,
  SET_ERROR,
} = authConstants;

export const initialState = {
  user: null,
  users: null,
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHECKING_SESSION:
      return {
        ...state,
        checkingSession: action.isChecking,
      };
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: action.isLoggingIn,
      };
    case LOGGING_OUT:
      return {
        ...state,
        loggingOut: action.isLoggingOut,
      };
    case SET_EMAIL_RECOVER:
      return {
        ...state,
        emailRecover: action.email,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: action.loginError,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default authReducer;
