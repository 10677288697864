import privacyPolicyServices from "./services";
import PrivacyPolicyConstants from "./constants";

const { SET_PRIVACY_POLICY, UPDATE_PRIVACY_POLICY, LOADING, SET_ERROR } =
  PrivacyPolicyConstants;

const setPrivacyPolicy = (privacyPolicy: any) => ({
  type: SET_PRIVACY_POLICY,
  privacyPolicy,
});

const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

export const updatePrivacyPolicy = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    try {
      const response = await privacyPolicyServices.update(data);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const getPrivacyPolicy = (dataPage?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await privacyPolicyServices.getAll(
        dataPage?.page,
        dataPage?.per_page
      );
      dispatch(setPrivacyPolicy(response.data.response));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};
