import announcerConstants from "./constants";

const { LOADING, SET_ERROR, SET_ANNOUNCERS } = announcerConstants;

export const initialState = {
  anouncer: null,
  announcers: null,
};

const announcerReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case SET_ANNOUNCERS:
        return{
            ...state,
           announcers: action.announcers,
        }
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default announcerReducer;
