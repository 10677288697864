import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Menu from "../../../components/Menu";
import {
  Collapse,
  Select,
  Form,
  Button,
  Drawer,
  Table,
  Tooltip,
  Switch,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
  showAlertSuccesQuestion,
} from "../../../components/Alerts";
import {
  deletePlace,
  getPlaces,
  updatePlaces,
} from "../../../redux/dashboard/menuBurger/action";
import { getSections } from "../../../redux/dashboard/section/action";
import { useDispatch, useSelector } from "react-redux";
import { setSubMenu } from "../../../redux/settings/action";
import { ColumnsType } from "antd/lib/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import FormEditMenuBurger from "../../../components/Forms/FormEditMenuBurger";
import FormCreateMenuBurger from "../../../components/Forms/FormCreateMenuBurger";

const MenuBurger = () => {
  const [menuBurgerEdit, setMenuBurgerEdit] = useState<any>(null);
  const [visibleMenuEdit, setVisibleMenuEdit] = useState<boolean | undefined>(
    false
  );
  const [visibleMenuCreate, setVisibleMenuCreate] = useState<
    boolean | undefined
  >(false);

  const dispatch = useDispatch();
  const { places } = useSelector((state: any) => state.menuBurger);
  const { sections } = useSelector((state: any) => state.sections);
  const { subMenu, menu } = useSelector((state: any) => state.settings);

  useEffect(() => {
    dispatch(getSections({ page: 1, per_page: 999 }));
    dispatch(getPlaces());
  }, []);

  const onHandleDelete = (data: any) => {
    showAlertSuccesQuestion(
      "¿Estás seguro que desea eliminar el espacio?",
      "Eliminar",
      "Espacio eliminado",
      "El espacio actual ha sido eliminado correctamente.",
      () => {
        dispatch(deletePlace(data));
      }
    );
  };

  /* drawers */
  const onCloseMenu = () => {
    dispatch(setSubMenu({ ...subMenu, menuBurger: false }));
  };
  const onCloseMenuEdit = () => {
    setVisibleMenuEdit(false);
  };
  const onCloseMenuCreate = () => {
    setVisibleMenuCreate(false);
  };

  /* tablet */

  const columns: ColumnsType<any> = [
    {
      title: "Espacios",
      render: (record) => record?.section?.title || `Espacio vacio`,
    },
    {
      title: "Opciónes",
      render: (row: any) => (
        <div className={styles.container_options_table}>
          <Tooltip title="Visible">
            <Switch
              size="small"
              checked={row.status}
              onChange={(checked) =>
                dispatch(updatePlaces({ id: row.id, status: checked }))
              }
            />
          </Tooltip>
          <Tooltip title="Editar">
            <FontAwesomeIcon
              icon={faPencilAlt}
              className={styles.icon_bars}
              onClick={() => {
                setMenuBurgerEdit(row);
                setVisibleMenuCreate(false);
                setVisibleMenuEdit(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className={styles.icon_bars}
              onClick={() => onHandleDelete({ id: row.id })}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  /* size table */
  const divHeight = useRef<any>();
  const [height, setHeight] = useState<any>(0);

  useEffect(() => {
    setHeight(divHeight.current.clientHeight);
  }, []);

  window.addEventListener("resize", (v: any) => {
    setHeight(divHeight.current.clientHeight);
  });

  return (
    <>
      <div ref={divHeight} style={{ height: "100%" }}>
        <Drawer
          visible={subMenu?.menuBurger}
          placement={"left"}
          onClose={onCloseMenu}
          className={styles.custom_drawer}
          getContainer={false}
          closable={false}
          mask={false}
          maskClosable={false}
          style={{ height: `${height}px` }}
        >
          <Button
            htmlType="submit"
            className={styles.button_add_blocks}
            block
            onClick={() => {
              setVisibleMenuEdit(false);
              setVisibleMenuCreate(true);
            }}
          >
            Crear nuevo espacio
          </Button>
          <Table
            columns={columns}
            pagination={false}
            dataSource={places || []}
            rowKey={(record: any) => record.id}
          />
        </Drawer>

        {/* edit */}
        <Drawer
          title={`Editar espacio`}
          visible={visibleMenuEdit}
          placement={"right"}
          onClose={onCloseMenuEdit}
          getContainer={false}
          className={styles.custom_drawer_options}
          mask={false}
          style={{ height: `${height}px` }}
        >
          {menuBurgerEdit && (
            <FormEditMenuBurger
              sections={sections}
              menuBurgerEdit={menuBurgerEdit}
              setVisibleMenuEdit={setVisibleMenuEdit}
            />
          )}
        </Drawer>

        {/* create */}
        <Drawer
          title={`Crear espacio`}
          visible={visibleMenuCreate}
          placement={"right"}
          onClose={onCloseMenuCreate}
          getContainer={false}
          className={styles.custom_drawer_options}
          mask={false}
          style={{ height: `${height}px` }}
        >
          <FormCreateMenuBurger
            sections={sections}
            setVisibleMenuCreate={setVisibleMenuCreate}
          />
        </Drawer>
      </div>
    </>
  );
};

export default MenuBurger;
