import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Table,
  Tooltip,
  Upload,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
} from "../../../components/Alerts";
import UploadFile from "../../../components/Notice/uploadFile";

import edit from "../../../assets/img/editar.svg";

import del from "../../../assets/img/papelera.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnnouncers,
  newAnnouncer,
  removeAnnouncer,
  editAnnouncer,
} from "../../../redux/dashboard/announcer/action";
import Spinner from "../../../components/Spinner";
import moment from "moment";
import { Record } from "immutable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

const Announcers = () => {
  const dispatch = useDispatch();

  const [formCreate] = Form.useForm();
  const [fileListEdit, setFileListEdit] = useState<any>([]);
  const [fileLogo, setFileLogo] = useState<any>([]);
  const [fileBanner, setFileBanner] = useState<any>([]);
  const [dataBanner, setDataBanner] = useState<any>([]);
  const [dataLogo, setDataLogo] = useState<any>([]);
  const [dataPage, setDataPage] = useState<any>({
    page: 1,
    per_page: 15,
  });

  //-----------------------Edit modal states functions ------------------------
  const [formEdit] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<any>({});
  const showModalEdit = () => {
    setIsModalVisibleEdit(true);
  };
  const handleCancelEdit = () => {
    setIsModalVisibleEdit(false);
  };
  const onHandleEdit = (data: any) => {
    console.log(data);
    setDataBanner(data.brandBanner);
    setDataLogo(data.brandLogo);
    delete data.endDate;
    setDataEdit(data);
    formEdit.setFieldsValue(data);
    setTextColor({ pickedTextColor: data.textColor });
    setBrandColor({ pickedBrandColor: data.brandColor });
    showModalEdit();
  };

  const onFinishEdit = (value: any) => {
    value.brandLogo = dataLogo.brandLogo;
    value.brandBanner = dataBanner.brandBanner;

    if (!value?.name || value?.name.trim() === "") delete value.name;
    if (!value?.brand || value?.brand.trim() === "") delete value.brand;
    if (typeof value?.brandBanner === "string") {
      delete value.brandBanner;
    }
    if (typeof value?.brandLogo === "string") {
      delete value.brandLogo;
    }
    if (!value?.brandBanner) delete value.brandBanner;
    if (!value?.brandLogo) delete value.brandLogo;
    if (!value?.endDate) delete value.endDate;
    if (!value?.email || value?.email.trim() === "") delete value.email;

    value.id = dataEdit.id;
    console.log(value);
    showAlertQuestion(
      "¿Estás seguro que desea los cambios realizados en el anunciante?",
      "Guardar",
      () => {
        dispatch(
          editAnnouncer(
            value,
            () => {
              formEdit.resetFields();
              handleCancelEdit();
              dispatch(getAnnouncers());
              showAlertSucces(
                "Anunciante  actualizado",
                "El anunciante ha sido actualizado correctamente"
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };
  const SetAnnoucerVis = (record: any) => {
    record.visible = !record.visible;
    const value = { id: record.id, visible: record.visible };
    console.log(value);

    dispatch(
      editAnnouncer(value, () => {
        dispatch(getAnnouncers);
      })
    );
  };

  //----------------Color picker states----------------
  const [brandColor, setBrandColor] = useState({
    pickedBrandColor: "#FF8A65",
  });

  const [textColor, setTextColor] = useState({
    pickedTextColor: "#FF8A65",
  });

  //------- --------------------Form Functions---------------------------
  const onFinishCreate = (value: any) => {
    console.log(value);
    value.brandLogo = dataLogo.brandLogo;
    value.brandBanner = dataBanner.brandBanner;
    const values = {
      ...value,
      endDate: value["endDate"].format("YYYY-MM-DD"),
    };
    values.brandColor = brandColor.pickedBrandColor;
    values.textColor = textColor.pickedTextColor;
    values.visible = true;
    console.log(values);
    showAlertQuestion(
      "¿Estás seguro que desea crear un nuevo Anunciante?",
      "Crear",
      () => {
        dispatch(
          newAnnouncer(
            values,
            () => {
              formCreate.resetFields();
              handleCancel();
              dispatch(getAnnouncers());
              showAlertSucces(
                "Anunciante creado!",
                "El Anunciante ha sido creado correctamente"
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const { loading, announcers } = useSelector((state: any) => state.announcer);
  const { users } = useSelector((state: any) => state.auth);
  useEffect(() => {
    dispatch(getAnnouncers());
  }, [dataPage]);

  const showTotal = (from: number, to: number, total: number) => {
    return `${from} - ${to} de ${total}`;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onPreview = async (file: any) => {
    console.log(file.url);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (isJpgOrPng) {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow: any = window.open(src);
      imgWindow.document.write(image.outerHTML);
    }
  };

  function beforeUpload(file: { type: string; size: number }) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      showAlertError(
        "Ha ocurrido un error",
        "No se ha podido cargar correctamente el archivo, intentalo nuevamente."
      );
    }
    const isLt2M = file.size / 640 / 640 < 2;
    if (!isLt2M) {
      showAlertError("Ha ocurrido un error", "Solo permite archivos de 2MB.");
    }
    return false;
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.file && e.fileList[0];
  };

  //------------------------------Table functions---------------------------
  const handelDelete = (id: any) => {
    const data = { id };
    showAlertQuestion(
      "¿Estás seguro que desea borrar este anunciante?",
      "Borrar",
      () => {
        dispatch(
          removeAnnouncer(
            data,
            () => {
              dispatch(getAnnouncers(dataPage));
              showAlertSucces("!Anunciante eliminado¡", "");
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Marca",
      dataIndex: "brand",
      key: "bran",
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Fecha Finalizacion",
      render: (row: any) => moment(row.endDate).format("L"),
    },
    {
      title: "Opciones",
      key: "action",
      render: (record: any) => (
        <>
          <div className={styles.container_button_table_notices}>
            <Tooltip title="Eliminar">
              <FontAwesomeIcon
                icon={faTrashAlt}
                className={styles.icon_bars}
                onClick={() => handelDelete(record.id)}
              />
            </Tooltip>
            <Tooltip title="Editar">
              <FontAwesomeIcon
                icon={faPencilAlt}
                className={styles.icon_bars}
                onClick={() => onHandleEdit(record)}
              />
            </Tooltip>
            {record.visible ? (
              <Tooltip title="Visible">
                <FontAwesomeIcon
                  icon={faEye}
                  className={styles.icon_bars}
                  style={{ color: "#5bbcf7" }}
                  onClick={() => {
                    SetAnnoucerVis(record);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="No visible">
                <FontAwesomeIcon
                  icon={faEye}
                  className={styles.icon_bars}
                  onClick={() => {
                    SetAnnoucerVis(record);
                  }}
                />
              </Tooltip>
            )}
          </div>
        </>
      ),
    },
  ];

  /* size table */
  const divHeight = useRef<any>(null);
  const divOptionsHeight = useRef<any>(null);
  const [height, setHeight] = useState<any>(100);
  const [optionsHeight, setOptionsHeight] = useState<any>(34);

  useEffect(() => {
    setHeight(divHeight?.current?.clientHeight);
  }, []);

  window.addEventListener("resize", (v: any) => {
    setHeight(divHeight?.current?.clientHeight);
    setOptionsHeight(divOptionsHeight?.current?.clientHeight);
  });

  return (
    <>
      {loading && <Spinner />}
      <div className={styles.container_signature} ref={divHeight}>
        {/* <div className={styles.line}></div> */}
        <div className={styles.container_header_search} ref={divOptionsHeight}>
          <div>
            <Button
              onClick={showModal}
              htmlType="submit"
              className={styles.button}
              block
            >
              Crear nuevo anunciante
            </Button>
          </div>
          <div className={styles.container_search}>
            <div className={styles.container_options}>
              <span className={styles.text_search}>Buscar:</span>
              <Form>
                <Input
                  placeholder="Escribe aqui"
                  className={styles.input_search}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form>
              <Button htmlType="submit" className={styles.search_button} block>
                Buscar
              </Button>
            </div>
            <Pagination
              className={styles.pagination}
              size="small"
              showTotal={() =>
                showTotal(announcers?.from, announcers?.to, announcers?.total)
              }
              defaultCurrent={announcers?.current_page}
              defaultPageSize={announcers?.per_page}
              pageSize={announcers?.per_page || 15}
              total={announcers?.total}
              onChange={(page) => setDataPage({ ...dataPage, page })}
            />
          </div>
        </div>

        <div
          className={styles.container_table}
          style={{
            height: `${height - optionsHeight}px`,
            overflowY: "auto",
          }}
        >
          <Table
            columns={columns}
            dataSource={announcers?.data}
            pagination={false}
            className={styles.information_table}
            rowKey={"id"}
          />
        </div>
        {/* modal create*/}
        <Modal
          visible={isModalVisible} //falta funcion
          footer={null}
          onCancel={handleCancel}
        >
          <div className={styles.container_content_modal}>
            <span className={styles.title_table}>Nuevo anunciante</span>
            <span className={styles.text_table}>Nombre</span>
            <Form form={formCreate}>
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "Porfavor escriba un nombre" },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Marca</span>
            <Form form={formCreate}>
              <Form.Item
                name="brand"
                rules={[
                  {
                    required: true,
                    message: "Porfavor escriba un nombre de marca",
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Correo</span>
            <Form form={formCreate}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un correo",
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Fecha de finalización</span>
            <Form form={formCreate}>
              <Form.Item
                name="endDate"
                rules={[
                  {
                    required: true,
                    message: "seleccione una fecha de finalización",
                  },
                ]}
              >
                <DatePicker className={styles.date_picker} />
              </Form.Item>
            </Form>
            <Row justify="space-around" align="middle">
              <Col>
                <div className={styles.color_picker_container}>
                  <input
                    type="color"
                    className={styles.button_color_picker}
                    value={brandColor.pickedBrandColor || "#000"}
                    onChange={(e) =>
                      setBrandColor({ pickedBrandColor: e.target.value })
                    }
                  />
                  <span className={styles.text_color_picker}>
                    color de marca
                  </span>
                </div>
              </Col>

              <Col>
                <div className={styles.color_picker_container}>
                  <input
                    type="color"
                    className={styles.button_color_picker}
                    value={textColor.pickedTextColor || "#000"}
                    onChange={(e) =>
                      setTextColor({ pickedTextColor: e.target.value })
                    }
                  />
                  <span className={styles.text_color_picker}>
                    color de texto
                  </span>
                </div>
              </Col>
            </Row>
            <span className={styles.text_table}>Logo</span>
            <div className={styles.container_upload}>
              <div className={styles.container_upload}>
                <UploadFile
                  form={formCreate}
                  dataForm={dataLogo}
                  setDataForm={setDataLogo}
                  nameFile={"brandLogo"}
                />
              </div>
            </div>
            <span className={styles.text_table}>Banner</span>
            <div className={styles.container_upload}>
              <div className={styles.container_upload}>
                <UploadFile
                  form={formCreate}
                  dataForm={dataBanner}
                  setDataForm={setDataBanner}
                  nameFile={"brandBanner"}
                />
              </div>
            </div>
            <span className={styles.text_table}>Sitio web anunciante</span>
            <Form form={formCreate}>
              <Form.Item
                name="site"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un correo",
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <div className={styles.container_button}>
              <Form form={formCreate} onFinish={onFinishCreate} layout="inline">
                <Form.Item>
                  <Button
                    className={styles.save_button}
                    loading={loading}
                    block
                    htmlType="submit"
                  >
                    Guardar
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={handleCancel}
                    className={styles.cancel_button}
                    block
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
        {/*   Edit modal   */}
        <Modal
          visible={isModalVisibleEdit}
          footer={null}
          onCancel={handleCancelEdit}
        >
          <div className={styles.container_content_modal}>
            <span className={styles.title_table}>Editar anunciante</span>
            <span className={styles.text_table}>Nombre</span>
            <Form form={formEdit}>
              <Form.Item name="name">
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Marca</span>
            <Form form={formEdit}>
              <Form.Item name="brand">
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Correo</span>
            <Form form={formEdit}>
              <Form.Item name="email">
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <span className={styles.text_table}>Fecha de finalización</span>
            <Form form={formEdit}>
              <Form.Item name="endDate">
                <DatePicker className={styles.date_picker} />
              </Form.Item>
            </Form>
            <Row justify="space-around" align="middle">
              <Form form={formEdit}>
                <Col>
                  <Form.Item name="brandColor">
                    <div className={styles.color_picker_container}>
                      <input
                        type="color"
                        className={styles.button_color_picker}
                        value={brandColor.pickedBrandColor || "#000"}
                        onChange={(e) =>
                          setBrandColor({ pickedBrandColor: e.target.value })
                        }
                      />
                      <span className={styles.text_color_picker}>
                        color de marca
                      </span>
                    </div>
                  </Form.Item>
                </Col>
              </Form>
              <Form form={formEdit}>
                <Col>
                  <Form.Item name="textColor">
                    <div className={styles.color_picker_container}>
                      <input
                        type="color"
                        className={styles.button_color_picker}
                        value={textColor.pickedTextColor || "#000"}
                        onChange={(e) =>
                          setTextColor({ pickedTextColor: e.target.value })
                        }
                      />
                      <span className={styles.text_color_picker}>
                        color de texto
                      </span>
                    </div>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
            <div className={styles.currPrev}>
              <span className={styles.text_table}>Logo Actual:</span>
              <img
                src={dataLogo}
                alt="curr logo"
                style={{ width: "10rem", marginLeft: "5rem" }}
              />
            </div>
            <span className={styles.text_table}>Logo</span>
            <div className={styles.container_upload}>
              <UploadFile
                form={formEdit}
                dataForm={dataLogo}
                setDataForm={setDataLogo}
                nameFile={"brandLogo"}
              />
            </div>
            <div className={styles.currPrev}>
              <span className={styles.text_table}>Banner Actual:</span>
              <img
                src={dataBanner}
                alt="curr logo"
                style={{ width: "10rem", marginLeft: "5rem" }}
              />
            </div>
            <span className={styles.text_table}>Banner</span>
            <div className={styles.container_upload}>
              <UploadFile
                form={formEdit}
                dataForm={dataBanner}
                setDataForm={setDataBanner}
                nameFile={"brandBanner"}
              />
            </div>
            <span className={styles.text_table}>Sitio web anunciante</span>
            <Form form={formEdit}>
              <Form.Item
                name="site"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un correo",
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <div className={styles.container_button}>
              <Form form={formEdit} onFinish={onFinishEdit} layout="inline">
                <Form.Item>
                  <Button
                    className={styles.save_button}
                    block
                    htmlType="submit"
                  >
                    Guardar
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={handleCancelEdit}
                    className={styles.cancel_button}
                    block
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Announcers;
