import React from "react";
import Layout, { Content } from "antd/lib/layout/layout";
import styles from "./styles.module.scss";
import { Row, Col } from "antd";
import moment from "moment";
import "moment/locale/es";
import { imgNoExist } from "../../../helpers/img";

const ModuleThree = ({ block }: any) => {
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1440}>
            <Row>
              <Col span={12}>
                <div className={styles.container_background}>
                  <div className={styles.container_block_three}>
                    <div className={styles.container_border}>
                      <div
                        style={{ background: "#FFFFFF" }}
                        className={styles.border}
                      ></div>
                      <span className={styles.title_block}>
                        {block?.title || "Modulo 3"}
                      </span>
                    </div>
                    <div className={styles.container_title}>
                      <p className={styles.title}>
                        {block?.new1?.title || `Titulo ejemplo`}
                      </p>
                    </div>
                    <span className={styles.signature}>
                      Por Andrés Rodríguez
                    </span>
                    <div className={styles.container_parrafo}>
                      <p className={styles.subtitle}>
                        {block?.new1?.subTitle || `Sub titulo ejemplo`}
                      </p>
                    </div>
                    <span className={styles.reading}>
                      {moment(block?.new1?.createdAt)
                        .locale("es")
                        .startOf("day")
                        .fromNow() || `Tiempo prueba`}
                    </span>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={styles.container_block_medium}
                  style={{
                    backgroundImage: `url(${
                      block?.new1?.imgBanner || imgNoExist
                    })`,
                  }}
                ></div>
              </Col>
            </Row>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleThree;
