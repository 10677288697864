import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";
import React, { useMemo, useRef } from "react";

const EditorNews = (props: any) => {
  const text = props?.text;
  const editor = useRef<any>();

  const config = {
    width: "auto",
    height: "auto",
    maxWidth: 708,
    showPlaceholder: true,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    toolbarInlineForSelection: true,
    editorCssClass: "editor_container",
    triggerChangeEvent: true,
    uploader: {
      insertImageAsBase64URI: true,
      imagesExtensions: ["jpg", "png", "jpeg", "gif"],
    },
    toolbarAdaptive: false,
    disablePlugins: ["color", "about", "source", "file", "iframe"],
    plugins: ["iframe"],
    extraButtons: [
      {
        tooltip: "Enter text and insert",
        iconURL:
          "https://image.similarpng.com/very-thumbnail/2020/05/Icon-Twitter-PNG.png",
        popup: (editor: any, current: any, self: any, close: any) => {
          const form = editor.create.fromHTML(
            `
            <div class="jodit-popup__content">

              <div class="jodit-tabs__wrapper">
                  <form>
                    <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle">
                      <div class="jodit-ui-input jodit-ui-block__url">
                        <span class="jodit-ui-input__label">URL</span>
                        <div class="jodit-ui-input__wrapper">
                          <input class="jodit-ui-input__input" dir="auto" name="url" type="text" data-ref="url" ref="url" required="true" placeholder="https://">
                        </div>
                      </div>
                    </div>    
                    <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle">
                      <button class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_variant_primary jodit-ui-button_text-icons_true" type="submit" role="button" aria-pressed="false" tabindex="-1">
                        <span class="jodit-ui-button__icon"></span>
                        <span class="jodit-ui-button__text">Insert</span>
                      </button>
                    </div>
                  </form>
              </div>
            </div>
            `
          );

          editor.e.on(form, "submit", (e: any) => {
            e.preventDefault();
            const url: string = form.querySelector("input").value;

            if (url.trim().length > 0) {
              if (
                url.includes("https://twitter.com") ||
                url.includes("https://www.twitter.com")
              ) {
                editor.s
                  .insertHTML(`<iframe border=0 frameborder=0 width=100% height=100% 
                src="https://twitframe.com/show?url=${encodeURIComponent(
                  url
                )}"></iframe>`);
              }
            }

            close();
          });

          return form;
        },
      },
    ],
  };

  return useMemo(
    () => (
      <div className="App">
        <JoditEditor
          key={1} // tabIndex of textarea
          ref={editor}
          config={config}
          value={text}
          onChange={props.updateEditor}
        />
      </div>
    ),
    []
  );
};

export default EditorNews;
