import React, { useEffect, useState } from 'react'
import { Collapse, Select, Form, Button, Switch, Row, Col, Input, Upload, Typography } from "antd";
import styles from './styles.module.scss';
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { Panel } = Collapse;
import { SocialPanel } from "./models";
import { UploadFile } from 'antd/lib/upload/interface';
import UploadImage from '../../../components/utils/Upload';
import { useSelector } from "react-redux";
import { Social } from './models';

export default function ItemSocial({ social, onCancel, onSubmit, onChange }: SocialPanel) {
    const { socials }: { socials: Array<Social> } = useSelector((state: any) => state.socials);

    const handleCancel = () => {
        onCancel();
    };

    const fileList: Array<UploadFile> = social?.icon
        ? [
            typeof social.icon === 'object'
                ? social.icon
                : {
                    uid: '-1',
                    name: `${social.title}.png`,
                    status: 'done',
                    url: social.icon,
                } as UploadFile
        ]
        : []
    console.log({ fileList })
    const setFileList = (list: Array<UploadFile>) => {
        onChange({ ...social, icon: list[ 0 ] })
    }

    useEffect(() => {
        console.log({ socials })
    }, [ socials ])

    const handleChange = (socialUpdate: any) => onChange({ ...social, ...socialUpdate })
    console.log({ social })
    return (
        <Form
            onFinish={(e) => onSubmit(social.id)}
            initialValues={{ section: null }}
        >
            <Form.Item
                name="title"
                rules={[ { required: !social.title?.replace(/\s+/g, ''), message: 'El nombre de la red social es requerido' } ]}
            >
                <Input
                    onChange={(event) => handleChange({ title: event.target.value })}
                    value={social.title}
                    defaultValue={social.title}
                    placeholder="Nombre red social"
                />
            </Form.Item>
            <Form.Item
                name="url"
                rules={[ { required: !social.url?.replace(/\s+/g, ''), message: 'La url de la red social es requerida' } ]}
            >
                <Input
                    onChange={(event) => handleChange({ url: event.target.value })}
                    value={social.url} defaultValue={social.url}
                    placeholder="Link URL de perfil"
                />
            </Form.Item>
            <Form.Item
                name="icon"
                rules={[ { required: !fileList?.length, message: 'El ícono de la red social es requerido' } ]}
            >
                <Row justify="space-around">
                    <Col span={10}>
                        <UploadImage fileList={fileList} setFileList={setFileList} message='Cambiar ícono de red social' />
                    </Col>
                    <Col span={14}>
                        <Text type="secondary" style={{ textAlign: 'justify' }}>
                            La resolución recomendada es 640*640 con un tamaño de archivo inferior a 2 MB, mantenga los elementos visuales centrados.
                        </Text>
                    </Col>
                </Row>
            </Form.Item>
            <div className={styles.container_button_menu}>
                <Button
                    className={styles.save_button}
                    block
                    htmlType="submit"
                >
                    Guardar
                </Button>
                <Button
                    className={styles.cancel_button}
                    block
                    onClick={handleCancel}
                >
                    Cancelar
                </Button>
            </div>
        </Form>
    )
}