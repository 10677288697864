import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";
import pequeña from "../../../assets/img/img_pequeña.svg";
import avatar from "../../../assets/img/avatar.svg";
import styles from "./styles.module.scss";
import { Row, Col, Card } from "antd";
import moment from "moment";
import "moment/locale/es";
import { imgNoExist } from "../../../helpers/img";

const ModuleOne = ({ block }: any) => {
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1060}>
            {block?.title_active && (
              <>
                <span
                  className={styles.title_module}
                  style={{ color: block?.color || "#103783" }}
                >
                  {block?.title || "Modulo 1"}
                </span>
                <div className={styles.line_section}></div>
              </>
            )}

            <Row gutter={[22, 22]}>
              <Col span={18} className={styles.order_module}>
                <div
                  className={styles.container_block}
                  style={{
                    backgroundImage: `url(${
                      block?.new1?.imgBanner || imgNoExist
                    })`,
                  }}
                >
                  <div>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new1?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new1?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_main}>
                      {block?.new1?.title || `Titulo ejemplo`}
                    </p>
                  </div>
                </div>

                <Row gutter={[26, 0]}>
                  <Col span={10}>
                    <div
                      className={styles.container_block_two}
                      style={{
                        backgroundImage: `url(${
                          block?.new2?.imgBanner || imgNoExist
                        })`,
                      }}
                    ></div>
                    <div className={styles.container_span}>
                      <span className={styles.text_section}>
                        {block?.new2?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_section}>
                        {moment(block?.new2?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <div>
                      <p className={styles.title_block}>
                        {block?.new2?.title || `Titulo ejemplo`}
                      </p>
                      <p className={styles.subtitle_block}>
                        {block?.new2?.subTitle || `subTitulo ejemplo`}
                      </p>
                    </div>
                  </Col>

                  <Col span={14}>
                    <div
                      className={styles.container_block_three}
                      style={{
                        backgroundImage: `url(${
                          block?.new3?.imgBanner || imgNoExist
                        })`,
                      }}
                    ></div>
                    <div className={styles.container_span}>
                      <span className={styles.text_section}>
                        {block?.new3?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_section}>
                        {moment(block?.new3?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <div>
                      <p className={styles.title_block}>
                        {block?.new3?.title || `Titulo ejemplo`}
                      </p>
                      <p className={styles.subtitle_block}>
                        {block?.new3?.subTitle || `subTitulo ejemplo`}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row gutter={[0, 38]}>
                  <Col span={24}>
                    {block?.staticNews1?.section && (
                      <div className={styles.container_title_border}>
                        <div
                          style={{ background: "#5BBCF7" }}
                          className={styles.borde}
                        ></div>
                        <span
                          style={{ color: "#5BBCF7" }}
                          className={styles.title_section}
                        >
                          {block?.staticNews1?.section?.title || `section`}
                        </span>
                      </div>
                    )}
                    <Card
                      className={styles.card_module}
                      cover={
                        <img
                          src={
                            block?.staticNews1?.imgBanner ||
                            block?.staticNews1?.imgVideo ||
                            block?.staticNews1?.imgPodcast ||
                            imgNoExist
                          }
                          alt=""
                        />
                      }
                    >
                      <div className={styles.container_info}>
                        <p className={styles.description_section}>
                          {block?.staticNews1?.title ||
                            `Este texto es de prueba`}
                        </p>
                        <div className={styles.container_info_img}>
                          <div className={styles.container_avatar}>
                            <div
                              className={styles.avatar_module}
                              style={{ backgroundImage: `url(${avatar})` }}
                            ></div>
                          </div>
                          <div className={styles.container_tex_card}>
                            <p className={styles.text_signature}>Por Firma 1</p>
                            <p className={styles.date_card}>
                              {moment(block?.staticNews1?.createdAt).format(
                                "LL"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col span={24}>
                    {block?.staticNews2?.section && (
                      <div className={styles.container_title_border}>
                        <div
                          style={{ background: "#23E4B6" }}
                          className={styles.borde}
                        ></div>
                        <span
                          style={{ color: "#23E4B6" }}
                          className={styles.title_section}
                        >
                          {block?.staticNews2?.section?.title || `section`}
                        </span>
                      </div>
                    )}
                    <Card
                      className={styles.card_module}
                      cover={
                        <img
                          src={
                            block?.staticNews2?.imgBanner ||
                            block?.staticNews2?.imgVideo ||
                            block?.staticNews2?.imgPodcast ||
                            imgNoExist
                          }
                          alt=""
                        />
                      }
                    >
                      <div className={styles.container_info}>
                        <p className={styles.description_section}>
                          {block?.staticNews2?.title ||
                            `Este texto es de prueba`}
                        </p>
                        <div className={styles.container_info_img}>
                          <div className={styles.container_avatar}>
                            <div
                              className={styles.avatar_module}
                              style={{ backgroundImage: `url(${avatar})` }}
                            ></div>
                          </div>
                          <div className={styles.container_tex_card}>
                            <p className={styles.text_signature}>
                              {block?.staticNews2?.author || `Por Firma 1`}
                            </p>
                            <p className={styles.date_card}>
                              {moment(block?.staticNews2?.createdAt).format(
                                "LL"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleOne;
