import settingsConstants from "./constants";
const { SET_MENU, SET_SUB_MENU } = settingsConstants;

export const initialState = {
  menu: false,
  subMenu: {
    barBanner: false,
    slider: false,
    barMenu: false,
    moduls: true,
    thermometer: false,
    footer: false,
    menuBurger: true,
  },
};

const settingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MENU:
      return {
        ...state,
        menu: action.state,
      };
    case SET_SUB_MENU:
      return {
        ...state,
        subMenu: action.state,
      };
    default:
      return state;
  }
};

export default settingsReducer;
