import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";
import styles from "./styles.module.scss";
import { Row, Col, Card } from "antd";
import moment from "moment";
import "moment/locale/es";
import { imgNoExist } from "../../../helpers/img";

const ModuleFourteen = ({ block }: any) => {
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1440}>
            <div
              style={{
                backgroundImage: `url(${block?.new1?.imgBanner || imgNoExist})`,
              }}
              className={styles.container_video}
            >
              <div>
                <div className={styles.container_text}>
                  {block?.title_active && (
                    <>
                      <span
                        className={styles.title_module}
                        style={{ color: block?.color || "#FFFFFF" }}
                      >
                        {block?.title || "Modulo 14"}
                      </span>
                    </>
                  )}

                  <p className={styles.title_main}>
                    {block?.new1?.title || `Titulo ejemplo`}
                  </p>
                  <p className={styles.subtitle_main}>
                    {block?.new1?.subtitle || `Subtitulo ejemplo`}
                  </p>
                </div>
              </div>
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleFourteen;
