import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import Menu from "../../../components/Menu";
import { Collapse, Select, Form, Button, Switch, Row, Col, Typography, Modal } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
} from "../../../components/Alerts";
import {
  getPlaces,
} from "../../../redux/dashboard/menuBurger/action";
import { getSections } from "../../../redux/dashboard/section/action";
import { createSocials, deleteSocials, getSocials, setSocial, updateSocials } from "../../../redux/dashboard/socials/action";
import { useDispatch, useSelector } from "react-redux";
import ItemSocial from "./ItemSocial";
import HeaderSocial from "./HeaderSocial";
import { UploadFile } from "antd/lib/upload/interface";
import { Social } from "./models";
const { Panel } = Collapse;
const { Text } = Typography;
const MenuSocial = () => {

  const dispatch = useDispatch();
  const { socials }: { socials: Array<Social> } = useSelector((state: any) => state.socials);

  const [ collapse, setCollapse ] = useState(false);
  const [ newSocial, setNewSocial ] = useState({} as Social);

  const handleCancel = () => {
    showAlertSucces(
      "Menú Burger",
      "La edicion del menú burger ha sido cancelada"
    );
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    dispatch(getSocials());
  }, []);

  const onDelete = (id: string) => {
    showAlertQuestion(
      "¿Estás seguro que deseas eliminar la red social?",
      "Aceptar",
      () => {
        dispatch(deleteSocials(
          { id },
          () => {
            dispatch(setSocial([ ...socials.filter(social => social.id !== id) ]));
            showAlertSucces(
              "¡Red social eliminada!",
              "La red social actual ha sido eliminada correctamente."
            );
          },
          () => {
            showAlertError(
              "Ha ocurrido un error",
              "No se ha podido completar correctamente la acción, intentalo nuevamente."
            );
          }
        ))
      }
    );
  };

  const onEdit = (id?: string) => {
    showAlertQuestion(
      "¿Estas seguro que deseas guardar los cambios?",
      "Aceptar",
      () => {
        const social = socials.find(social => social.id === id)
        dispatch(updateSocials(
          social,
          () => {
            showAlertSucces(
              "¡Red social actualizada!",
              "La red social actual ha sido actualizada correctamente."
            );
          },
          () => {
            showAlertError(
              "Ha ocurrido un error",
              "No se ha podido completar correctamente la acción, intentalo nuevamente."
            );
          }
        ))
      }
    );
  };

  const onEnable = (id: string, checked: boolean) => {
    showAlertQuestion(
      "¿Estas seguro que deseas habilitar la red social?",
      "Aceptar",
      () => {
        const social = socials.find(social => social.id === id)
        dispatch(updateSocials(
          { id: social?.id, enabled: checked },
          () => {
            const listSocial = socials.map(social => {
              if (social.id === id) social.enabled = checked
              return social;
            })
            dispatch(setSocial(listSocial))
            showAlertSucces(
              "¡Red social habilitada!",
              "La red social actual ha sido habilitada correctamente."
            );
          },
          () => {
            showAlertError(
              "Ha ocurrido un error",
              "No se ha podido completar correctamente la acción, intentalo nuevamente."
            );
          }
        ))
      }
    );
  };

  const onDisable = (id: string, checked: boolean) => {
    showAlertQuestion(
      "¿Estas seguro que deseas deshabilitar la red social?",
      "Aceptar",
      () => {
        const social = socials.find(social => social.id === id)
        dispatch(updateSocials(
          { id: social?.id, enabled: checked },
          () => {
            const listSocial = socials.map(social => {
              if (social.id === id) social.enabled = checked
              return social;
            })
            dispatch(setSocial(listSocial))
            showAlertSucces(
              "¡Red social deshabilitada!",
              "La red social actual ha sido deshabilitada correctamente."
            );
          },
          () => {
            showAlertError(
              "Ha ocurrido un error",
              "No se ha podido completar correctamente la acción, intentalo nuevamente."
            );
          }
        ))
      }
    );
  };

  const onCancel = (value?: any, id?: string) => {
    showAlertQuestion(
      "¿Estás seguro que deseas cancelar la acción?",
      "Aceptar",
      () => {
        console.log('test')
      }
    );
  };

  const onCreate = () => {
    showAlertQuestion(
      "¿Estás seguro que deseas agregar una nueva red social?",
      "Aceptar",
      () => {
        dispatch(createSocials(
          newSocial,
          () => {
            dispatch(setSocial([ ...socials, { ...newSocial, enabled: true } ]));
            setNewSocial({} as Social);
            setCollapse(false)
            showAlertSucces(
              "¡Red social agregada!",
              "La red social actual ha sido agregada correctamente."
            );
          },
          () => {
            showAlertError(
              "Ha ocurrido un error",
              "No se ha podido completar correctamente la acción, intentalo nuevamente."
            );
          }
        ))
      }
    );
  };

  const onChange = (updateSocial: Social) => {
    console.log({ updateSocial })
    const newSocialData = socials.map(social => {
      if (updateSocial.id === social.id) social = { ...updateSocial }
      return social;
    })
    dispatch(setSocial(newSocialData))
  }

  return (
    <>
      <Menu>
        <div>
          <p className={styles.title} >
            Redes Sociales
          </p>
          <Collapse
            expandIconPosition="right"
            bordered={false}
            expandIcon={() => null}
            key={10}
            activeKey={collapse ? 1 : 0}
          >
            <Panel
              header={
                <Button
                  className={styles.add_button}
                  block
                  onClick={() => setCollapse(!collapse)}
                >
                  Agregar nueva red social
                </Button>
              }
              key={1}
            >
              <ItemSocial
                onCancel={onCancel}
                onSubmit={onCreate}
                onChange={(updateSocial) => setNewSocial(updateSocial)}
                social={newSocial}
              />
            </Panel>
          </Collapse>
          {
            socials?.map(social =>
              <Collapse
                expandIconPosition="right"
                bordered={false}
                expandIcon={() =>
                  <div
                    style={{ padding: 10, paddingRight: 0 }}
                    onClick={(e) => {
                      e.stopPropagation()
                      onDelete(social.id)
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                }
                key={social.id}
              >
                <Panel
                  header={<HeaderSocial social={social} onSwitch={social.enabled ? onDisable : onEnable} />}
                  key={social.id}
                >
                  <ItemSocial
                    onCancel={onCancel}
                    onSubmit={onEdit}
                    onChange={onChange}
                    social={social}
                  />
                </Panel>
              </Collapse>
            )
          }
        </div>
      </Menu>
    </>
  );
};

export default MenuSocial;