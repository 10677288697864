import socialConstans from "./constans";
import { Social } from "../../../pages/dashboard/socials/models";
const { SET_SOCIAL, LOADING, SET_ERROR } = socialConstans;
import socialServices from "./services";

export const setSocial = (socials: Array<Social>) => ({
    type: SET_SOCIAL,
    socials,
});

const setLoading = (loading: boolean) => ({
    type: LOADING,
    loading,
});

const setError = (error: any | null) => ({
    type: SET_ERROR,
    error,
});

export const getSocials = (callback?: any, err?: any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        try {
            const response = await socialServices.getAll();
            dispatch(setSocial(response.data.response));
            dispatch(setLoading(false));
            if (callback) callback();
        } catch (error) {
            dispatch(setError(error));
            if (err) err();
        }
    };
};

export const updateSocials = (data: any, callback?: any, err?: any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        dispatch(setError(false));
        try {
            const formData = new FormData();
            data = { ...data }
            if (data?.icon?.status === 'success') {
                data.icon = data.icon.originFileObj;
            } else {
                delete data.icon;
            }
            Object.keys(data).forEach((key) => {
                formData.append(key, data[ key ]);
            });
            const response = await socialServices.update(formData);
            dispatch(setLoading(false));
            if (callback) callback();
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setError(error));
            if (err) err();
        }
    };
};

export const createSocials = (data: any, callback?: any, err?: any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        dispatch(setError(false));
        try {
            const formData = new FormData();
            data = { ...data, icon: data.icon.originFileObj }
            Object.keys(data).forEach((key) => {
                formData.append(key, data[ key ]);
            });
            const response = await socialServices.save(formData);
            dispatch(setLoading(false));
            if (callback) callback();
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setError(error));
            if (err) err();
        }
    };
};

export const deleteSocials = (data: any, callback?: any, err?: any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        dispatch(setError(true));
        try {
            const response = await socialServices.delete(data);
            dispatch(setLoading(false));
            if (callback) callback();
        } catch (error) {
            dispatch(setError(error));
            dispatch(setLoading(false));
            if (err) err();
        }
    };
};