import React from "react";
import Swal from "sweetalert2";
import styles from "./styles.module.scss";
import { Modal } from "antd";

export const showAlertSuccesQuestion = (
  titleQuestion: string,
  textButtonQuestion: string,
  titleSucces: string,
  textSucces: string,
  callback?: any
) => {
  Swal.fire({
    title: `${titleQuestion}`,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: `${textButtonQuestion}`,
    cancelButtonText: `Cancelar`,
    customClass: {
      popup: styles.container_alert_question,
      confirmButton: styles.confirmButtonQuestion,
      cancelButton: styles.cancelButtonQuestion,
      actions: styles.actionsQuestion,
      title: styles.title_alert_question,
    },
  }).then((result) => {
    if (result.isConfirmed) {
      if (callback) callback();
      Swal.fire({
        title: `${titleSucces}`,
        text: `${textSucces}`,
        showCloseButton: true,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: styles.title_alert_success,
          popup: styles.container_alert_success,
          htmlContainer: styles.html_container_success,
          closeButton: styles.closeButton_success,
        },
      });
    }
  });
};

export const showAlertQuestion = (
  titleQuestion: string,
  textButtonQuestion: string,
  callback?: any
) => {
  Swal.fire({
    title: `${titleQuestion}`,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: `${textButtonQuestion}`,
    cancelButtonText: `Cancelar`,
    customClass: {
      popup: styles.container_alert_question,
      confirmButton: styles.confirmButtonQuestion,
      cancelButton: styles.cancelButtonQuestion,
      actions: styles.actionsQuestion,
      title: styles.title_alert_question,
    },
  }).then((result) => {
    if (result.isConfirmed) {
      if (callback) callback();
    }
  });
};

export const showAlertSucces = (titleSucces: string, textSucces: string) => {
  Swal.fire({
    title: `${titleSucces}`,
    text: `${textSucces}`,
    showCloseButton: true,
    showConfirmButton: false,
    timer: 1500,
    customClass: {
      title: styles.title_alert_success,
      popup: styles.container_alert_success,
      htmlContainer: styles.html_container_success,
      closeButton: styles.closeButton_success,
    },
  });
};

export const showAlertError = (titleSucces: string, textSucces: string) => {
  Swal.fire({
    icon: "error",
    title: `${titleSucces}`,
    text: `${textSucces}`,
    showCloseButton: true,
    showConfirmButton: false,
    timer: 1500,
    customClass: {
      title: styles.title_alert_error,
      popup: styles.container_alert_error,
      htmlContainer: styles.html_container_error,
      closeButton: styles.closeButton_success,
    },
  });
};
