import api from "../../../helpers/api";

const sectionServices = {
  getAll: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 10;
    return api.get("/section/getAll", {
      params: {
        page,
        per_page,
      },
    });
  },

  getAllWithOutType: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 10;
    return api.get("/section/getAllWithOutType", {
      params: {
        page,
        per_page,
      },
    });
  },

  save: (data: any) => {
    return api.post("/section/save", {
      data,
    });
  },

  delete: (data: any) => {
    return api.delete("/section/delete", {
      data,
    });
  },
  
  update: (data: any) => {
    return api.put("/section/update", {
      data,
    });
  },
};

export default sectionServices;
