import bannerServices from "./services";
import bannerConstants from "./constants";

const { SET_BANNER, LOADING, SET_ERROR } = bannerConstants;

export const setBanner = (banner: any) => ({
  type: SET_BANNER,
  banner,
});

export const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

export const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

export const getBanner = (callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await bannerServices.getAll();
      const { response: res } = response.data;
      dispatch(setBanner(res));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const updateBanner = (data?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(null));
    try {
      const response = await bannerServices.update(data);
      if (response) dispatch(getBanner());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};
