import React, { useState } from "react";

import { Collapse, Select, Button, Input, Upload, Form } from "antd";
import styles from "../../pages/dashboard/notices/styles.module.scss";

const { TextArea, Search } = Input;

const UploadFile = (props: any) => {
  const [fileList, setFileList] = useState<any>([]);
  const { dataForm, setDataForm, nameFile } = props;

  const onChange = ({ fileList: newFileList, file }: any) => {
    setDataForm({ ...dataForm, [nameFile]: file });
    setFileList(newFileList);
    return false;
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow: any = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <Form form={props?.form}>
      <Form.Item>
        <div className={styles.container_upload}>
          <Form.Item name={nameFile}>
            <Upload
              accept={!props?.type ? ".png, .jpg, .jpeg" : ".pdf"}
              listType={!props?.type ? "picture-card" : "picture"}
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={() => false}
            >
              {fileList.length === 1 ? null : "+ Upload"}
            </Upload>
          </Form.Item>
          {!props?.type && (
            <span className={styles.span_text}>
              Recommanded resolution is 1280*720 with file size less than 2MB,
              keep visual elements centered
            </span>
          )}
        </div>
      </Form.Item>

      {props?.nameTextImg && (
        <Form.Item name="textImgBanner">
          <TextArea
            placeholder="Escribe aquí"
            rows={2}
            onChange={(e) =>
              setDataForm({
                ...dataForm,
                [props?.nameTextImg]: e.target.value,
              })
            }
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default UploadFile;
