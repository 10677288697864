import announcerServices from "./services";
import announcerConstants from "./constants";

const { LOADING, SET_ERROR, SET_ANNOUNCERS } = announcerConstants;

export const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

export const setError = (error: any) => ({
  type: SET_ERROR,
  error,
});

export const setAnnouncers = (announcers: any) => ({
  type: SET_ANNOUNCERS,
  announcers,
});

export const getAnnouncers = (callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    try {
      const response = await announcerServices.getAll();

      dispatch(setAnnouncers(response.data));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const newAnnouncer = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    try {
      const formData = new FormData();

      Object.keys(data).forEach((d) => {
        formData.append(d, data[d]);
      });
      console.log(data);
      const response = await announcerServices.createAnnouncer(formData);
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const removeAnnouncer = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    try {
      const response = await announcerServices.deleteAnnouncer(data);
      //console.log(data);
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const editAnnouncer = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    try {
      const formData = new FormData();
      console.log(data);
      Object.keys(data).forEach((d) => {
        formData.append(d, data[d]);
      });
      const response = await announcerServices.updateAnnouncer(formData);
      dispatch(setLoading(false));
      console.log(response);
      if (callback) callback();
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};


