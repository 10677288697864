import { Col, Row } from "antd";
import React from "react";
import styles from "./styles.module.scss";
import Layout, { Content } from "antd/lib/layout/layout";
import { imgNoExist } from "../../../helpers/img";
import moment from "moment";
import "moment/locale/es";

const ModuleFive = ({ block }: any) => {
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_bg_grey}>
            <div className={styles.module_five}>
              {block?.title_active && (
                <div className={styles.container_title_border}>
                  <div
                    style={{ background: "#7D7D7D" }}
                    className={styles.borde}
                  ></div>
                  <span className={styles.title_module}>
                    {block?.title || "Modulo 5"}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.container_spacing}>
              <Row>
                <Col span={24}>
                  <div
                    style={{
                      backgroundImage: `url(${
                        block?.new1?.imgBanner || imgNoExist
                      })`,
                    }}
                    className={styles.container_module}
                  >
                    <div>
                      <div className={styles.container_span}>
                        <span className={styles.text_main}>
                          {block?.new1?.section?.title || `section`}
                        </span>
                        <span className={styles.reading_main}>
                          {moment(block?.new1?.createdAt)
                            .locale("es")
                            .startOf("day")
                            .fromNow() || `Tiempo prueba`}
                        </span>
                      </div>
                      <div className={styles.container_new}>
                        <p className={styles.title_main}>
                          {block?.new1?.title || `Titulo ejemplo`}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className={styles.container_spacing_dos}>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <div
                    style={{
                      backgroundImage: `url(${
                        block?.new2?.imgBanner || imgNoExist
                      })`,
                    }}
                    className={styles.container_module_block}
                  ></div>
                </Col>
                <Col span={12}>
                  <div className={styles.container_new_block_two}>
                    <p className={styles.title_section}>
                      {block?.new2?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.text_section}>
                      {block?.new2?.subTitle || `subTitulo ejemplo`}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleFive;
