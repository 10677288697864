import legalWarningConstants from "./constants";
import legalWarningServices from "./services";

const { SET_LEGAL_WARNING, UPDATE_LEGAL_WARNING, LOADING, SET_ERROR } =
  legalWarningConstants;

const setLegalWarning = (legalWarning: any) => ({
  type: SET_LEGAL_WARNING,
  legalWarning,
});

const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

export const saveLegalWarning = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    try {
      const response = await legalWarningServices.save(data);
      if (response) dispatch(getLegalWarning());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const getLegalWarning = (dataPage?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await legalWarningServices.getAll(
        dataPage?.page,
        dataPage?.per_page
      );
      dispatch(setLegalWarning(response.data.response));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const updateLegalWarning = (data?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
      const response = await legalWarningServices.update(data);
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};
