import menuBurgerConstants from "./constants";

const { SET_PLACE, SET_PLACES, LOADING, SET_ERROR } = menuBurgerConstants;

export const inicialState = {
    places: null,
}


const menuBurger = (state = inicialState, action: any) => {
    switch (action.type) {
        case SET_PLACE:
            return {
                ...state,
                place: action.place,
            };
        case SET_PLACES:
            return {
                ...state,
                places: action.places,
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        default:
            return state;
    }
}

export default menuBurger;