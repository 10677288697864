const subSectionConstants = {
  SET_SUB_SECTION: "SET_SUB_SECTION",
  SET_SUB_SECTIONS: "SET_SUB_SECTIONS",
  UPDATE_SUB_SECTION: "UPDATE_SUB_SECTION",
  DELETE_SUB_SECTION: "DELETE_SUB_SECTION",
  LOADING: "LOADING",
  SET_ERROR: "SET_ERROR",
};

export default subSectionConstants;
