import api from "../../../helpers/api";

const subSectionServices = {
  getAll: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 10;
    return api.get("/subsection/getAll", {
      params: {
        page,
        per_page,
      },
    });
  },

  getSpecific: (id: string) => {
    return api.get("/subsection/getSpecific", {
      params: {
        id,
      },
    });
  },

  getSpecificWithSection: (id: string) => {
    return api.get("/subsection/getSpecificWithSection", {
      params: {
        id,
      },
    });
  },

  save: (data: any) => {
    return api.post("/subsection/save", {
      data,
    });
  },

  delete: (data: any) => {
    return api.delete("/subsection/delete", {
      data,
    });
  },

  update: (data: any) => {
    return api.put("/subsection/update", {
      data,
    });
  },
};

export default subSectionServices;
