import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { Button, Collapse, Form, Select } from "antd";
import { showAlertError, showAlertQuestion, showAlertSucces } from "../Alerts";
import { savePlace } from "../../redux/dashboard/menuBurger/action";

const FormCreateMenuBurger = (props: any) => {
  const { Option } = Select;
  const { sections, handleCancel, setVisibleMenuCreate } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (value: any) => {
    showAlertQuestion(
      "¿Estas seguro que desea guardar los cambios?",
      "Guardar",
      () => {
        dispatch(
          savePlace(
            { ...value },
            () => {
              setVisibleMenuCreate(false);
              showAlertSucces(
                "¡Espacio editado!",
                "El espacio ha sido editado correctamente."
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentelo nuevamente"
              );
            }
          )
        );
      }
    );
  };

  return (
    <>
      <Form onFinish={(e) => onFinish(e)} form={form}>
        <Form.Item name="section">
          <Select
            placeholder="Elegir sección"
            className={styles.select_seccion}
          >
            <Option default className={styles.option_select}>
              Elegir sección
            </Option>
            {sections?.data &&
              sections.data.map((section: any) => (
                <Option
                  key={section.id}
                  value={section.id}
                  className={styles.option_select}
                >
                  {section.title || `section`}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <div className={styles.container_button_menu}>
          <Button className={styles.save_button} block htmlType="submit">
            Guardar
          </Button>
          <Button
            className={styles.cancel_button}
            block
            onClick={() => setVisibleMenuCreate(false)}
          >
            Cancelar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default FormCreateMenuBurger;
