import React from "react";
import ReactDOM from "react-dom";
import "./theme/index.scss";
import App from "./pages";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

/* redux */
import { Provider } from "react-redux";
import { store, persistor } from "./redux/rootStore";
import { PersistGate } from "redux-persist/integration/react";
import Helmet from "react-helmet";
import moment from "moment-timezone";

moment.tz.setDefault("Europe/Madrid");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Helmet>
            <script async src="https://platform.twitter.com/widgets.js" />
            <script
              async
              defer
              crossOrigin="anonymous"
              src="https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v12.0"
              nonce="zqHEaGDd"
            ></script>
          </Helmet>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
