import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { setMenu } from "../../../redux/settings/action";
import { Modal, Form, Radio, Image, message, Tooltip } from "antd";
import Menu from "../../../components/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "antd/lib/mentions";
import { CaretRightOutlined } from "@ant-design/icons";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
} from "../../../components/Alerts";
import {
  Input,
  Button,
  Pagination,
  Table,
  Switch,
  Collapse,
  DatePicker,
  ConfigProvider,
  Select,
  Row,
  Col,
} from "antd";
import {
  deleteNews,
  getNewsWithoutPublicate,
  publicateNews,
  setPostNews,
} from "../../../redux/dashboard/news/action";
import { getBlocks } from "../../../redux/dashboard/block/action";
import ModuleOne from "../../../components/Moduls/Module-one/module_one";
import ModuleTwo from "../../../components/Moduls/Module-two/module_two";
import ModuleThree from "../../../components/Moduls/Module-three/module_three";
import ModuleFour from "../../../components/Moduls/Module-four/module_four";
import ModuleFive from "../../../components/Moduls/Module-five/module_five";
import ModuleSix from "../../../components/Moduls/Module-six/module_six";
import ModuleSeven from "../../../components/Moduls/Module-seven/module_seven";
import ModuleEight from "../../../components/Moduls/Module-eight/module_eight";
import ModuleNine from "../../../components/Moduls/Module-nine/module_nine";
import ModuleTen from "../../../components/Moduls/Module-ten/module_ten";
import ModuleEleven from "../../../components/Moduls/Module-eleven/module_eleven";
import ModuleTwelve from "../../../components/Moduls/Module-twelve/module_twelve";
import ModuleThirteen from "../../../components/Moduls/Module-thirteen/module_thirteen";
import ModuleFourteen from "../../../components/Moduls/Module-fourteen/module_fourteen";
import ModuleEighteen from "../../../components/Moduls/Module-eighteen/module_eighteen";
import ModuleFifteen from "../../../components/Moduls/Module-fifteen/module_fifteen";
import { OptionsPositionModules } from "../../../components/Moduls/Option-position-modules/OptionsPositionModules";
import moment from "moment";
import "moment/locale/es";
import locale from "antd/lib/locale/es_ES";

import Preview from "../../../components/Preview/preview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faClock,
  faEye,
  faLink,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ModuleNineteen from "../../../components/Moduls/Molune-nineteen/module_nineteen";
import ModuleSixteen from "../../../components/Moduls/Module-sixteen";
import ModuleSeventeen from "../../../components/Moduls/Module-seventeen";
import { OptionsPositionStaticNews } from "../../../components/Moduls/Option-position-modules/OptionsPositionStaticNews";

const FORMAT_SCHEDULE = "D [de] MMMM [de] YYYY [a las] HH:mm";
moment.locale("es");

const PostNotices = () => {
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { menu } = useSelector((state: any) => state.settings);
  const { newss, newsWithOutPublicate, postNews } = useSelector(
    (state: any) => state.news
  );
  const { blocks } = useSelector((state: any) => state.blocks);

  const [switchValue, setSwitchValue] = useState(true);
  const [showModule, setShowModule] = useState(false);
  const [dataSelect, setDataSelect] = useState<any>({});

  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [isModalPreview, setIsModalPreviw] = useState(false);
  const [isModalVisibleSchedule, setIsModalVisibleSchedule] = useState(false);
  const [isSchedule, setSchedule] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(0);
  const [newsPreview, setNewsPreview] = useState<any>({});
  const [newsPublic, setNewsPublic] = useState<any>({
    visibleInHome: true,
    isAuto: true,
  });

  const [formAuto] = Form.useForm();
  const [formManual] = Form.useForm();

  useEffect(() => {
    dispatch(getNewsWithoutPublicate({ page: 1, per_page: 999 }));
    dispatch(getBlocks({ page: 1, per_page: 999 }));
  }, []);

  useEffect(() => {
    if (postNews) {
      showModalEdit();
      setNewsPublic({ ...newsPublic, ...postNews });
      setSchedule(false);
    }
  }, []);

  const handleClose = () => {
    dispatch(setPostNews(null));
    setIsModalVisibleEdit(false);
  };

  const showModalEdit = () => {
    setIsModalVisibleEdit(true);
  };

  const handleCancelEdit = () => {
    setIsModalVisibleEdit(false);
  };

  const showModalPreview = () => {
    setIsModalPreviw(true);
  };

  const hideModalPreview = () => {
    setIsModalPreviw(false);
  };

  const showTotal = (total: any) => {
    return `${total} - ${total} de ${total}`;
  };

  const deleteNs = (row: any) => {
    showAlertQuestion(
      "¿Estás seguro que desea eliminar la noticia actual?",
      "Eliminar",
      () => {
        dispatch(
          deleteNews(
            row,
            () => {
              dispatch(getNewsWithoutPublicate({ page: 1, per_page: 999 }));
              showAlertSucces(
                "Noticia eliminada",
                "La noticia actual ha sido eliminada correctamente."
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const columns = [
    {
      title: "Noticia",
      render: (row: any) => (
        <>
          <p>
            {row?.title}{" "}
            {row?.template === "podcast" && (
              <Tooltip title="Podcast">
                <FontAwesomeIcon
                  icon={faBullhorn}
                  className={styles.icon_bars}
                />
              </Tooltip>
            )}
          </p>
        </>
      ),
    },
    {
      title: "Sección",
      render: (d: any) => d?.section?.title,
    },
    {
      title: "Subsección",
      render: (d: any) =>
        d?.subSection?.title ? d?.subSection?.title : "Sin subsección",
    },
    {
      title: "Fecha de creación",
      render: (d: any) => moment(d.createdAt).format("L"),
    },
    {
      title: "Redactor",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      key: "action",
      title: "Opciones",
      render: (d: any) => (
        <>
          <div className={styles.container_button_table_notices}>
            <Tooltip title="Obtener url">
              <FontAwesomeIcon
                icon={faLink}
                className={styles.icon_bars}
                onClick={(e) => {
                  e.stopPropagation();
                  navigator?.clipboard?.writeText(
                    `https://www.medicinaresponsable.com/new/${d.slug}`
                  );
                  message.info("Link copiado!");
                }}
              />
            </Tooltip>
            <Tooltip title="Previsualizar">
              <FontAwesomeIcon
                icon={faEye}
                className={styles.icon_bars}
                onClick={() => {
                  setNewsPreview(d);
                  showModalPreview();
                }}
              />
            </Tooltip>
            <Tooltip title="Programar">
              <FontAwesomeIcon
                icon={faClock}
                className={styles.icon_bars}
                onClick={() => {
                  showModalEdit();
                  setNewsPublic({ ...newsPublic, idNews: d.id, news: d });
                  setSchedule(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Publicar">
              <FontAwesomeIcon
                icon={faBullhorn}
                className={styles.icon_bars}
                onClick={() => {
                  showModalEdit();
                  setNewsPublic({ ...newsPublic, idNews: d.id, news: d });
                  setSchedule(false);
                }}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <FontAwesomeIcon
                icon={faTrashAlt}
                className={styles.icon_bars}
                onClick={() => {
                  deleteNs(d);
                }}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const onFinishAuto = (value: any) => {
    setNewsPublic({ ...newsPublic, isAuto: true });
    showAlertQuestion(
      "¿Estás seguro que desea publicar la noticia?",
      "Publicar",
      () => {
        dataSelect.idNews = newsPublic.idNews;
        dataSelect.visibleInHome = true;

        dispatch(
          publicateNews(
            dataSelect,
            () => {
              dispatch(setMenu(false));
              dispatch(setPostNews(null));
              setIsModalVisibleEdit(false);
              formAuto.resetFields();
              showAlertSucces(
                "Noticia Publicada",
                "La noticia actual ha sido publicada correctamente."
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const onFinishAutoSchedule = (value: any) => {
    setNewsPublic({ ...newsPublic, isAuto: true });
    showAlertQuestion(
      "¿Estás seguro que desea programar la noticia?",
      "Programar",
      () => {
        dataSelect.idNews = newsPublic.idNews;
        dataSelect.visibleInHome = true;

        dispatch(
          publicateNews(
            { ...dataSelect, schedule: scheduleDate, autoPosition: true },
            () => {
              dispatch(setMenu(false));
              dispatch(setPostNews(null));
              setIsModalVisibleEdit(false);
              formAuto.resetFields();
              showAlertSucces(
                "Noticia Programada",
                "La noticia actual ha sido programada correctamente."
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const onFinishManual = (value: any) => {
    setNewsPublic({ ...newsPublic, isAuto: true });
    showAlertQuestion(
      "¿Estás seguro que desea publicar la noticia?",
      "Publicar",
      () => {
        dataSelect.idNews = newsPublic.idNews;
        dataSelect.visibleInHome = true;

        dispatch(
          publicateNews(
            dataSelect,
            () => {
              dispatch(setMenu(false));
              dispatch(setPostNews(null));
              setIsModalVisibleEdit(false);
              formAuto.resetFields();
              showAlertSucces(
                "Noticia Publicada",
                "La noticia actual ha sido publicada correctamente."
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const onFinishManualSchedule = (value: any) => {
    setNewsPublic({ ...newsPublic, isAuto: true });
    showAlertQuestion(
      "¿Estás seguro que desea programar la noticia?",
      "Programar",
      () => {
        dataSelect.idNews = newsPublic.idNews;
        dataSelect.visibleInHome = true;

        dispatch(
          publicateNews(
            { ...dataSelect, schedule: scheduleDate, autoPosition: false },
            () => {
              dispatch(setMenu(false));
              dispatch(setPostNews(null));
              setIsModalVisibleEdit(false);
              formAuto.resetFields();
              showAlertSucces(
                "Noticia Programada",
                "La noticia actual ha sido programada correctamente."
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const onHandleChangeModuleAutomatic = (value: any) => {
    formManual.resetFields();
    setNewsPublic({ ...newsPublic, idBlock: value });

    let blockTemp = blocks?.data.filter((d: any) => d.id === value)[0];

    blockTemp = {
      ...blockTemp,
      new7: blockTemp?.new6,
      new6: blockTemp?.new5,
      new5: blockTemp?.new4,
      new4: blockTemp?.new3,
      new3: blockTemp?.new2,
      new2: blockTemp?.new1,
      new1: { ...newsPublic?.news },
    };
    setDataSelect(blockTemp);
  };

  const onHandleChangeModuleManual = (value: any) => {
    formAuto.resetFields();
    setNewsPublic({ ...newsPublic, idBlock: value });
    const blockTemp = blocks?.data.filter((d: any) => d.id === value)[0];

    setDataSelect(blockTemp);
  };

  const onHandleChangePositionManual = (value: any) => {
    formAuto.resetFields();
    formManual.setFieldsValue({ positionStatic: null });
    let blockTemp = blocks?.data.filter(
      (d: any) => d.id === newsPublic.idBlock
    )[0];

    if (value) {
      switch (value) {
        case 1:
          blockTemp = {
            ...blockTemp,
            new1: { ...newsPublic?.news },
          };
          break;
        case 2:
          blockTemp = {
            ...blockTemp,
            new2: { ...newsPublic?.news },
          };
          break;
        case 3:
          blockTemp = {
            ...blockTemp,
            new3: { ...newsPublic?.news },
          };
          break;
        case 4:
          blockTemp = {
            ...blockTemp,
            new4: { ...newsPublic?.news },
          };
          break;
        case 5:
          blockTemp = {
            ...blockTemp,
            new5: { ...newsPublic?.news },
          };
          break;
        case 6:
          blockTemp = {
            ...blockTemp,
            new6: { ...newsPublic?.news },
          };
          break;
        case 7:
          blockTemp = {
            ...blockTemp,
            new7: { ...newsPublic?.news },
          };
          break;
        default:
          break;
      }
      setDataSelect(blockTemp);
    }
  };

  const onHandleChangePositionManualStatic = (value: any) => {
    formAuto.resetFields();
    formManual.setFieldsValue({ position: null });
    let blockTemp = blocks?.data.filter(
      (d: any) => d.id === newsPublic.idBlock
    )[0];
    if (value) {
      switch (value) {
        case 1:
          blockTemp = {
            ...blockTemp,
            staticNews1: { ...newsPublic?.news },
          };
          break;
        case 2:
          blockTemp = {
            ...blockTemp,
            staticNews2: { ...newsPublic?.news },
          };
          break;

        default:
          break;
      }
      setDataSelect(blockTemp);
    }
  };

  const handleConfirmPublish: () => void = () => {
    showAlertQuestion(
      "¿Estás seguro que desea publicar la noticia?",
      "Publicar",
      () => {
        dispatch(
          publicateNews(
            newsPublic,
            () => {
              setIsModalVisibleEdit(false);
              dispatch(setPostNews(null));
              showAlertSucces(
                "Noticia Publicada",
                "La noticia actual ha sido publicada correctamente."
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const handleConfirmSchedule = (date: number) => {
    showAlertQuestion(
      `¿Estás seguro que deseas programar la publicación de la noticia para el ${moment(
        date
      ).format(FORMAT_SCHEDULE)}?`,
      "Programar",
      () => {
        dispatch(
          publicateNews(
            { ...newsPublic, schedule: date },
            () => {
              dispatch(setPostNews(null));
              setIsModalVisibleEdit(false);
              showAlertSucces(
                "Noticia Programada",
                "La noticia actual ha sido programada correctamente."
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const handleSelectSchedule = () => {
    setIsModalVisibleEdit(false);
    setIsModalVisibleSchedule(true);
  };

  interface ModalSchedule {
    callback: (date: number) => void;
    visible: boolean;
    onCancel: () => void;
  }

  function ModalSelectScheduleDate(props: ModalSchedule) {
    const resetZero = { hour: 0, minute: 0, second: 0, millisecond: 0 };
    const resetMinuteZero = { minute: 0, second: 0, millisecond: 0 };
    const resetSecondZero = { second: 0, millisecond: 0 };
    const available = initTimeAvailable(moment());
    const [date, setDate] = useState(moment().set(available).valueOf());

    useEffect(() => {
      // checkTime()
    }, []);

    const onChangeDate: (value: any, dateString: string) => void = (value) => {
      const available = initTimeAvailable(value);
      const newDate = value.set(available);
      setDate(newDate.valueOf());
    };

    const onselectDate = (value: moment.Moment) => {
      console.log(value.format(FORMAT_SCHEDULE));
    };

    const parseTime = (hour: number) => `${hour < 10 ? "0" : ""}${hour}`;

    function hourAvailable(date: moment.Moment) {
      const maxMinute = date.set({ minute: 30 });
      return (
        maxMinute.set(resetSecondZero).valueOf() >=
        moment().set(resetSecondZero).valueOf()
      );
    }

    function minuteAvailable(date: moment.Moment) {
      const minute = parseInt(date.format("m"));
      return (
        date.set(resetSecondZero).valueOf() >
          moment().set(resetSecondZero).valueOf() &&
        (minute == 30 || minute == 0)
      );
    }

    function initTimeAvailable(date: moment.Moment) {
      const availableHour = validateHours(date)[0];
      const availableMinute = validateMinutes(
        date.set({ hour: availableHour })
      )[0];
      const timeAvailable = {
        hour: availableHour,
        minute: availableMinute,
        second: 0,
        millisecond: 0,
      };
      return timeAvailable;
    }

    function validateMinutes(date: moment.Moment) {
      const minutes = [];
      for (let minute = 0; minute < 60; minute++) {
        if (minuteAvailable(date.set({ minute }))) minutes.push(minute);
      }
      return minutes;
    }

    function disabledDate(current: moment.Moment) {
      return current && current.valueOf() < moment().set(resetZero).valueOf();
    }

    function validateHours(date: moment.Moment) {
      const hours: Array<number> = [];
      for (let hour = 0; hour < 24; hour++) {
        if (hourAvailable(date.set({ hour }))) hours.push(hour);
      }
      return hours;
    }

    function dateRender(currentDate: moment.Moment, today: moment.Moment) {
      return (
        <div className={`${styles.container_item_calendar}`}>
          <p>{currentDate.format("D")}</p>
        </div>
      );
    }

    function RenderSelectHour({
      dateCurrent,
      onChange,
    }: {
      dateCurrent: number;
      onChange: (newDate: number) => void;
    }) {
      return (
        <Select
          style={{ width: "100%" }}
          defaultValue={moment(dateCurrent).format("H")}
          value={moment(dateCurrent).format("H")}
          onChange={(value) => {
            const newValue = parseInt(value);
            let newDate = moment(dateCurrent).set({ hour: newValue });
            newDate = newDate.set({ minute: validateMinutes(newDate)[0] });
            onChange(newDate.valueOf());
          }}
        >
          {validateHours(moment(dateCurrent)).map((hour) => (
            <Option key={`${hour}`} value={`${hour}`}>
              {parseTime(hour)} h
            </Option>
          ))}
        </Select>
      );
    }

    function RenderSelectMinute({
      dateCurrent,
      onChange,
    }: {
      dateCurrent: number;
      onChange: (newDate: number) => void;
    }) {
      return (
        <Select
          style={{ width: "100%" }}
          defaultValue={moment(dateCurrent).format("m")}
          value={moment(dateCurrent).format("m")}
          onChange={(value) => {
            const newValue = parseInt(value);
            const newDate = moment(dateCurrent).set({ minute: newValue });
            onChange(newDate.valueOf());
          }}
        >
          {validateMinutes(moment(dateCurrent)).map((minute) => (
            <Option key={`${minute}`} value={`${minute}`}>
              {parseTime(minute)} m
            </Option>
          ))}
        </Select>
      );
    }

    function onChangeSelect(newDate: number) {
      setDate(newDate);
    }

    return (
      <Modal footer={null} visible={props.visible} onCancel={props.onCancel}>
        <div className={styles.container_content_modal}>
          <span className={styles.title_table}>Programar publicación</span>
          <span className={styles.text_table}>
            Selecciona la fecha y hora en la que se publicará la noticia
          </span>
          <div className={styles.line_table_modal}></div>
          <Form layout="vertical">
            <Row justify="space-around">
              <Col span={20}>
                <Form.Item
                  name="date"
                  label="Fecha:"
                  rules={[{ required: true }]}
                >
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      format={FORMAT_SCHEDULE}
                      disabledDate={disabledDate}
                      placeholder="Seleccionar fecha y hora"
                      defaultValue={moment(date)}
                      value={moment(date)}
                      onChange={onChangeDate}
                      dateRender={dateRender}
                      onSelect={onselectDate}
                      autoFocus={true}
                      size="large"
                    />
                  </ConfigProvider>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-around">
              <Col span={8}>
                <Form.Item
                  name="hour"
                  label="Hora"
                  rules={[{ required: true }]}
                >
                  <RenderSelectHour
                    dateCurrent={date}
                    onChange={onChangeSelect}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="minute"
                  label="Minuto"
                  rules={[{ required: true }]}
                >
                  <RenderSelectMinute
                    dateCurrent={date}
                    onChange={onChangeSelect}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div style={{ height: 20 }} />
          <div className={styles.container_buttons}>
            <Button
              htmlType="submit"
              className={styles.save_button_notices}
              block
              onClick={() => {
                props.callback(date);
              }}
            >
              Programar
            </Button>
            <Button
              className={styles.button_notices_table}
              block
              onClick={props.onCancel}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  const handleScheduleHome = (date: number) => {
    setScheduleDate(date);
    setShowModule(true);
    dispatch(setMenu(true));
    setIsModalVisibleSchedule(false);
  };

  const previewModal = () => {
    return (
      <Modal
        footer={null}
        visible={isModalPreview}
        onCancel={hideModalPreview}
        width={1440}
      >
        <Preview news={newsPreview} />
      </Modal>
    );
  };

  return (
    <>
      <Menu>
        <span className={styles.text_title_home}>
          Ubicación de la noticia en home
        </span>
        <div className={styles.container_collapse}>
          <Collapse
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            expandIconPosition="right"
            bordered={true}
          >
            <Panel header="Automática" className={styles.header_panel} key="1">
              <Form form={formAuto}>
                <Form.Item
                  name="block"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona un bloque",
                    },
                  ]}
                >
                  <Select
                    placeholder="Seleccione el bloque"
                    className={styles.select_seccion}
                    onChange={onHandleChangeModuleAutomatic}
                  >
                    {blocks?.data?.map(
                      (d: any) =>
                        d.module !== 13 && (
                          <Option
                            key={d.id}
                            className={styles.option_select}
                            value={d.id}
                          >
                            {d.title}
                          </Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              </Form>
              <div className={styles.container_button_collapse}>
                <Form
                  form={formAuto}
                  onFinish={isSchedule ? onFinishAutoSchedule : onFinishAuto}
                  layout="inline"
                >
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className={styles.save_button_notices}
                      block
                    >
                      Guardar
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className={styles.cancel_button_notices}
                      onClick={() => {
                        dispatch(setMenu(false));
                        setShowModule(false);
                        setDataSelect({ ...dataSelect, module: null });
                        formAuto.resetFields();
                      }}
                      block
                    >
                      Cancelar
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Panel>

            <Panel header="Manual" className={styles.header_panel} key="2">
              <Form form={formManual}>
                <Form.Item
                  name="block"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona un bloque",
                    },
                  ]}
                >
                  <Select
                    placeholder="Seleccione el bloque"
                    className={styles.select_seccion}
                    onChange={onHandleChangeModuleManual}
                  >
                    {blocks?.data?.map(
                      (d: any) =>
                        d.module !== 3 &&
                        d.module !== 7 &&
                        d.module !== 14 && (
                          <Option
                            key={d.id}
                            className={styles.option_select}
                            value={d.id}
                          >
                            {d.title}
                          </Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              </Form>

              <Collapse
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition="right"
                bordered={false}
              >
                {dataSelect?.module && (
                  <Panel
                    header="Espacios"
                    key="1"
                    className={`${styles.header_espacio} ${styles.padding_subcollapse}`}
                  >
                    <Form form={formManual}>
                      <Form.Item
                        name="position"
                        rules={[
                          {
                            required: formManual.getFieldValue("positionStatic")
                              ? false
                              : true,
                            message: "Por favor selecciona un espacio",
                          },
                        ]}
                        className={styles.custom_form_item}
                      >
                        <Radio.Group
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "17px",
                            marginBottom: "20px",
                          }}
                          name="module"
                          onChange={(e) =>
                            onHandleChangePositionManual(e.target.value)
                          }
                        >
                          {dataSelect?.module &&
                            OptionsPositionModules(dataSelect.module)}
                        </Radio.Group>
                      </Form.Item>
                    </Form>
                  </Panel>
                )}

                {(dataSelect?.module === 1 || dataSelect?.module === 12) && (
                  <Panel
                    header="Espacios fijos"
                    key="2"
                    className={`${styles.header_espacio} ${styles.padding_subcollapse}`}
                  >
                    <Form form={formManual}>
                      <Form.Item
                        name="positionStatic"
                        rules={[
                          {
                            required: formManual.getFieldValue("position")
                              ? false
                              : true,
                            message: "Por favor selecciona un espacio",
                          },
                        ]}
                        className={styles.custom_form_item}
                      >
                        <Radio.Group
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "17px",
                            marginBottom: "20px",
                          }}
                          name="module"
                          onChange={(e) =>
                            onHandleChangePositionManualStatic(e.target.value)
                          }
                        >
                          {dataSelect?.module &&
                            OptionsPositionStaticNews(dataSelect.module)}
                        </Radio.Group>
                      </Form.Item>
                    </Form>
                  </Panel>
                )}
              </Collapse>
              <div className={styles.container_button_collapse}>
                <Form
                  form={formManual}
                  onFinish={
                    isSchedule ? onFinishManualSchedule : onFinishManual
                  }
                  layout="inline"
                >
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className={styles.save_button_notices}
                      block
                    >
                      Guardar
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className={styles.cancel_button_notices}
                      onClick={() => {
                        dispatch(setMenu(false));
                        setShowModule(false);
                        setDataSelect({ ...dataSelect, module: null });
                        formManual.resetFields();
                        handleClose();
                      }}
                      block
                    >
                      Cancelar
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Menu>

      {!showModule && (
        <div className={styles.container_notices}>
          <div className={styles.container_search_table}>
            <div className={styles.container_options}>
              <span className={styles.text_search}>Buscar:</span>
              <Form>
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input_search}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form>
              <Button
                htmlType="submit"
                className={styles.save_button_notices}
                block
              >
                Buscar
              </Button>
            </div>
            <Pagination
              className={styles.pagination}
              size="small"
              showTotal={showTotal}
            />
          </div>

          <div className={styles.container_table}>
            <Table
              columns={columns}
              dataSource={newsWithOutPublicate?.data}
              pagination={false}
              className={styles.information_table}
            />

            <ModalSelectScheduleDate
              visible={isModalVisibleSchedule}
              callback={
                newsPublic?.visibleInHome
                  ? handleScheduleHome
                  : handleConfirmSchedule
              }
              onCancel={() => {
                setIsModalVisibleSchedule(false);
              }}
            />
            <Modal
              footer={null}
              visible={isModalVisibleEdit}
              onCancel={handleCancelEdit}
            >
              <div className={styles.container_content_modal}>
                <span className={styles.title_table}>Publicar noticia</span>
                <span className={styles.text_table}>
                  Deseas{" "}
                  {isSchedule ? "programar la publicación de" : "publicar"} la
                  noticia {`"${newsPublic.news?.title}"`}
                </span>
                <div className={styles.line_table_modal}></div>
                <div className={styles.container_option_home}>
                  <span className={styles.text_table_option}>
                    ¿Ésta noticia también estará ubicada en el home?
                  </span>
                  <div className={styles.container_switch}>
                    <span className={styles.text_color_switch}>No</span>
                    <Switch
                      checked={newsPublic?.visibleInHome}
                      onChange={(checked) =>
                        setNewsPublic({ ...newsPublic, visibleInHome: checked })
                      }
                      size="small"
                    />
                    <span className={styles.text_color_switch}>Si</span>
                  </div>
                </div>
                <div className={styles.container_buttons}>
                  {newsPublic?.visibleInHome ? (
                    <Button
                      htmlType="submit"
                      className={styles.save_button_notices}
                      block
                      onClick={
                        isSchedule
                          ? handleSelectSchedule
                          : () => {
                              setShowModule(true);
                              dispatch(setMenu(true));
                              setIsModalVisibleEdit(false);
                            }
                      }
                    >
                      {isSchedule ? "Programar" : "Continuar"}
                    </Button>
                  ) : (
                    <Button
                      htmlType="submit"
                      className={styles.save_button_notices}
                      block
                      onClick={
                        isSchedule ? handleSelectSchedule : handleConfirmPublish
                      }
                    >
                      {isSchedule ? "Programar" : "Publicar"}
                    </Button>
                  )}
                  <Button
                    className={styles.save_button_notices}
                    block
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Modal>
            {previewModal()}
          </div>
        </div>
      )}

      {dataSelect?.module === 1 && (
        <ModuleOne news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 2 && (
        <ModuleTwo news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 3 && (
        <ModuleThree news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 4 && (
        <ModuleFour news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 5 && (
        <ModuleFive news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 6 && (
        <ModuleSix news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 7 && (
        <ModuleSeven news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 8 && (
        <ModuleEight news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 9 && (
        <ModuleNine news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 10 && (
        <ModuleTen news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 11 && (
        <ModuleEleven news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 12 && (
        <ModuleTwelve news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 13 && (
        <ModuleThirteen news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 14 && (
        <ModuleFourteen news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 15 && (
        <ModuleFifteen news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 16 && (
        <ModuleSixteen news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 17 && (
        <ModuleSeventeen news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 18 && (
        <ModuleEighteen news={newsPublic?.news} block={dataSelect} />
      )}
      {dataSelect?.module === 19 && (
        <ModuleNineteen news={newsPublic?.news} block={dataSelect} />
      )}
    </>
  );
};

export default PostNotices;
