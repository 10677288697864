import React, { useRef } from "react";
import "moment/locale/es";
import moment from "moment";
import { Row, Col, Card } from "antd";
import Parser from "html-react-parser";
import Related from "../Related/Related";
import publicidad_uno from "../../../src/assets/img/publicidad_uno.svg";
import styles from "./styles.module.scss";
import { imgNoExist } from "../../helpers/img";
const ContentFour = ({ news }: any) => {
  const divContent = useRef<any>();

  return (
    <>
      <div className={styles.container_content_four}>
        <div className={styles.container_text_content_four}>
          <div className={styles.container_firma_general}>
            <div>
              <p className={styles.firma}>
                Por{" "}
                <span className={styles.underline}>
                  {news?.signature1?.fullName || `Firma 1`}
                </span>
                {news?.signature2 && (
                  <span className={styles.underline}>
                    {", "}
                    {news?.signature2?.fullName || `Firma 2`}
                  </span>
                )}
                {news?.signature3 && (
                  <span className={styles.underline}>
                    {", "}
                    {news?.signature3?.fullName || `Firma 3`}
                  </span>
                )}
              </p>
              <span className={styles.firma_noticia}>
                {moment(news?.createdAt).format("LL")}
              </span>
            </div>
          </div>
          <div id={styles.container_parrafos} ref={divContent}>
            {news?.text && Parser(news?.text)}

            {/* file */}
            {news?.file && (
              <p className={styles.underline}>
                <a href={news?.file} target="_blank" rel="noreferrer">
                  Click Aqui para descargar contenido
                </a>
              </p>
            )}
            <div className={styles.container_publicidad_uno}>
              <img src={publicidad_uno} width={728} alt="publicidad_uno" />
            </div>
            <hr className={styles.hr_spacing} />
          </div>
          {/* te puede interezar */}
          <div className={styles.container_interes}>
            <div className={styles.container_tex_interes}>
              <span className={styles.title_interes}>Te puede interesar</span>
            </div>
            <div className={styles.container_news_interest}>
              <div className={styles.container_news}>
                <div className={styles.image_news_related}>
                  <img src={imgNoExist} alt="noticia_relacionada" />
                </div>
                <span className={styles.text_news_related}>
                  Lorem ipsum dolor sit amer, consectetu adipiscing elit
                </span>
              </div>
              <div className={styles.container_news}>
                <div className={styles.image_news_related}>
                  <img src={imgNoExist} alt="noticia_relacionada" />
                </div>
                <span className={styles.text_news_related}>
                  Lorem ipsum dolor sit amer, consectetu adipiscing elit
                </span>
              </div>
              <div className={styles.container_news}>
                <div className={styles.image_news_related}>
                  <img src={imgNoExist} alt="noticia_relacionada" />
                </div>
                <span className={styles.text_news_related}>
                  Lorem ipsum dolor sit amer, consectetu adipiscing elit
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentFour;
