import newsConstants from "./constants";

const {
  SET_NEWS,
  SET_EDIT_NEWS,
  SET_POST_NEWS,
  SET_NEWS_WITHOUT_PUBLICATE,
  SET_NEWSS,
  LOADING,
  SET_ERROR,
  SET_SECTIONS,
} = newsConstants;

export const initialState = {
  news: {
    template: null,
    section: null,
    preTitle: null,
    title: null,
    titleOnBanner: false,
    subTitle: null,
    imgBanner: null,
    newsRelatedIsAuto: true,
    newsInterestedIsAuto: true,
  },
};

const newsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_NEWS:
      return {
        ...state,
        news: action.news,
      };
    case SET_EDIT_NEWS:
      return {
        ...state,
        editNews: action.news,
      };
    case SET_POST_NEWS:
      return {
        ...state,
        postNews: action.news,
      };
    case SET_NEWSS:
      return {
        ...state,
        newss: action.newss,
      };
    case SET_NEWS_WITHOUT_PUBLICATE:
      return {
        ...state,
        newsWithOutPublicate: action.news,
      };
    case SET_SECTIONS:
      return {
        ...state,
        sections: action.sections,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default newsReducer;
