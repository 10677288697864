import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  Collapse,
  Select,
  Button,
  Input,
  Radio,
  Switch,
  Image,
  Row,
  Col,
  Table,
  Tooltip,
  Drawer,
} from "antd";
import { showAlertError, showAlertSucces } from "../Alerts";
import { CaretRightOutlined } from "@ant-design/icons";
import module1 from "../../assets/modules/module1.png";
import module2 from "../../assets/modules/module2.png";
import module3 from "../../assets/modules/module3.png";
import module4 from "../../assets/modules/module4.png";
import module5 from "../../assets/modules/module5.png";
import module6 from "../../assets/modules/module6.png";
import module7 from "../../assets/modules/module7.png";
import module8 from "../../assets/modules/module8.png";
import module9 from "../../assets/modules/module9.png";
import module10 from "../../assets/modules/module10.png";
import module11 from "../../assets/modules/module11.png";
import module12 from "../../assets/modules/module12.png";
import module13 from "../../assets/modules/module13.png";
import module14 from "../../assets/modules/module14.png";
import module15 from "../../assets/modules/module15.png";
import module16 from "../../assets/modules/module16.png";
import module17 from "../../assets/modules/module17.png";
import module18 from "../../assets/modules/module18.png";
import module19 from "../../assets/modules/module19.png";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* table */
import "./index.css";
import { updateBlock } from "../../redux/dashboard/block/action";
import { setMenu } from "../../redux/settings/action";

const ContentBlockEdit = ({ data: dataBlock, ...props }: any) => {
  const { Panel } = Collapse;
  const { Option } = Select;

  const [value, setValue] = useState(1);
  const [nameBlock, setNameBlock] = useState("Bloque 1");

  const { sections } = useSelector((state: any) => state.blocks);

  const [data, setData] = useState<any>({});

  useEffect(() => {
    setData({
      id: dataBlock.id,
      module: dataBlock.module,
      title: dataBlock.title,
      title_active: dataBlock.title_active,
      color: dataBlock.color,
      bgColor: dataBlock.bgColor,
      section1: dataBlock?.section1?.id,
      section2: dataBlock?.section2?.id,
    });
  }, [dataBlock]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onHandleSubmit = () => {
    if (
      data.id &&
      data.module &&
      data.title &&
      data.title !== "" &&
      data.color
    ) {
      /*       if (data.module === 1 && (!data?.section1 || !data?.section2)) {
          showAlertError(
            "Ha ocurrido un error",
            "Todos los campos son requeridos"
          );
          return;
        } else if (data.module === 12 && !data?.section1) {
          showAlertError(
            "Ha ocurrido un error",
            "Todos los campos son requeridos"
          );
          return;
        }
   */
      dispatch(
        updateBlock(
          data,
          () => {
            showAlertSucces(
              "Bloque editado!",
              "El bloque ha sido editado correctamente."
            );
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
          () => {
            showAlertError(
              "Ha ocurrido un error",
              "El bloque no ha sido editado."
            );
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        )
      );
    }
  };

  return (
    <>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        expandIconPosition="right"
        bordered={false}
        ghost
        style={{ paddingBottom: "20px" }}
      >
        <Panel
          key="1"
          header="Elegir modulo"
          className={`${styles.header_panel_children}`}
        >
          <Radio.Group
            onChange={(e) => {
              if (data?.section1 || data?.section2) {
                setData({
                  id: data.id,
                  module: e.target.value,
                  title: data.title,
                  title_active: data.title_active,
                  color: data.color,
                });
              } else {
                setData({ ...data, module: e.target.value });
              }
            }}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "17px",
            }}
            name="module"
            value={data.module}
          >
            <Radio.Button value={1}>
              <div>
                <Image src={module1} preview={false} />
                <p className={styles.text_module}>Módulo 1</p>
              </div>
            </Radio.Button>
            <Radio.Button value={2}>
              <div>
                <Image src={module2} preview={false} />
                <p className={styles.text_module}>Módulo 2</p>
              </div>
            </Radio.Button>
            <Radio.Button value={3}>
              <div>
                <Image src={module3} preview={false} />
                <p className={styles.text_module}>Módulo 3</p>
              </div>
            </Radio.Button>
            <Radio.Button value={4}>
              <div>
                <Image src={module4} preview={false} />
                <p className={styles.text_module}>Módulo 4</p>
              </div>
            </Radio.Button>
            <Radio.Button value={5}>
              <div>
                <Image src={module5} preview={false} />
                <p className={styles.text_module}>Módulo 5</p>
              </div>
            </Radio.Button>
            <Radio.Button value={6}>
              <div>
                <Image src={module6} preview={false} />
                <p className={styles.text_module}>Módulo 6</p>
              </div>
            </Radio.Button>
            <Radio.Button value={7}>
              <div>
                <Image src={module7} preview={false} />
                <p className={styles.text_module}>Módulo 7</p>
              </div>
            </Radio.Button>
            <Radio.Button value={8}>
              <div>
                <Image src={module8} preview={false} />
                <p className={styles.text_module}>Módulo 8</p>
              </div>
            </Radio.Button>
            <Radio.Button value={9}>
              <div>
                <Image src={module9} preview={false} />
                <p className={styles.text_module}>Módulo 9</p>
              </div>
            </Radio.Button>
            <Radio.Button value={10}>
              <div>
                <Image src={module10} preview={false} />
                <p className={styles.text_module}>Módulo 10</p>
              </div>
            </Radio.Button>
            <Radio.Button value={11}>
              <div>
                <Image src={module11} preview={false} />
                <p className={styles.text_module}>Módulo 11</p>
              </div>
            </Radio.Button>
            <Radio.Button value={12}>
              <div>
                <Image src={module12} preview={false} />
                <p className={styles.text_module}>Módulo 12</p>
              </div>
            </Radio.Button>
            <Radio.Button value={13}>
              <div>
                <Image src={module13} preview={false} />
                <p className={styles.text_module}>Módulo 13</p>
              </div>
            </Radio.Button>
            <Radio.Button value={14}>
              <div>
                <Image src={module14} preview={false} />
                <p className={styles.text_module}>Módulo 14</p>
              </div>
            </Radio.Button>
            <Radio.Button value={15}>
              <div>
                <Image src={module15} width={"80px"} preview={false} />
                {/*Falta imagen modulo */}
                <p className={styles.text_module}>Módulo 15</p>
              </div>
            </Radio.Button>
            <Radio.Button value={16}>
              <div>
                <Image src={module16} width={"80px"} preview={false} />
                {/*Falta imagen modulo */}
                <p className={styles.text_module}>Módulo 16</p>
              </div>
            </Radio.Button>
            <Radio.Button value={17}>
              <div>
                <Image src={module17} width={"80px"} preview={false} />
                {/*Falta imagen modulo */}
                <p className={styles.text_module}>Módulo 17</p>
              </div>
            </Radio.Button>
            <Radio.Button value={18}>
              <div>
                <Image src={module18} width={"80px"} preview={false} />
                <p className={styles.text_module}>Módulo 18</p>
              </div>
            </Radio.Button>
            <Radio.Button value={19}>
              <div>
                <Image src={module19} width={"80px"} preview={false} />
                <p className={styles.text_module}>Módulo 19</p>
              </div>
            </Radio.Button>
          </Radio.Group>
        </Panel>

        <Panel
          key="2"
          collapsible="header"
          header="Titulo del bloque"
          extra={
            <div>
              <span className={styles.text_color_switch}>Visible</span>
              <Switch
                size="small"
                checked={data.title_active}
                onChange={(checked) =>
                  setData({ ...data, title_active: checked })
                }
              />
            </div>
          }
          className={`${styles.header_panel_children}`}
        >
          <Input
            placeholder="Titulo"
            name="title"
            value={data.title}
            onChange={(e) => setData({ ...data, title: e.target.value })}
          />
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            <input
              type="color"
              style={{
                border: "1px solid #f3f3f3",
                width: "28px",
                height: "28px",
              }}
              value={data.color || "#000"}
              onChange={(e) => setData({ ...data, color: e.target.value })}
            />
            <span className={styles.text_new_news}>Cambiar color de texto</span>
          </div>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            <input
              type="color"
              style={{
                border: "1px solid #f3f3f3",
                width: "28px",
                height: "28px",
              }}
              value={data.bgColor || "#000"}
              onChange={(e) => setData({ ...data, bgColor: e.target.value })}
            />
            <span className={styles.text_new_news}>Cambiar color de fondo</span>
          </div>
        </Panel>

        {/* {(data.module === 1 || data.module === "1") && (
            <Panel
              key="3"
              header="Secciones"
              className={`${styles.header_panel_children}`}
            >
              <Row gutter={[10, 0]} style={{ marginBottom: "2rem" }}>
                <Col
                  span={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img src={module1sec1} alt="Logo" className={styles.w_img} />
                </Col>
                <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    placeholder="Seleccionar una seccion"
                    className={styles.select_seccion}
                    onChange={(e) => setData({ ...data, section1: e })}
                    value={data?.section1}
                  >
                    {sections.data.map((section: any) => (
                      <Option
                        key={section.id}
                        className={styles.option_select}
                        value={section.id}
                      >
                        {section.title}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  span={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img src={module1sec2} alt="Logo" className={styles.w_img} />
                </Col>
                <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    placeholder="Seleccionar una seccion"
                    className={styles.select_seccion}
                    onChange={(e) => setData({ ...data, section2: e })}
                    value={data?.section2}
                  >
                    {sections.data.map((section: any) => (
                      <Option
                        key={section.id}
                        className={styles.option_select}
                        value={section.id}
                      >
                        {section.title}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Panel>
          )} */}

        {/* {(data.module === 12 || data.module === "12") && (
            <Panel
              key="3"
              header="Secciones"
              className={`${styles.header_panel_children}`}
            >
              <Row gutter={[10, 0]} style={{ marginBottom: "2rem" }}>
                <Col
                  span={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img src={module1sec1} alt="Logo" className={styles.w_img} />
                </Col>
                <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    placeholder="Seleccionar una seccion"
                    className={styles.select_seccion}
                    onChange={(e) => setData({ ...data, section1: e })}
                    value={data?.section1}
                  >
                    {sections.data.map((section: any) => (
                      <Option
                        key={section.id}
                        className={styles.option_select}
                        value={section.id}
                      >
                        {section.title}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Panel>
          )} */}
      </Collapse>
      <div className={`${styles.container_buttons}`}>
        <Button
          className={styles.save_button_notices}
          block
          onClick={onHandleSubmit}
        >
          Guardar
        </Button>
        <Button
          className={styles.cancel_button_notices}
          onClick={() => {
            dispatch(setMenu(false));
            props?.onCloseMenuEdit();
          }}
          block
        >
          Cancelar
        </Button>
      </div>
    </>
  );
};

export default ContentBlockEdit;
