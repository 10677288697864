import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";
import styles from "./styles.module.scss";
import { Row, Col, Card } from "antd";
import moment from "moment";
import "moment/locale/es";
import { imgNoExist } from "../../../helpers/img";

const ModuleTen = ({ block }: any) => {
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1060}>
            {block?.title_active && (
              <>
                <span
                  className={styles.title_module}
                  style={{ color: block?.color || "#103783" }}
                >
                  {block?.title || "Modulo 10"}
                </span>
                <div className={styles.line_section}></div>
              </>
            )}
            <Row gutter={[25, 0]} className={styles.divider_container}>
              <Col span={16}>
                <div
                  style={{
                    backgroundImage: `url(${
                      block?.new1?.imgBanner || imgNoExist
                    })`,
                  }}
                  className={styles.container_main}
                />
              </Col>
              <Col span={8}>
                <div>
                  <div className={styles.container_span}>
                    <span className={styles.text_main}>
                      {block?.new1?.section?.title || `section`}
                    </span>
                    <span className={styles.reading_main}>
                      {moment(block?.new1?.createdAt)
                        .locale("es")
                        .startOf("day")
                        .fromNow() || `Tiempo prueba`}
                    </span>
                  </div>
                  <p className={styles.title_main}>
                    {block?.new1?.title || `Titulo ejemplo`}
                  </p>
                  <p className={styles.subtitle_main}>
                    {block?.new1?.subtitle || `Subtitulo ejemplo`}
                  </p>
                </div>
              </Col>
            </Row>

            <Row gutter={[23, 0]}>
              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={
                    <img src={block?.new2?.imgBanner || imgNoExist} alt="" />
                  }
                >
                  <div className={styles.container_text_card}>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new2?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new2?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_card}>
                      {block?.new2?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle_card}>
                      {block?.new2?.subtitle || `Subtitulo ejemplo`}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={
                    <img src={block?.new3?.imgBanner || imgNoExist} alt="" />
                  }
                >
                  <div className={styles.container_text_card}>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new3?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new3?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_card}>
                      {block?.new3?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle_card}>
                      {block?.new3?.subtitle || `Subtitulo ejemplo`}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={
                    <img src={block?.new4?.imgBanner || imgNoExist} alt="" />
                  }
                >
                  <div className={styles.container_text_card}>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new4?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new4?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_card}>
                      {block?.new4?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle_card}>
                      {block?.new4?.subtitle || `Subtitulo ejemplo`}
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleTen;
