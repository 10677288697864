import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth/reducer";
import settings from "./settings/reducer";
import sections from "./dashboard/section/reducer";
import blocks from "./dashboard/block/reducer";
import news from "./dashboard/news/reducer";
import signature from "./dashboard/signature/reducer";
import barMenu from "./dashboard/barMenu/reducer";
import barBanner from "./dashboard/banner/reducer";
import slider from "./dashboard/slider/reducer";
import menuBurger from "./dashboard/menuBurger/reducer";
import announcer from "./dashboard/announcer/reducer";
import thermometer from "./dashboard/thermometer/reducer";
import subscriber from "./dashboard/subscriber/reducer";
import socials from "./dashboard/socials/reducer";
import legalWarning from "./dashboard/legalWarning/reducer";
import aboutUs from "./dashboard/aboutUs/reducer";
import privacyPolicy from "./dashboard/privacyPolicy/reducer";
import subSection from "./dashboard/subsection/reducer";
import contact from "./dashboard/contact/reducer";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "news"],
};

const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: [ "somethingTemporary" ],
};

const rootReducer = combineReducers({
  auth,
  settings,
  sections,
  subSection,
  blocks,
  news,
  signature,
  barMenu,
  barBanner,
  slider,
  thermometer,
  announcer,
  menuBurger,
  subscriber,
  socials,
  legalWarning,
  aboutUs,
  privacyPolicy,
  contact,
});

export default persistReducer(rootPersistConfig, rootReducer);
