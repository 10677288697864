const newsConstants = {
  SET_NEWS: "SET_NEWS",
  SET_EDIT_NEWS: "SET_EDIT_NEWS",
  SET_POST_NEWS: "SET_POST_NEWS",
  SET_CREATE_NEWS: "SET_CREATE_NEWS",
  SET_NEWSS: "SET_NEWSS",
  SET_NEWS_WITHOUT_PUBLICATE: "SET_NEWS_WITHOUT_PUBLICATE",
  SET_SECTIONS: "SET_SECTIONS",
  LOADING: "LOADING",
  SET_ERROR: "SET_ERROR",
};

export default newsConstants;
