import React from "react";
import styles from "./styles.module.scss";
import { Form, Input, Checkbox, Button, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo_img.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/auth/action";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Text } = Typography;
  const { user, loading, loginError } = useSelector((state: any) => state.auth);

  const onFinish = (values: any) => {
    dispatch(
      loginUser(values, () => {
        navigate("/dashboard");
      })
    );
    //falta mostrar errores
  };

  return (
    <>
      <div className={styles.container_1440}>
        <div className={styles.container_login_form}>
          <img src={logo} alt="Logo" className={styles.login_form_logo} />
          <Form
            name="login_medicina"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            className={styles.login_form}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Por favor introduce tu correo" },
                { type: "email", message: "Introduce un email válido" },
              ]}
              className={styles.space_item}
            >
              <Input
                placeholder="Correo electrónico"
                className={styles.inputs_login_form}
                style={{ color: "#ababab" }}
                autoComplete="off"
                prefix={<UserOutlined style={{ color: "#5BBCF7" }} />}
              />
            </Form.Item>
            <Form.Item
              className={styles.space_item}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Por favor introduce tu contraseña",
                },
              ]}
            >
              <Input.Password
                type="password"
                placeholder="Contraseña"
                className={styles.inputs_login_form}
                autoComplete="off"
                prefix={<LockOutlined style={{ color: "#5BBCF7" }} />}
              />
            </Form.Item>
            <Form.Item className={styles.space_item}>
              <Form.Item name="remember" noStyle valuePropName="checked">
                <Checkbox>
                  <span className={styles.checkbox_text}>Recordarme</span>
                </Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item className={styles.space_item}>
              <Link className={styles.login_form_forgot} to="/recover/request">
                <span className={styles.login_text}>
                  ¿Has olvidado la contraseña?
                </span>
              </Link>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className={styles.login_form_submit}
                block
              >
                <span className={styles.login_text}>Entrar</span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
