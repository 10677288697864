import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import { Collapse, Button, Switch, Table, Tooltip, Drawer } from "antd";

import { setSubMenu } from "../../../../redux/settings/action";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlocks,
  updateBlock,
  updatePositionBlocks,
} from "../../../../redux/dashboard/block/action";

/* table */
import { MenuOutlined } from "@ant-design/icons";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import ContentBlockEdit from "../../../../components/Blocks/ContentBlockEdit";
import ContentBlockCreate from "../../../../components/Blocks/ContentBlockCreate";

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const Blocks = () => {
  const { Panel } = Collapse;

  const dispatch = useDispatch();
  const { blocks } = useSelector((state: any) => state.blocks);
  const { subMenu, menu } = useSelector((state: any) => state.settings);

  const [visibleMenu, setVisibleMenu] = useState<boolean | undefined>(false);
  const [visibleMenuEdit, setVisibleMenuEdit] = useState<boolean | undefined>(
    false
  );
  const [visibleMenuCreate, setVisibleMenuCreate] = useState<
    boolean | undefined
  >(false);
  const [blockEdit, setBlockEdit] = useState<any>(null);

  useEffect(() => {
    dispatch(getBlocks({ page: 1, per_page: 999 }));
    dispatch(setSubMenu({ ...subMenu, moduls: true }));
  }, []);

  const onCloseMenu = () => {
    dispatch(setSubMenu({ ...subMenu, moduls: false }));
  };

  const onCloseMenuEdit = () => {
    setVisibleMenuEdit(false);
  };

  const onCloseMenuCreate = () => {
    setVisibleMenuCreate(false);
  };

  const divHeight = useRef<any>();
  const [height, setHeight] = useState<any>(0);

  useEffect(() => {
    setHeight(divHeight.current.clientHeight);
  }, []);

  window.addEventListener("resize", (v: any) => {
    setHeight(divHeight.current.clientHeight);
  });

  return (
    <>
      <div ref={divHeight} style={{ height: "100%" }}>
        <Drawer
          visible={subMenu?.moduls}
          placement={"left"}
          onClose={onCloseMenu}
          className={styles.custom_drawer}
          getContainer={false}
          closable={false}
          mask={false}
          maskClosable={false}
          style={{ height: `${height}px` }}
        >
          <Button
            htmlType="submit"
            className={styles.button_add_blocks}
            block
            onClick={() => {
              setVisibleMenuEdit(false);
              setVisibleMenuCreate(true);
            }}
          >
            Crear nuevo modulo
          </Button>
          <DragTable
            blocks={blocks?.data || []}
            setVisibleMenuEdit={setVisibleMenuEdit}
            setVisibleMenuCreate={setVisibleMenuCreate}
            setBlockEdit={setBlockEdit}
          />
        </Drawer>

        <Drawer
          title={`Editar (${blockEdit?.title})`}
          visible={visibleMenuEdit}
          placement={"right"}
          onClose={onCloseMenuEdit}
          getContainer={false}
          className={styles.custom_drawer_options}
          mask={false}
          style={{ height: `${height}px` }}
        >
          {blockEdit && (
            <ContentBlockEdit
              data={blockEdit}
              onCloseMenuEdit={onCloseMenuEdit}
            />
          )}
        </Drawer>

        <Drawer
          title={`Crear nuevo modulo`}
          visible={visibleMenuCreate}
          placement={"right"}
          onClose={onCloseMenuCreate}
          getContainer={false}
          className={styles.custom_drawer_options}
          mask={false}
          style={{ height: `${height}px` }}
        >
          <ContentBlockCreate onCloseMenuEdit={onCloseMenuCreate} />
        </Drawer>
      </div>
    </>
  );
};

const DragTable = ({
  blocks,
  setVisibleMenuEdit,
  setVisibleMenuCreate,
  setBlockEdit,
  ...props
}: any) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState<any>([]);
  const { loading } = useSelector((state: any) => state.blocks);

  useEffect(() => {
    setDataSource(blocks);
  }, [blocks]);

  const columns = [
    {
      title: () => (
        <FontAwesomeIcon icon={faArrowsAlt} className={styles.icon_bars} />
      ),
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Nombre",
      dataIndex: "title",
      className: "drag-visible",
    },
    {
      title: "Opciones",
      render: (row: any) => (
        <div className={styles.container_button_table_notices}>
          <Tooltip title="Visible">
            <Switch
              size="small"
              checked={row.status}
              onChange={(checked) =>
                dispatch(updateBlock({ id: row.id, status: checked }))
              }
            />
          </Tooltip>
          <Tooltip title="Editar">
            <FontAwesomeIcon
              icon={faPencilAlt}
              className={styles.icon_bars}
              onClick={() => {
                setBlockEdit(row);
                setVisibleMenuCreate(false);
                setVisibleMenuEdit(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  const SortableBody = SortableContainer((props: any) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      setDataSource(arrayMove(dataSource, oldIndex, newIndex));

      const filter = arrayMove(dataSource, oldIndex, newIndex);
      const array: Array<any> = [];
      filter.map((item: any) => {
        array.push({ id: item.id });
      });
      dispatch(updatePositionBlocks(array));
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource?.findIndex(
      (x: any) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <>
      <Table
        loading={loading}
        pagination={false}
        dataSource={dataSource || []}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </>
  );
};

export default Blocks;
