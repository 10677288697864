import trash from "../../../assets/img/papelera.svg";
import edit from "../../../assets/img/editar.svg";
import reload from "../../../assets/img/reload.svg";
import styles from "./styles.module.scss";
import { setMenu } from "../../../redux/settings/action";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Modal,
  Pagination,
  Table,
  Form,
  Switch,
  Tooltip,
} from "antd";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
  showAlertSuccesQuestion,
} from "../../../components/Alerts";
import { Router, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNews,
  getNewss,
  getNewsWhere,
  setEditNews,
  setNews,
  setPostNews,
  updateNews,
} from "../../../redux/dashboard/news/action";
import moment from "moment";
import "moment/locale/es";
import { NoticeStatus } from "../../../helpers/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";

const Notices = () => {
  const showTotal = (from: number, to: number, total: number) => {
    return `${from} - ${to} de ${total}`;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { menu } = useSelector((state: any) => state.settings);
  const { newss, postNews } = useSelector((state: any) => state.news);
  const [dataPage, setDataPage] = useState<any>({
    page: 1,
    per_page: 15,
  });
  const [searchInput, setSearchInput] = useState<any>({
    title: "",
  });

  /* modal */
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [switchFecha, setSwitchFecha] = useState(false);

  const handleClose = () => {
    dispatch(setPostNews(null));
    setIsModalVisibleEdit(false);
  };

  const showModalEdit = () => {
    setIsModalVisibleEdit(true);
  };

  const handleCancelEdit = () => {
    setIsModalVisibleEdit(false);
  };

  useEffect(() => {
    dispatch(getNewss(dataPage));
  }, [dataPage]);

  const RepublishedNew = (data: any) => {
    dispatch(setPostNews({ idNews: data.id, news: data }));
    showModalEdit();
  };

  const columns = [
    {
      title: "Noticia",
      render: (row: any) => (
        <>
          <p>
            {row?.title}{" "}
            {row?.template === "podcast" && (
              <Tooltip title="Podcast">
                <FontAwesomeIcon
                  icon={faBullhorn}
                  className={styles.icon_bars}
                />
              </Tooltip>
            )}
          </p>
        </>
      ),
    },
    {
      title: "Sección",
      render: (row: any) => row?.section?.title,
    },
    {
      title: "Subsección",
      render: (d: any) =>
        d?.subSection?.title ? d?.subSection?.title : "Sin subsección",
    },
    {
      title: "Fecha de creación",
      render: (row: any) => moment(row.createdAt).format("L"),
    },
    {
      title: "Estado",
      dataIndex: "noticeStatus",
      key: "noticeStatus",
    },
    {
      title: "Opciones",
      key: "action",
      render: (row: any) => (
        <>
          <div className={styles.container_icons}>
            <img
              src={trash}
              onClick={() => deleteNs(row)}
              className={styles.icons_table}
            />
            <img
              src={edit}
              onClick={(e) => {
                dispatch(setEditNews(row));
                navigate("/dashboard/editNews");
              }}
              className={styles.icons_table}
            />
            <img
              src={reload}
              onClick={(e) => {
                RepublishedNew(row);
              }}
              className={styles.icons_table}
            />
          </div>
        </>
      ),
    },
  ];

  /* options */
  const deleteNs = (row: any) => {
    showAlertQuestion(
      "¿Estás seguro que desea eliminar la noticia actual?",
      "Eliminar",
      () => {
        dispatch(
          deleteNews(
            row,
            () => {
              dispatch(getNewss(dataPage));
              showAlertSucces(
                "Noticia eliminada",
                "La noticia actual ha sido eliminada correctamente."
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  return (
    <>
      <div className={styles.container_notices}>
        <div className={styles.container_search_table}>
          <div className={styles.container_options}>
            <span className={styles.text_search}>Buscar:</span>
            <Form>
              <Input
                placeholder="Escribe aquí"
                className={styles.input_search}
                style={{ color: "#ababab" }}
                autoComplete="off"
                onChange={(e) => {
                  setSearchInput({ ...searchInput, title: e.target.value });
                }}
              />
            </Form>
            <Button
              htmlType="submit"
              className={styles.save_button_notices}
              block
              onClick={() => {
                if (searchInput.title === "") {
                  dispatch(getNewss(dataPage));
                } else {
                  dispatch(getNewsWhere(searchInput));
                }
              }}
            >
              Buscar
            </Button>
          </div>
          {newss?.total && (
            <Pagination
              className={styles.pagination}
              size="small"
              showTotal={() => showTotal(newss?.from, newss?.to, newss?.total)}
              showSizeChanger={false}
              defaultPageSize={newss?.per_page}
              pageSize={newss?.per_page || 15}
              total={newss?.total}
              onChange={(page) => setDataPage({ ...dataPage, page })}
            />
          )}
        </div>
        <div className={styles.container_table}>
          <Table
            columns={columns}
            dataSource={newss?.data}
            pagination={false}
            className={styles.information_table}
            rowKey={"id"}
          />
        </div>
      </div>
      <Modal
        footer={null}
        visible={isModalVisibleEdit}
        onCancel={handleCancelEdit}
      >
        <div className={styles.container_content_modal}>
          <span className={styles.title_table}>Republicar noticia</span>
          <span className={styles.text_table}>
            Deseas republicar la noticia
          </span>
          <div className={styles.line_table_modal}></div>
          <div className={styles.container_option_home}>
            <span className={styles.text_table_option}>
              ¿Desea actualizar la fecha de creacion?
            </span>
            <div className={styles.container_switch}>
              <span className={styles.text_color_switch}>No</span>
              <Switch
                checked={switchFecha}
                onChange={(checked: any) => {
                  setSwitchFecha(checked);
                }}
                size="small"
              />
              <span className={styles.text_color_switch}>Si</span>
            </div>
          </div>
          <div className={styles.container_buttons}>
            <Button
              htmlType="submit"
              className={styles.save_button_notices}
              block
              onClick={() => {
                dispatch(setPostNews({ ...postNews, updateDate: switchFecha }));
                navigate("/dashboard/post_news");
              }}
            >
              {"Continuar"}
            </Button>

            <Button
              className={styles.save_button_notices}
              block
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Notices;
