import thermConstants from "./constants";
import thermServices from "./services";

const { SET_THERMOMETER, SET_NEWS, LOADING, SET_ERROR } = thermConstants;

export const setThermNews = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await thermServices.setTherm(data);
      console.log(data);
      console.log(response);
      if (callback) callback();
    } catch (error: any) {
      if (err) err();
    }
  };
};

export const setThermometer = (data: any) => ({
  type: SET_THERMOMETER,
  data,
});

export const getThermometer = (callback?: any, err?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await thermServices.getTherm();
      for (const item of response.data) {
        if (item.img.charAt(0) === "{") {
          const imgcpy = JSON.parse(item.img);
          item.img = imgcpy;
        }
      }
      dispatch(setThermometer(response.data));
    } catch (error: any) {
      if (err) err();
    }
  };
};
