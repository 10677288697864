import api from "../../../helpers/api";

const barMenuServices = {
  getAll: () => {
    return api.get("/menuBurger/getAll");
  },
  getAllWithoutActive: () => {
    return api.get("/menuBurger/getAllWithoutActive");
  },

  update: (data: any) => {
    return api.put("/menuBurger/update", {
      data,
    });
  },
  save: (data: any) => {
    return api.post("/menuBurger/save", {
      data,
    });
  },

  delete: (data: any) => {
    return api.delete("/menuBurger/delete", {
      data,
    });
  },
};

export default barMenuServices;
