import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Pagination, Select, Table } from "antd";
import { Button, Modal, Form, Input, Radio } from "antd";
import trash from "../../../assets/img/papelera.svg";
import edit from "../../../assets/img/editar.svg";
import {
  showAlertSuccesQuestion,
  showAlertSucces,
  showAlertError,
} from "../../../components/Alerts";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSection,
  getSections,
  saveSection,
  updateSections,
} from "../../../redux/dashboard/section/action";
import moment from "moment";
import { SectionType } from "../../../helpers/enums";
import TextArea from "antd/lib/input/TextArea";

const CreateSeccions = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { sections }: any = useSelector((state: any) => state.sections);
  const [formCreate] = Form.useForm();
  const [formEdit] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<any>({ title: "" });
  const [dataPage, setDataPage] = useState<any>({
    page: 1,
    per_page: 15,
  });

  useEffect(() => {
    dispatch(getSections(dataPage));
  }, [dataPage]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    formCreate.resetFields();
  };

  const handleCancelEdit = () => {
    setIsModalVisibleEdit(false);
    formEdit.resetFields();
  };

  const onFinishCreate = (e: any) => {
    setIsModalVisible(false);
    dispatch(
      saveSection(
        e,
        () => {
          showAlertSucces(
            "¡Sección creada!",
            "La sección ha sido creada exitosamente."
          );
          formCreate.resetFields();
        },
        () => {
          showAlertError(
            "Ha currido un error",
            "No se ha podido completar correctamente la accion, intentalo nuevamente"
          );
        }
      )
    );
  };

  const onFinishEdit = (e: any) => {
    setIsModalVisibleEdit(false);
    showAlertSuccesQuestion(
      "¿Estás seguro que desea editar la sección actual?",
      "Guardar",
      "¡Sección editada!",
      "La sección actual ha sido editada correctamente.",
      () => {
        dispatch(updateSections({ ...dataEdit, ...e }));
        formEdit.resetFields();
      }
    );
  };

  const onHandleDelete = (data: any) => {
    showAlertSuccesQuestion(
      "¿Estás seguro que desea eliminar la sección actual?",
      "Eliminar",
      "Sección eliminada",
      "La sección actual ha sido eliminada correctamente.",
      () => {
        dispatch(deleteSection(data));
      }
    );
  };

  const onHandleEdit = (data: any) => {
    formEdit.setFieldsValue(data);
    setDataEdit(data);
    setIsModalVisibleEdit(true);
  };

  const showTotal = (from: number, to: number, total: number) => {
    return `${from} - ${to} de ${total}`;
  };

  const columns = [
    {
      title: "Nombre Sección",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Tipo",
      render: (data: any) => {
        if (data?.type === SectionType.PODCAST) {
          return "Podcast";
        } else if (data?.type === SectionType.VIDEO) {
          return "Video";
        } else if (data?.type === SectionType.PARTNERS) {
          return "Socios";
        } else {
          return "Sin tipo";
        }
      },
    },
    {
      title: "descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Fecha de Creación",
      render: (data: any) => moment(data.createdAt).format("L"),
    },
    {
      key: "action",
      title: "Opciones",
      render: (data: any) => (
        <>
          <img
            src={trash}
            className={styles.icons_table}
            onClick={() => onHandleDelete({ id: data.id })}
          />
          <img
            src={edit}
            onClick={() => onHandleEdit(data)}
            className={styles.icons_table}
          />
        </>
      ),
    },
  ];

  /* size table */
  const divHeight = useRef<any>();
  const divOptionsHeight = useRef<any>();
  const [height, setHeight] = useState<any>(100);
  const [optionsHeight, setOptionsHeight] = useState<any>(32);

  useEffect(() => {
    setHeight(divHeight?.current?.clientHeight);
    setOptionsHeight(divOptionsHeight?.current?.clientHeight);
  }, []);

  window.addEventListener("resize", (v: any) => {
    setHeight(divHeight?.current?.clientHeight);
    setOptionsHeight(divOptionsHeight?.current?.clientHeight);
  });
  return (
    <>
      <div className={styles.container_seccion} ref={divHeight}>
        <div className={styles.container_search_table} ref={divOptionsHeight}>
          <Button onClick={showModal} className={styles.button} block>
            <span className={styles.btnSesion_text}>Crear nueva sección</span>
          </Button>

          <Pagination
            className={styles.pagination}
            size="small"
            showSizeChanger={false}
            total={sections?.total}
            showTotal={() =>
              showTotal(sections?.from, sections?.to, sections?.total)
            }
            defaultCurrent={sections?.current_page}
            defaultPageSize={sections?.per_page}
            pageSize={sections?.per_page || 15}
            onChange={(page) => setDataPage({ ...dataPage, page })}
            current={sections?.current_page || 1}
          />
        </div>

        <div
          className={styles.container_table}
          style={{
            height: `${height - optionsHeight}px`,
            overflowY: "auto",
          }}
        >
          <Table
            columns={columns}
            dataSource={sections?.data}
            className={styles.booking_information_table}
            pagination={false}
            /* pagination={{ position: ["topRight", "topRight"] }} */
          />
        </div>

        {/* news section */}
        <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
          <div className={styles.container_content_modal}>
            <span className={styles.title_seccion}>Nueva sección</span>
            <span className={styles.text_seccion}>Nombre</span>
            <Form onFinish={onFinishCreate} form={formCreate}>
              <Form.Item
                name="title"
                className={styles.space_item}
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                  {
                    validator: (rule, value) => {
                      if (
                        sections?.data.filter(
                          (item: any) => item.title === value
                        ).length > 0
                      ) {
                        return Promise.reject(
                          "No se pueden crear dos secciones con el mismo nombre"
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input_seccion}
                  autoComplete="off"
                />
              </Form.Item>

              {/* description */}
              <Form.Item className={styles.custom_form_item}>
                <span className={styles.text_seccion}>Descripción</span>
                <Form.Item
                  name="description"
                  className={styles.space_item}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <span className={styles.text_seccion}>
                  Tipo sección - Opcional
                </span>

                <Form.Item name="type">
                  <Select
                    placeholder="Elegir un tipo"
                    className={styles.select_seccion}
                    defaultValue={null}
                  >
                    <Option className={styles.option_select} value={null}>
                      Sin tipo
                    </Option>

                    <Option
                      className={styles.option_select}
                      value={SectionType.PODCAST}
                    >
                      Podcast
                    </Option>
                    <Option
                      className={styles.option_select}
                      value={SectionType.PARTNERS}
                    >
                      Socios
                    </Option>
                    <Option
                      className={styles.option_select}
                      value={SectionType.VIDEO}
                    >
                      Videos
                    </Option>
                  </Select>
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  className={styles.save_button_seccion}
                  block
                >
                  Guardar
                </Button>
                <Button
                  htmlType="submit"
                  className={styles.cancel_button_seccion}
                  block
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        {/* Editar */}
        <Modal
          visible={isModalVisibleEdit}
          footer={null}
          onCancel={handleCancelEdit}
        >
          <div className={styles.container_content_modal}>
            <span className={styles.title_seccion}>Editar sección</span>
            <span className={styles.title_seccion_editar}>
              {dataEdit.title}
            </span>
            <span className={styles.text_seccion}>Editar nombre</span>
            <Form onFinish={onFinishEdit} form={formEdit}>
              <Form.Item
                name="title"
                className={styles.space_item}
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                  {
                    validator: (rule, value) => {
                      if (
                        sections?.data.filter(
                          (item: any) =>
                            item.title === value && item.id !== dataEdit.id
                        ).length > 0
                      ) {
                        return Promise.reject(
                          "No se pueden crear dos secciones con el mismo nombre"
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input_seccion}
                  autoComplete="off"
                />
              </Form.Item>

              {/* description */}
              <Form.Item className={styles.custom_form_item}>
                <span className={styles.text_seccion}>Descripción</span>
                <Form.Item
                  name="description"
                  className={styles.space_item}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <span className={styles.text_seccion}>
                  Tipo sección - Opcional
                </span>

                <Form.Item name="type">
                  <Select
                    placeholder="Elegir un tipo"
                    className={styles.select_seccion}
                  >
                    <Option className={styles.option_select} value={null}>
                      Sin tipo
                    </Option>

                    <Option
                      className={styles.option_select}
                      value={SectionType.PODCAST}
                    >
                      Podcast
                    </Option>
                    <Option
                      className={styles.option_select}
                      value={SectionType.PARTNERS}
                    >
                      Socios
                    </Option>
                    <Option
                      className={styles.option_select}
                      value={SectionType.VIDEO}
                    >
                      Videos
                    </Option>
                  </Select>
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  className={styles.save_button_seccion}
                  block
                >
                  Guardar
                </Button>
                <Button
                  block
                  className={styles.cancel_button_seccion}
                  onClick={handleCancelEdit}
                >
                  Cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CreateSeccions;
