import api from "../../../helpers/api";

const bannerServices = {
    update: (data: any) => {
        return api.put("/barBanner/update", {
            data,
        });
    },
    getAll: () => {
        return api.get("/barBanner/getAll");
    }
}

export default bannerServices;