const blockConstants = {
  SET_BLOCK: "SET_BLOCK",
  SET_BLOCKS: "SET_BLOCKS",
  SET_SECTIONS: "SET_SECTIONS",
  UPDATE_BLOCK: "UPDATE_BLOCK",
  DELETE_BLOCK: "DELETE_BLOCK",
  LOADING: "LOADING",
  SET_ERROR: "SET_ERROR",
};

export default blockConstants;
