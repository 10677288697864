import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import styles from "./styles.module.scss";
import banner_principal from "../../../src/assets/img/banner_general.svg";
import publicidad_uno from "../../../src/assets/img/publicidad_uno.svg";
import facebook from "../../../src/assets/img/facebook.svg";
import instagram from "../../../src/assets/img/instagram.svg";
import linkedln from "../../../src/assets/img/linkedln.svg";
import twitter from "../../../src/assets/img/twitter.svg";
import whatsapp from "../../../src/assets/img/whatsapp.svg";
import bannerGeneral from "../../assets/img/banner_general.svg";
import { imgNoExist } from "../../helpers/img";
import ReactPlayer from "react-player";

const BannerFour = ({ news }: any) => {
  const [imgVideo, setImgVideo] = useState<any>(null);
  const [imgBanner, setImgBanner] = useState<any>(null);

  const convertImgVideo = async () => {
    let src: any;
    if (news?.imgVideo) {
      const { imgVideo } = news;
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgVideo);
        reader.onload = () => resolve(reader.result);
      });
    }
    const img = new Image();
    img.src = src;
    setImgVideo(img.src);
  };
  const convertImg = async () => {
    let src: any;
    if (news?.imgBanner) {
      const { imgBanner } = news;
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgBanner);
        reader.onload = () => resolve(reader.result);
      });
    }
    const img = new Image();
    img.src = src;
    setImgBanner(img.src);
  };

  useEffect(() => {
    convertImgVideo();
    convertImg();
  }, [news]);

  return (
    <>
      <div>
        <div className={styles.container_text_banner}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a>Noticia Patrocinada</a>
            </Breadcrumb.Item>
          </Breadcrumb>

          {news?.preTitle && (
            <p className={styles.antetitulo_banner}>{news.preTitle}</p>
          )}
          {news?.title && <p className={styles.title_banner}>{news.title}</p>}
          {news?.subTitle && (
            <p className={styles.subtitulo_banner}>{news.subTitle}</p>
          )}
          {/* Share */}
          <div className={styles.container_share}>
            <span className={styles.text_share}>Compartir</span>
            <img
              src={facebook}
              height={22}
              alt="logo_facebook"
              className={styles.icon_logo}
            />
            <img
              src={instagram}
              height={22}
              alt="logo_instagram"
              className={styles.icon_logo}
            />
            <img
              src={linkedln}
              height={22}
              alt="logo_linkedln"
              className={styles.icon_logo}
            />
            <img
              src={twitter}
              height={22}
              alt="logo_twitter"
              className={styles.icon_logo}
            />
            <img
              src={whatsapp}
              height={22}
              alt="logo_whatsapp"
              className={styles.icon_logo}
            />
          </div>
        </div>

        <div className={styles.container_img_announcer}>
          {news?.video ? (
            <ReactPlayer
              url={news?.video}
              light={news?.imgVideo ? imgVideo : true}
              className={styles.container_foto_pie}
            />
          ) : (
            <>
              <div className={styles.container_foto_pie}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={
                    (news?.imgBanner && imgBanner) ||
                    (!news?.video && imgVideo) ||
                    news?.imgVideo ||
                    imgNoExist
                  }
                  alt="img_content"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BannerFour;
