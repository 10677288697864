import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Menu from "../../../../components/Menu";
import { Collapse, Form, Button } from "antd";
import LegalWarning from "../../../../components/Footer/LegalWarning";
import AboutUs from "../../../../components/Footer/AboutUs";
import PrivacyPolicy from "../../../../components/Footer/PrivacyPolicy";
import Contact from "../../../../components/Footer/Contact";
import { EditOutlined } from "@ant-design/icons";

const footer = () => {
  const { Panel } = Collapse;

  return (
    <>
      <Menu>
        <Collapse
          bordered={false}
          expandIcon={() => <EditOutlined />}
          expandIconPosition="right"
        >
          <Panel header={`Aviso legal y condiciones de uso`} key={"avisoLegal"}>
            <LegalWarning />
          </Panel>
          <Panel header={`Quienes somos`} key={2}>
            <AboutUs />
          </Panel>
          <Panel header={`Política de Privacidad`} key={3}>
            <PrivacyPolicy />
          </Panel>
          <Panel header={`Contacto`} key={4}>
            <Contact/>
          </Panel>
        </Collapse>
      </Menu>
    </>
  );
};

export default footer;
