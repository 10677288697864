import api from "../../../helpers/api";

const aboutUsServices = {
  getAll: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get("/aboutUs/getAll", {
      params: {
        page,
        per_page,
      },
    });
  },

  save: (data: any) => {
    return api.post(
      "/aboutUs/save",
      { data },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  update: (data: any) => {
    return api.put("/aboutUs/update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default aboutUsServices;
