import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";
import styles from "./styles.module.scss";
import { Row, Col, Card } from "antd";
import { Progress } from "antd";
import moment from "moment";
import "moment/locale/es";
import { imgNoExist } from "../../../helpers/img";

const ModuleThirteen = ({ block }: any) => {
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1060}>
            <div className={styles.container_module}>
              {block?.title_active && (
                <>
                  <span
                    className={styles.title_module}
                    style={{ color: block?.color || "#103783" }}
                  >
                    {block?.title || "Modulo 13"}
                  </span>
                </>
              )}
              <div className={styles.container_line}>
                <div>
                  <Progress
                    strokeColor={{
                      "33%": "#52B68F",
                      "66%": "#52B68F",
                      "100%": "#E84C4E",
                    }}
                    percent={100}
                    showInfo={false}
                  />
                </div>
              </div>
              <Row gutter={[39, 0]}>
                <Col span={8}>
                  <Card
                    className={styles.container_card}
                    cover={
                      <img src={block?.new1?.imgBanner || imgNoExist} alt="" />
                    }
                  >
                    <div>
                      <p className={styles.title_card}>
                        {block?.new1?.title || "Titulo ejemplo"}
                      </p>
                      <p className={styles.subtitle_card}>
                        {block?.new1?.subTitle || "Subtitulo ejemplo"}
                      </p>
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    className={styles.container_card}
                    cover={
                      <img src={block?.new2?.imgBanner || imgNoExist} alt="" />
                    }
                  >
                    <div>
                      <p className={styles.title_card}>
                        {block?.new2?.title || "Titulo ejemplo"}
                      </p>
                      <p className={styles.subtitle_card}>
                        {block?.new2?.subTitle || "Subtitulo ejemplo"}
                      </p>
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    className={styles.container_card}
                    cover={
                      <img src={block?.new3?.imgBanner || imgNoExist} alt="" />
                    }
                  >
                    <div>
                      <p className={styles.title_card}>
                        {block?.new3?.title || "Titulo ejemplo"}
                      </p>
                      <p className={styles.subtitle_card}>
                        {block?.new3?.subTitle || "Subtitulo ejemplo"}
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleThirteen;
