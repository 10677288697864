import api from "../../../helpers/api";

const socialServices = {
    getAll: () => {
        return api.get("/socials/getAll");
    },
    save: (data: any) => {
        return api.post("/socials/save", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    },
    update: (data: FormData) => {
        return api.put("/socials/update", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    },
    delete: (data: any) => {
        return api.delete("/socials/delete", { data });
    },
};


export default socialServices