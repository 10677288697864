import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
/* Router */
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./Login"; /* con miniscula */
import RecoverRequest from "./recover/request";
import RecoverToken from "./recover/token";
import Dashboard from "./dashboard/index";
import Sections from "./dashboard/sections/sections";
import Subsections from "./dashboard/subsections";
import NewNotice from "./dashboard/notices/new_notices";
import PostNotice from "./dashboard/notices/post_notices";
import Notices from "./dashboard/notices/notices";
import Blocks from "./dashboard/home/blocks";
import Signature from "./dashboard/signature";
import MenuBar from "./dashboard/home/menu";
import Banner from "./dashboard/home/banner";
import Slider from "./dashboard/home/slider";
import Footer from "./dashboard/home/footer";
import AdminUsers from "./dashboard/adminUsers";
import NotFound from "./notFound";
import MenuBurger from "./dashboard/burger";
import MenuSocial from "./dashboard/socials";
import Announcers from "./dashboard/anouncers";
import Thermometer from "./dashboard/home/thermometer";
import Subscribers from "./dashboard/subscribers";
import EditNews from "./dashboard/notices/EditNews";

const AppRouter = () => {
  const loggedOutRoutes = ["/login", "/recover/request", "/recover/"];

  const { user } = useSelector((state: any) => state.auth);
  const { editNews } = useSelector((state: any) => state.news);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  /* Verify if exist user and redirect */
  useEffect(() => {
    const { pathname } = location;
    if (!user) {
      const redirect = loggedOutRoutes.every(
        (route) => !pathname.startsWith(route)
      );
      if (redirect && pathname !== "/login") navigate("/login");
    } else {
      const redirect = loggedOutRoutes.some((route) =>
        pathname.startsWith(route)
      );
      if (redirect || pathname === "/") navigate("/dashboard");
    }
  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/recover/request" element={<RecoverRequest />} />
        <Route path="/recover/:token" element={<RecoverToken />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="banner" element={<Banner />} />
          <Route path="thermometer" element={<Thermometer />} />
          <Route path="slider" element={<Slider />} />
          <Route path="menubar" element={<MenuBar />} />
          <Route path="modules" element={<Blocks />} />
          <Route path="subsections" element={<Subsections />} />
          <Route path="sections" element={<Sections />} />
          <Route path="new_news" element={<NewNotice />} />
          {editNews && <Route path="editNews" element={<EditNews />} />}
          <Route path="news" element={<Notices />} />
          <Route path="post_news" element={<PostNotice />} />
          <Route path="signature" element={<Signature />} />
          <Route path="subscribers" element={<Subscribers />} />
          <Route path="announcer" element={<Announcers />} />
          <Route path="burger" element={<MenuBurger />} />
          <Route path="social" element={<MenuSocial />} />
          <Route path="admin-user" element={<AdminUsers />} />
          <Route path="footer" element={<Footer />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRouter;
