import React from "react";
import styles from "./styles.module.scss";
import { Row, Col, Card } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { imgNoExist } from "../../../helpers/img";
import moment from "moment";
import "moment/locale/es";
const ModuleSeven = ({ block }: any) => {
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1440}>
            <div className={styles.container_1060}>
              <div className={styles.container_title}>
                {block?.title_active && (
                  <span
                    className={styles.title_module}
                    style={{ color: block?.color || "#103783" }}
                  >
                    {block?.title || "Modulo 7"}
                  </span>
                )}
              </div>
              <Row gutter={[27, 0]}>
                <Col span={18}>
                  <div
                    className={styles.container_video}
                    style={{
                      backgroundImage: `url(${
                        block?.new1?.imgBanner || imgNoExist
                      })`,
                    }}
                  />
                </Col>
                <Col span={6}>
                  <div>
                    <span className={styles.reading}>
                      {moment(block?.new1?.createdAt)
                        .locale("es")
                        .startOf("day")
                        .fromNow() || `Tiempo prueba`}
                    </span>
                    <p className={styles.title}>
                      {block?.new1?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle}>
                      {block?.new1?.subTitle || `Sub titulo ejemplo`}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleSeven;
