import menuBurgerConstants from "./constants";
import menuBurgerServices from "./services";

const { SET_PLACE, SET_PLACES, LOADING, SET_ERROR } = menuBurgerConstants;

export const setPlace = (place: any) => ({
  type: SET_PLACE,
  place,
});

export const setPlaces = (places: any) => ({
  type: SET_PLACES,
  places,
});

export const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

export const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

export const getPlaces = (callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await menuBurgerServices.getAllWithoutActive();
      const { response: res } = response.data;
      dispatch(setPlaces(res));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const updatePlaces = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const response = await menuBurgerServices.update(data);
      if (response) dispatch(getPlaces());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const savePlace = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));

    try {
      const response = await menuBurgerServices.save(data);
      if (response) dispatch(getPlaces());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const deletePlace = (data: string, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await menuBurgerServices.delete(data);
      if (response) dispatch(getPlaces());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};
