import api from "../../../helpers/api";

const subscriberServices = {
  getAll: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get("/subscriber/getAll", {
      params: {
        page,
        per_page,
      },
    });
  },
};

export default subscriberServices;
