import { stat } from "fs";
import thermConstants from "./constants";

const { SET_THERMOMETER, SET_NEWS, LOADING, SET_ERROR } =
  thermConstants;

export const initialState = {
  thermometer: null
};

const therReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case SET_THERMOMETER:
      console.log(action);
      return{
        ...state,
        thermometer: action.data,
    }
    default:
      return state;
  }
};

export default therReducer;
