import api from "../../../helpers/api";

const thermServices = {
    getTherm: () => {
        return api.get("/thermometer/get");
    },

    setTherm:(data:any) => {
        console.log(data);
        return api.patch("/thermometer/edit", {
            data,
          });
    },
};

export default thermServices;