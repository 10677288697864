import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";
import styles from "./styles.module.scss";
import { Row, Col, Card } from "antd";
import moment from "moment";
import "moment/locale/es";
import { imgNoExist } from "../../../helpers/img";

const ModuleSeventeen = ({ block }: any) => {
  console.log(block);
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1060}>
            {block?.title_active && (
              <>
                <span
                  className={styles.title_module}
                  style={{ color: block?.color || "#103783" }}
                >
                  {block?.title || "Modulo 16"}
                </span>
                <div className={styles.line_section}></div>
              </>
            )}

            <Row gutter={[23, 0]}>
              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={
                    <img
                      src={
                        block?.new1?.imgBanner ||
                        block?.new1?.imgVideo ||
                        block?.new1?.imgPodcast ||
                        imgNoExist
                      }
                      alt=""
                    />
                  }
                >
                  <div className={styles.container_text_card}>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new1?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new1?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_card}>
                      {block?.new1?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle_card}>
                      {block?.new1?.subtitle || `Subtitulo ejemplo`}
                    </p>
                  </div>
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={
                    <img
                      src={
                        block?.new2?.imgBanner ||
                        block?.new2?.imgVideo ||
                        block?.new2?.imgPodcast ||
                        imgNoExist
                      }
                      alt=""
                    />
                  }
                >
                  <div className={styles.container_text_card}>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new2?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new2?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_card}>
                      {block?.new2?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle_card}>
                      {block?.new2?.subtitle || `Subtitulo ejemplo`}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={
                    <img
                      src={
                        block?.new3?.imgBanner ||
                        block?.new3?.imgVideo ||
                        block?.new3?.imgPodcast ||
                        imgNoExist
                      }
                      alt=""
                    />
                  }
                >
                  <div className={styles.container_text_card}>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new3?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new3?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_card}>
                      {block?.new3?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle_card}>
                      {block?.new3?.subtitle || `Subtitulo ejemplo`}
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={[23, 0]}>
              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={
                    <img
                      src={
                        block?.new4?.imgBanner ||
                        block?.new4?.imgVideo ||
                        block?.new4?.imgPodcast ||
                        imgNoExist
                      }
                      alt=""
                    />
                  }
                >
                  <div className={styles.container_text_card}>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new4?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new4?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_card}>
                      {block?.new4?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle_card}>
                      {block?.new4?.subtitle || `Subtitulo ejemplo`}
                    </p>
                  </div>
                </Card>
              </Col>

              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={
                    <img
                      src={
                        block?.new5?.imgBanner ||
                        block?.new5?.imgVideo ||
                        block?.new5?.imgPodcast ||
                        imgNoExist
                      }
                      alt=""
                    />
                  }
                >
                  <div className={styles.container_text_card}>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new5?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new5?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_card}>
                      {block?.new5?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle_card}>
                      {block?.new5?.subtitle || `Subtitulo ejemplo`}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={
                    <img
                      src={
                        block?.new6?.imgBanner ||
                        block?.new6?.imgVideo ||
                        block?.new6?.imgPodcast ||
                        imgNoExist
                      }
                      alt=""
                    />
                  }
                >
                  <div className={styles.container_text_card}>
                    <div className={styles.container_span}>
                      <span className={styles.text_main}>
                        {block?.new6?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_main}>
                        {moment(block?.new6?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.title_card}>
                      {block?.new6?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.subtitle_card}>
                      {block?.new6?.subtitle || `Subtitulo ejemplo`}
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleSeventeen;
