import React from "react";
import styles from "./styles.module.scss";
import publicidad_dos from "../../../src/assets/img/publicidad_dos.svg";
import noticia_relacionada_uno from "../../../src/assets/img/noticia_relacionada_uno.svg";
import noticia_relacionada_dos from "../../../src/assets/img/noticia_relacionada_dos.svg";
import { imgNoExist } from "../../helpers/img";
import { Col, Row } from "antd";

const Related = () => {
  return (
    <>
      <Row className={styles.spacing}>
        <Col span={24}>
          <div className={styles.container_related_banner}>
            <p className={styles.noticias_relacionadas}>
              Noticias relacionadas
            </p>
            <hr className={styles.hr_spacing_relacionada} />
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={8} md={8} lg={24} xl={24} xxl={24}>
                <div className={styles.container_relacionada}>
                  <div className={styles.container_image_relacionada}>
                    <img
                      src={imgNoExist}
                      alt="img_relacionada"
                      style={{ borderRadius: "6px" }}
                    />
                  </div>
                  <div className={styles.container_seccion}>
                    <span className={styles.text_seccion}>Sección</span>
                    <p className={styles.parrafo_seccion}>
                      {
                        "El plan antitabaco de Sanidad 'triunfa' entre los sanitarios"
                      }
                    </p>
                    <p className={styles.subparrafo_seccion}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
                <hr className={styles.hr_spacing_relacionada} />
              </Col>
              <Col xs={24} sm={8} md={8} lg={24} xl={24} xxl={24}>
                <div className={styles.container_relacionada}>
                  <div className={styles.container_image_relacionada_dos}>
                    <img
                      src={imgNoExist}
                      alt="img_relacionada"
                      style={{ borderRadius: "6px" }}
                    />
                  </div>
                  <div className={styles.container_seccion}>
                    <span className={styles.text_seccion}>Sección</span>
                    <p className={styles.parrafo_seccion}>
                      El rostro femenino del cáncer de pulmón
                    </p>
                    <p className={styles.subparrafo_seccion}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
                <hr className={styles.hr_spacing_relacionada} />
              </Col>
              <Col xs={24} sm={8} md={8} lg={24} xl={24} xxl={24}>
                <div className={styles.container_relacionada}>
                  <div className={styles.container_image_relacionada}>
                    <img
                      src={imgNoExist}
                      alt="img_relacionada"
                      style={{ borderRadius: "6px" }}
                    />
                  </div>
                  <div className={styles.container_seccion}>
                    <span className={styles.text_seccion}>Sección</span>
                    <p className={styles.parrafo_seccion}>
                      {
                        "El plan antitabaco de Sanidad 'triunfa' entre los sanitarios"
                      }
                    </p>
                    <p className={styles.subparrafo_seccion}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>

                {/* <div className={styles.container_image_relacionada}>
            <Image src={publicidad_dos} />
          </div> */}
              </Col>
            </Row>
          </div>
          <hr className={styles.hr_spacing} />
        </Col>
      </Row>
    </>
  );
};

export default Related;
