import api from "../../helpers/api";

const authServices = {
  login: (email: string, password: string, remember?: boolean) => {
    return api.post(
      "/auth/login", { email, password }
    );
  },
  singUp: (data: any) => {
    return api.post("/auth/signUp", {
      data,
    });
  },
  update: (data: any) => {
    return api.put("/auth/update", {
      data,
    });
  },
  logout: () => {
    return api.get("/auth/logout");
  },
  requestRecovery: (email: string) => {
    return api.post("/auth/forgot_password", {
      email,
    });
  },
  updatePassword: (token: string, password: string) => {
    return api.put(`/auth/update_password/${token}`, {
      password,
    });
  },
  getAllActive: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get(`/auth/getAllActive`, {
      params: {
        page,
        per_page,
      },
    });
  },
};

export default authServices;
