import React from "react";
import { Collapse, Select, Form, Button, Switch, Row, Col, Input, Upload, Typography } from "antd";
import styles from './styles.module.scss';
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { SocialHeader } from "./models";

export default function HeaderSocial({ social, onSwitch }: SocialHeader) {
    return (
        <Row style={{ width: '100%' }}>
            <Col span={12}>
                <p>
                    {social.title}
                </p>
            </Col>
            <Col span={12}>
                <Row justify="end" style={{ width: '100%' }}>
                    <Col span={3} pull={1} onClick={(e) => e.stopPropagation()}>
                        <Switch
                            checked={social.enabled}
                            size="small"
                            onChange={(checked) => {
                                onSwitch(social.id, checked);
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}