import socialConstans from "./constans";
const { SET_SOCIAL, LOADING, SET_ERROR } = socialConstans;

export const initialState = {
    socials: [],
    loading: false,
    error: null
};

const socialsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_SOCIAL:
            return {
                ...state,
                socials: action.socials,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        default:
            return state;
    }
};

export default socialsReducer;