import { showAlertError } from "../../../components/Alerts";
import sectionConstants from "./constants";
import sectionServices from "./services";
import authServices from "./services";

const {
  SET_SECTION,
  SET_SECTIONS,
  UPDATE_SECTION,
  DELETE_SECTION,
  LOADING,
  SET_ERROR,
} = sectionConstants;

export const setSection = (section: any) => ({
  type: SET_SECTION,
  section,
});

export const setSections = (sections: any) => ({
  type: SET_SECTIONS,
  sections,
});

export const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

export const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

export const getSections = (dataPage?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));

    try {
      const response = await sectionServices.getAll(
        dataPage?.page,
        dataPage?.per_page
      );
      const { response: res } = response.data;
      dispatch(setSections(res));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const getSectionsWithOutType = (
  dataPage?: any,
  callback?: any,
  err?: any
) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));

    try {
      const response = await sectionServices.getAllWithOutType(
        dataPage?.page,
        dataPage?.per_page
      );
      const { response: res } = response.data;
      dispatch(setSections(res));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const saveSection = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));

    try {
      const response = await sectionServices.save(data);
      if (response) dispatch(getSections());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const deleteSection = (data: string, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(true));
    try {
      const response = await sectionServices.delete(data);
      if (response) dispatch(getSections());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const updateSections = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const response = await sectionServices.update(data);
      if (response) dispatch(getSections());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};
