import aboutUsConstants from "./constants";

const { SET_ABOUT_US, UPDATE_ABOUT_US, LOADING, SET_ERROR } = aboutUsConstants;

export const initialState = {
  aboutUs: null,
};

const aboutUsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ABOUT_US:
      return {
        ...state,
        aboutUs: action.aboutUs,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default aboutUsReducer;
