import { useSelector } from "react-redux";
import { showAlertError } from "../../../components/Alerts";
import sectionServices from "../section/services";
import signatureConstants from "./constants";
import newsConstants from "./constants";
import signatureServices from "./services";
import newsServices from "./services";

const { SET_SIGNATURE, SET_SIGNATURES, LOADING, SET_ERROR } =
  signatureConstants;

export const setSignature = (signature: any) => ({
  type: SET_SIGNATURE,
  signature,
});

const setSignatures = (signatures: any) => ({
  type: SET_SIGNATURES,
  signatures,
});

const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

export const saveSignature = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
      const formData = new FormData();

      Object.keys(data).forEach((d) => {
        formData.append(d, data[d]);
      });

      const response = await signatureServices.save(formData);
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const getSignatures = (dataPage?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await signatureServices.getAllActives(
        dataPage?.page,
        dataPage?.per_page
      );

      dispatch(setSignatures(response.data));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const deleteSignature = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(true));
    try {
      const response = await signatureServices.delete(data);
      if (response) dispatch(getSignatures());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      dispatch(setLoading(false));
      if (err) err();
    }
  };
};

export const editSignature = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(true));
    try {
      const formData = new FormData();

      Object.keys(data).forEach((d) => {
        formData.append(d, data[d]);
      });

      const response = await signatureServices.update(formData);
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      dispatch(setLoading(false));
      if (err) err();
    }
  };
};
