import { Col, Divider, Row } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

import { imgNoExist } from "../../../helpers/img";

const moduleEighteen = ({ block }: any) => {
  console.log(block);

  return (
    <>
      <div className={styles.spacingModule}>
        <Layout>
          <div className={styles.container_bg}>
            <Content className={styles.container_1060}>
            {block?.title_active && (
                <>
                  <span
                    className={styles.title_module}
                    style={{ color: block?.color || "#103783" }}
                  >
                    {block?.title || "Modulo 8"}
                  </span>
                  <div className={styles.line_section}></div>
                </>
              )}

              <Row gutter={24}>
                <Col xs={12} sm={12} md={4} >
                  <div
                    className={styles.news_img}
                    style={{
                      backgroundImage: `url(${
                        block?.new1?.imgBanner ||
                        block?.new1?.imgVideo ||
                        imgNoExist
                      })`,
                    }}
                  />
                  <div className={styles.container_span}>
                    <span className={styles.text_section}>
                      {block?.new1?.section?.title || `section`}
                    </span>
                  </div>
                  <p className={styles.title}>
                    {block?.new1?.title || `Titulo ejemplo`}
                  </p>
                  
                </Col>
                
                <Col xs={12} sm={12}md={4}>
                  <div
                    className={styles.news_img}
                    style={{
                      backgroundImage: `url(${
                        block?.new2?.imgBanner ||
                        block?.new2?.imgVideo ||
                        imgNoExist
                      })`,
                    }}
                  />
                  <div className={styles.container_span}>
                    <span className={styles.text_section}>
                      {block?.new2?.section?.title || `section`}
                    </span>
                  </div>
                  <p className={styles.title}>
                    {block?.new2?.title || `Titulo ejemplo`}
                  </p>
                </Col>
                
                <Col xs={12} sm={12}md={4}>
                <div
                    className={styles.news_img}
                    style={{
                      backgroundImage: `url(${
                        block?.new3?.imgBanner ||
                        block?.new3?.imgVideo ||
                        imgNoExist
                      })`,
                    }}
                  />
                  <div className={styles.container_span}>
                    <span className={styles.text_section}>
                      {block?.new3?.section?.title || `section`}
                    </span>
                  </div>
                  <p className={styles.title}>
                    {block?.new3?.title || `Titulo ejemplo`}
                  </p>
                </Col>
                <Col xs={12} sm={12}md={4}>
                <div
                    className={styles.news_img}
                    style={{
                      backgroundImage: `url(${
                        block?.new4?.imgBanner ||
                        block?.new4?.imgVideo ||
                        imgNoExist
                      })`,
                    }}
                  />
                  <div className={styles.container_span}>
                    <span className={styles.text_section}>
                      {block?.new4?.section?.title || `section`}
                    </span>
                  </div>
                  <p className={styles.title}>
                    {block?.new4?.title || `Titulo ejemplo`}
                  </p>
                </Col>
                <Col xs={12} sm={12}md={4}>
                <div
                    className={styles.news_img}
                    style={{
                      backgroundImage: `url(${
                        block?.new5?.imgBanner ||
                        block?.new5?.imgVideo ||
                        imgNoExist
                      })`,
                    }}
                  />
                  <div className={styles.container_span}>
                    <span className={styles.text_section}>
                      {block?.new5?.section?.title || `section`}
                    </span>
                  </div>
                  <p className={styles.title}>
                    {block?.new5?.title || `Titulo ejemplo`}
                  </p>
                </Col>
                <Col xs={12} sm={12}md={4}>
                <div
                    className={styles.news_img}
                    style={{
                      backgroundImage: `url(${
                        block?.new6?.imgBanner ||
                        block?.new6?.imgVideo ||
                        imgNoExist
                      })`,
                    }}
                  />
                  <div className={styles.container_span}>
                    <span className={styles.text_section}>
                      {block?.new6?.section?.title || `section`}
                    </span>
                  </div>
                  <p className={styles.title}>
                    {block?.new6?.title || `Titulo ejemplo`}
                  </p>
                </Col>
              </Row>
            </Content>
          </div>
        </Layout>
      </div>
    </>
  );
};

export default moduleEighteen;
