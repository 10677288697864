import api from "../../../helpers/api";

const blockServices = {
  getAll: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get("/block/getAll", {
      params: {
        page,
        per_page,
      },
    });
  },
  update: (data: any) => {
    return api.put("/block/update", {
      data,
    });
  },
  updatePositionBlocks: (data: any) => {
    return api.put("/block/updatePositionBlocks", {
      data,
    });
  },
  save: (data: any) => {
    return api.post("/block/save", {
      data,
    });
  },
};

export default blockServices;
