import { Input, Button, Form, Pagination, Table, Upload, Avatar } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import trash from "../../../assets/img/papelera.svg";
import edit from "../../../assets/img/editar.svg";
import Modal from "antd/lib/modal/Modal";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
} from "../../../components/Alerts";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSignature,
  editSignature,
  getSignatures,
  saveSignature,
  setSignature,
} from "../../../redux/dashboard/signature/action";
import moment from "moment";
import "moment/locale/es";

const Signature = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileListCreate, setFileListCreate] = useState<any>([]);
  const [fileListEdit, setFileListEdit] = useState<any>([]);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const { signatures, signature } = useSelector(
    (state: any) => state.signature
  );
  const [dataPage, setDataPage] = useState<any>({
    page: 1,
    per_page: 15,
  });

  const [formCreate] = Form.useForm();
  const [formEdit] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSignatures(dataPage));
  }, [dataPage]);

  const showTotal = (from: number, to: number, total: number) => {
    return `${from} - ${to} de ${total}`;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalEdit = (data: any) => {
    setIsModalVisibleEdit(true);
    dispatch(setSignature(data));
    formEdit.setFieldsValue({ id: data.id, fullName: data.fullName });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelEdit = () => {
    setIsModalVisibleEdit(false);
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Fotografía",
      render: (row: any) => (
        <>
          <Avatar size={64} icon={<UserOutlined />} src={row.img} />
        </>
      ),
    },
    {
      title: "Fecha de creación",
      render: (row: any) => moment(row.createdAt).format("L"),
    },
    {
      title: "Opciones",
      key: "action",
      render: (row: any) => (
        <>
          <img
            src={trash}
            onClick={() =>
              showAlertQuestion(
                "¿Estás seguro que desea eliminar la firma actual?",
                "Eliminar",
                () => {
                  dispatch(
                    deleteSignature(
                      row,
                      () => {
                        showAlertSucces(
                          "Firma eliminada",
                          "La firma actual ha sido eliminada correctamente."
                        );
                      },
                      () => {
                        showAlertError(
                          "Ha ocurrido un error",
                          "No se ha podido completar correctamente la acción, intentalo nuevamente."
                        );
                      }
                    )
                  );
                }
              )
            }
            className={styles.icons_table}
          />
          <img
            src={edit}
            onClick={() => showModalEdit(row)}
            className={styles.icons_table}
          />
        </>
      ),
    },
  ];

  /* onFinish */
  const onFinishCreate = (value: any) => {
    showAlertQuestion(
      "¿Estás seguro que desea crear una nueva firma?",
      "Crear",
      () => {
        value.imgSignature = value.imgSignature[0].originFileObj;
        dispatch(
          saveSignature(
            value,
            () => {
              formCreate.resetFields();
              setFileListCreate([]);
              setIsModalVisible(false);
              dispatch(getSignatures(dataPage));
              showAlertSucces(
                "¡Firma creada!",
                "La firma ha sido creada correctamente"
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  const onFinishEdit = (value: any) => {
    showAlertQuestion(
      "¿Estás seguro que desea los cambios realizados a la firma?",
      "Guardar",
      () => {
        if (!value.imgSignature || value.imgSignature === null) {
          delete value.imgSignature;
        } else {
          value.imgSignature = value.imgSignature[0].originFileObj;
        }

        dispatch(
          editSignature(
            { id: signature.id, ...value },
            () => {
              formEdit.resetFields();
              setFileListEdit([]);
              setIsModalVisibleEdit(false);
              dispatch(getSignatures(dataPage));
              showAlertSucces(
                "Firma actualizada",
                "La firma ha sido actualizada correctamente"
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  /* upload */

  const onPreview = async (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (isJpgOrPng) {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow: any = window.open(src);
      imgWindow.document.write(image.outerHTML);
    }
  };

  function beforeUpload(file: { type: string; size: number }) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      showAlertError(
        "Ha ocurrido un error",
        "No se ha podido cargar correctamente el archivo, intentalo nuevamente."
      );
    }
    const isLt2M = file.size / 640 / 640 < 2;
    if (!isLt2M) {
      showAlertError("Ha ocurrido un error", "Solo permite archivos de 2MB.");
    }
    return false;
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  /* size table */
  const divHeight = useRef<any>();
  const divOptionsHeight = useRef<any>();
  const [height, setHeight] = useState<any>(100);
  const [optionsHeight, setOptionsHeight] = useState<any>(32);

  useEffect(() => {
    setHeight(divHeight.current.clientHeight);
    setOptionsHeight(divOptionsHeight.current.clientHeight);
  }, []);

  window.addEventListener("resize", (v: any) => {
    setHeight(divHeight.current.clientHeight);
    setOptionsHeight(divOptionsHeight.current.clientHeight);
  });

  return (
    <>
      <div className={styles.container_signature} ref={divHeight}>
        {/* <div className={styles.line}></div> */}
        <div className={styles.container_header_search} ref={divOptionsHeight}>
          <div>
            <Button
              onClick={showModal}
              htmlType="submit"
              className={styles.button_signature}
              block
            >
              Crear nueva firma
            </Button>
          </div>
          <div className={styles.container_search}>
            <div className={styles.container_options}>
              <span className={styles.text_search}>Buscar:</span>
              <Form>
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input_search}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form>
              <Button htmlType="submit" className={styles.search_button} block>
                Buscar
              </Button>
            </div>
            <Pagination
              className={styles.pagination}
              size="small"
              showTotal={() =>
                showTotal(signatures?.from, signatures?.to, signatures?.total)
              }
              defaultCurrent={signatures?.current_page}
              defaultPageSize={signatures?.per_page}
              pageSize={signatures?.per_page || 15}
              total={signatures?.total}
              onChange={(page) => setDataPage({ ...dataPage, page })}
              current={signatures?.current_page || 1}
            />
          </div>
        </div>
        <div
          className={styles.container_table}
          style={{
            height: `${height - optionsHeight}px`,
            overflowY: "auto",
          }}
        >
          <Table
            columns={columns}
            dataSource={signatures?.data}
            pagination={false}
            className={styles.information_table}
          />
        </div>
        <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
          <div className={styles.container_content_modal}>
            <span className={styles.title_table}>Nueva firma</span>
            <span className={styles.text_table}>Nombre</span>
            <Form form={formCreate}>
              <Form.Item
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un nombre",
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <div className={styles.container_upload}>
              <Form form={formCreate}>
                <Form.Item>
                  <div className={styles.container_upload}>
                    <Form.Item
                      name="imgSignature"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: true,
                          message: "La foto es requerida",
                        },
                      ]}
                    >
                      <Upload
                        accept={".png, .jpg, .jpeg"}
                        listType="picture-card"
                        onPreview={onPreview}
                        beforeUpload={beforeUpload}
                        onChange={({ fileList: newFileList }: any) =>
                          setFileListCreate(newFileList)
                        }
                      >
                        {fileListCreate.length === 1 ? null : "+ Upload"}
                      </Upload>
                    </Form.Item>
                    <span className={styles.span_text}>
                      Recommanded resolution is 1280*720 with file size less
                      than 2MB, keep visual elements centered
                    </span>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div className={styles.container_button}>
              <Form form={formCreate} layout="inline" onFinish={onFinishCreate}>
                <Form.Item>
                  <Button className={styles.save_button} htmlType="submit">
                    Guardar
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    onClick={handleCancel}
                    className={styles.cancel_button}
                    block
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>

        <Modal
          visible={isModalVisibleEdit}
          footer={null}
          onCancel={handleCancelEdit}
        >
          <div className={styles.container_content_modal}>
            <span className={styles.title_table}>Editar firma</span>
            <span className={styles.text_table}>Nombre</span>
            <Form form={formEdit}>
              <Form.Item
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Por favor escriba un nombre",
                  },
                ]}
              >
                <Input
                  placeholder="Escribe aquí"
                  className={styles.input}
                  style={{ color: "#ababab" }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
            <div className={styles.container_upload}>
              <Form form={formEdit}>
                <Form.Item>
                  <div className={styles.container_upload}>
                    <Form.Item
                      name="imgSignature"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        accept={".png, .jpg, .jpeg"}
                        listType="picture-card"
                        onPreview={onPreview}
                        beforeUpload={beforeUpload}
                        onChange={({ fileList: newFileList }: any) =>
                          setFileListEdit(newFileList)
                        }
                      >
                        {fileListEdit.length === 1 ? null : "+ Upload"}
                      </Upload>
                    </Form.Item>
                    <span className={styles.span_text}>
                      Recommanded resolution is 1280*720 with file size less
                      than 2MB, keep visual elements centered
                    </span>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div className={styles.container_button}>
              <Form form={formEdit} layout="inline" onFinish={onFinishEdit}>
                <Form.Item>
                  <Button className={styles.save_button} htmlType="submit">
                    Guardar
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    onClick={handleCancelEdit}
                    className={styles.cancel_button}
                    block
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Signature;
