import React from "react";
import "./styles.scss";
import ClockLoader from "react-spinners/ClockLoader";

const Spinner = () => {
  return (
    <div className="container">
      <ClockLoader color={"#79b48f"} loading={true} size={100} />

      {/* <div className="looping-rhombuses-spinner">
        <div className="rhombus"></div>
        <div className="rhombus"></div>
        <div className="rhombus"></div>
      </div> */}
    </div>
  );
};

export default Spinner;
