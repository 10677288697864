import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Row, Col, Card } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { imgNoExist } from "../../../helpers/img";
import moment from "moment";
import "moment/locale/es";
import api from "../../../helpers/api";

const ModuleNineteen = ({ block }: any) => {
  //console.log(block);

  const [announcer1, setAnnouncer1] = useState<any>();
  const [anouncr1News, setAnouncr1News] = useState<any>();

  const [announcer2, setAnnouncer2] = useState<any>();
  const [anouncr2News, setAnouncr2News] = useState<any>();
  useEffect(() => {
    console.log(block);
    const getNews = async (anuncrSlug: any, index: any) => {
      try {
        const res = await api.get(`/news/getSpecificWithSlug`, {
          params: {
            slug: anuncrSlug,
          },
        });
        if (!res?.data) {
          return null;
        } else {
          //console.log(res.data);
          if (res.data.announcer) {
            if (index === 1) {
              //console.log("anuncer 1");
              getAnnouncer(res.data.announcer, 1);
              //setAnouncr1News(res.data);
            } else {
              getAnnouncer(res.data.announcer, 2);
              //setAnouncr2News(res.data);
            }
          } else {
            if (index === 1) {
              //console.log("anuncer 1");
              setAnnouncer1(null);
              //setAnouncr1News(res.data);
            } else {
              setAnnouncer2(null);
              //setAnouncr2News(res.data);
            }
          }

          return res.data;
        }
      } catch (err) {
        console.log("err: ", err);
        return null;
      }
    };
    const getAnnouncer = async (id: any, index: any) => {
      await api
        .get(`/announcer/getAnnouncer`, {
          params: {
            brand: id,
          },
        })
        .then((res: any) => {
          if (!res?.data) {
            return null;
          } else {
            //console.log(res.data[0]);
            if (index === 1) {
              setAnnouncer1(res.data[0]);
            } else {
              //console.log("annunciante 2");

              setAnnouncer2(res.data[0]);
            }
            return res.data[0];
          }
        })
        .catch((err: any) => {
          console.log("err: ", err);
        });
    };
    if (block) {
      getNews(block?.new2?.slug, 1);
      getNews(block?.new3?.slug, 2);
    }
  }, [block]);
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1060}>
            {block?.title_active && (
              <>
                <span
                  className={styles.title_module}
                  style={{ color: block?.color || "#103783" }}
                >
                  {block?.title || "Modulo 8"}
                </span>
                <div className={styles.line_section}></div>
              </>
            )}

            <Row gutter={[26, 0]}>
              <Col span={16}>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <div
                      style={{
                        backgroundImage: `url(${
                          block?.new1?.imgBanner ||
                          block?.new1?.imgVideo ||
                          imgNoExist
                        })`,
                        minHeight: "25.67rem",
                      }}
                      className={styles.container_video}
                    ></div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <div className={styles.container_span}>
                        <span className={styles.text_section}>
                          {block?.new1?.section?.title || `section`}
                        </span>
                        <span className={styles.reading_section}>
                          {moment(block?.new1?.createdAt)
                            .locale("es")
                            .startOf("day")
                            .fromNow() || `Tiempo prueba`}
                        </span>
                      </div>
                      <p className={styles.title}>
                        {block?.new1?.title || `Titulo ejemplo`}
                      </p>
                      <p className={styles.subtitle}>
                        {block?.new1?.subTitle || `Subtitulo ejemplo`}
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className={styles.line_section}></div>

                {announcer1 ? (
                  <Row>
                    <Col span={12}>
                      <div
                        style={{
                          backgroundImage: `url(${
                            block?.new2?.imgBanner ||
                            block?.new2?.imgVideo ||
                            imgNoExist
                          })`,
                          minHeight: "16.167rem",
                        }}
                        className={styles.container_video}
                      ></div>
                    </Col>
                    <Col span={12}>
                      <div
                        style={{
                          backgroundColor: announcer1.brandColor || "red",
                          width: "50%",
                          height: "20%",
                          borderBottomRightRadius: "20px",
                          borderTopRightRadius: "20px",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: announcer1.brandColor || "red",
                          width: "100%",
                          height: "80%",
                          borderBottomRightRadius: "6px",
                          borderTopRightRadius: "6px",
                        }}
                      >
                        <div className={styles.container_card_text}>
                          <p
                            className={styles.title_card_announcer1}
                            style={{ color: announcer1.textColor || "#ffffff" }}
                          >
                            {block?.new2?.title || `Titulo ejemplo`}
                          </p>
                          <p
                            className={styles.text_card_announcer1}
                            style={{ color: announcer1.textColor || "#ffffff" }}
                          >
                            {block?.new2?.subTitle || `Subtitulo ejemplo`}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={12}>
                      <div
                        style={{
                          backgroundImage: `url(${
                            block?.new2?.imgBanner ||
                            block?.new2?.imgVideo ||
                            imgNoExist
                          })`,
                          minHeight: "16.167rem",
                        }}
                        className={styles.container_video}
                      ></div>
                    </Col>
                    <Col span={12}>
                      <div
                        style={{
                          backgroundColor: "red",
                          width: "50%",
                          height: "20%",
                          borderBottomRightRadius: "20px",
                          borderTopRightRadius: "20px",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: "red",
                          width: "100%",
                          height: "80%",
                          borderBottomRightRadius: "6px",
                          borderTopRightRadius: "6px",
                        }}
                      >
                        <div className={styles.container_card_text}>
                          <p
                            className={styles.title_card_announcer1}
                            style={{ color: "#ff ffff" }}
                          >
                            {block?.new2?.title || `Titulo ejemplo`}
                          </p>
                          <p
                            className={styles.text_card_announcer1}
                            style={{ color: "#ffffff" }}
                          >
                            {block?.new2?.subTitle || `Subtitulo ejemplo`}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col span={8}>
                {announcer2 ? (
                  <Card
                    className={styles.container_card}
                    style={{backgroundColor:announcer2.brandColor||"red"}}
                    cover={
                      <img
                        src={
                          block?.new3?.imgBanner ||
                          block?.new3?.imgVideo ||
                          imgNoExist
                        }
                        alt=""
                      />
                    }
                  >
                    <div className={styles.container_card_text}>
                      <p className={styles.title_card} style={{color:announcer2.textColor||"#ffffff"}}>
                        {block?.new3?.title || `Titulo ejemplo`}
                      </p>
                      <p className={styles.text_card} style={{color:announcer2.textColor||"#ffffff"}}>
                        {block?.new3?.subTitle || `Subtitulo ejemplo`}
                      </p>
                    </div>
                  </Card>
                ) : (
                  <Card
                    className={styles.container_card}
                    
                    cover={
                      <img
                        src={
                          block?.new3?.imgBanner ||
                          block?.new3?.imgVideo ||
                          imgNoExist
                        }
                        alt=""
                      />
                    }
                  >
                    <div className={styles.container_card_text}>
                      <p className={styles.title_card}>
                        {block?.new3?.title || `Titulo ejemplo`}
                      </p>
                      <p className={styles.text_card}>
                        {block?.new3?.subTitle || `Subtitulo ejemplo`}
                      </p>
                    </div>
                  </Card>
                )}

                <div className={styles.line_section}></div>
                <div>
                  <div className={styles.container_span}>
                    <span className={styles.text_section}>
                      {block?.new4?.section?.title || `section`}
                    </span>
                    <span className={styles.reading_section}>
                      {moment(block?.new4?.createdAt)
                        .locale("es")
                        .startOf("day")
                        .fromNow() || `Tiempo prueba`}
                    </span>
                  </div>
                  <p className={styles.title} style={{ fontSize: "16px" }}>
                    {block?.new4?.title || `Titulo ejemplo`}
                  </p>
                </div>
              </Col>
            </Row>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleNineteen;
