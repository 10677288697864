import aboutUsConstants from "./constants";
import aboutUsServices from "./services";

const { SET_ABOUT_US, UPDATE_ABOUT_US, LOADING, SET_ERROR } = aboutUsConstants;

const setAboutUs = (aboutUs: any) => ({
  type: SET_ABOUT_US,
  aboutUs,
});

const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

export const getAboutUs = (dataPage?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await aboutUsServices.getAll(
        dataPage?.page,
        dataPage?.per_page
      );
      dispatch(setAboutUs(response.data.response));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const saveAboutUs = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    try {
      console.log("Action Redux: ", data);
      const response = await aboutUsServices.save(data);
      if (response) dispatch(getAboutUs());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const updateAboutUs = (data?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
      const formData = new FormData();
      Object.keys(data).forEach((d) => {
        formData.append(d, data[d]);
      });
      const reponse = await aboutUsServices.update(formData);
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};
