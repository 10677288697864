import { showAlertError } from "../../../components/Alerts";
import sectionServices from "../section/services";
import blockConstants from "./constants";
import blockServices from "./services";

const { SET_BLOCK, SET_BLOCKS, LOADING, SET_ERROR, SET_SECTIONS } =
  blockConstants;

export const setBlock = (block: any) => ({
  type: SET_BLOCK,
  block,
});

export const setBlocks = (blocks: any) => ({
  type: SET_BLOCKS,
  blocks,
});

const setSections = (sections: any) => ({
  type: SET_SECTIONS,
  sections,
});

export const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

export const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

const getSections = (dataPage?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));

    try {
      const response = await sectionServices.getAll(
        dataPage?.page,
        dataPage?.per_page
      );
      const { response: res } = response.data;
      dispatch(setSections(res));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const getBlocks = (dataPage?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));

    try {
      const response = await blockServices.getAll(
        dataPage?.page,
        dataPage?.per_page
      );
      const { response: res } = response.data;
      if (res) {
        dispatch(setBlocks(res));
        dispatch(getSections({ page: 1, per_page: 999 }));
      }

      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const updateBlock = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const response = await blockServices.update(data);
      if (response) dispatch(getBlocks({ page: 1, per_page: 999 }));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const updatePositionBlocks = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const response = await blockServices.updatePositionBlocks(data);
      if (response) dispatch(getBlocks({ page: 1, per_page: 999 }));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const createBlock = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const response = await blockServices.save(data);
      if (response) dispatch(getBlocks({ page: 1, per_page: 999 }));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};
