import React from "react";
import { Col, Row, Card } from "antd";
import styles from "./styles.module.scss";
import Layout, { Content } from "antd/lib/layout/layout";
import { imgNoExist } from "../../../helpers/img";
import moment from "moment";
import "moment/locale/es";

const ModuleFour = ({ block }: any) => {
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1060}>
            {block?.title_active && (
              <>
                <span
                  className={styles.title_module}
                  style={{ color: block?.color || "#103783" }}
                >
                  {block?.title || "Modulo 4"}
                </span>
                <div className={styles.line_section}></div>
              </>
            )}
            <div className={styles.container_block_one}>
              <Row gutter={[20, 0]}>
                <Col span={6}>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url(${
                          block?.new2?.imgBanner || imgNoExist
                        })`,
                      }}
                      className={styles.container_small}
                    ></div>
                    <div className={styles.container_span}>
                      <span className={styles.text_section}>
                        {block?.new2?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_section}>
                        {moment(block?.new2?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.description_title}>
                      {block?.new2?.title || `Titulo ejemplo`}
                    </p>
                    <p className={styles.description_subtitle}>
                      {block?.new2?.subTitle || `Sub titulo ejemplo`}
                    </p>
                  </div>

                  <div className={styles.line_section}></div>

                  <div>
                    <div className={styles.container_span}>
                      <span className={styles.text_section}>
                        {block?.new3?.section?.title || `section`}
                      </span>
                      <span className={styles.reading_section}>
                        {moment(block?.new3?.createdAt)
                          .locale("es")
                          .startOf("day")
                          .fromNow() || `Tiempo prueba`}
                      </span>
                    </div>
                    <p className={styles.description_title}>
                      {block?.new3?.title || `Titulo ejemplo`}
                    </p>
                  </div>
                </Col>

                <Col span={18}>
                  <div
                    style={{
                      backgroundImage: `url(${
                        block?.new1?.imgBanner || imgNoExist
                      })`,
                    }}
                    className={styles.container_big_img}
                  >
                    <div>
                      <div className={styles.container_span}>
                        <span className={styles.text_main}>
                          {block?.new1?.section?.title || `section`}
                        </span>
                        <span className={styles.reading_main}>
                          {moment(block?.new1?.createdAt)
                            .locale("es")
                            .startOf("day")
                            .fromNow() || `Tiempo prueba`}
                        </span>
                      </div>
                      <p className={styles.title_main}>
                        {block?.new1?.title || `Titulo ejemplo`}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row gutter={[23, 0]}>
                <Col span={8}>
                  <Card
                    className={styles.container_card}
                    cover={
                      <img src={block?.new4?.imgBanner || imgNoExist} alt="" />
                    }
                  >
                    <div className={styles.container_text_card}>
                      <div className={styles.container_span}>
                        <span className={styles.reading_section}>
                          {moment(block?.new4?.createdAt)
                            .locale("es")
                            .startOf("day")
                            .fromNow() || `Tiempo prueba`}
                        </span>
                      </div>
                      <p className={styles.title_card}>
                        {block?.new4?.title || `Titulo ejemplo`}
                      </p>
                      <p className={styles.text_card}>
                        {block?.new4?.subTitle || `Sub titulo ejemplo`}
                      </p>
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    className={styles.container_card}
                    cover={
                      <img src={block?.new5?.imgBanner || imgNoExist} alt="" />
                    }
                  >
                    <div className={styles.container_text_card}>
                      <div className={styles.container_span}>
                        <span className={styles.reading_section}>
                          {moment(block?.new5?.createdAt)
                            .locale("es")
                            .startOf("day")
                            .fromNow() || `Tiempo prueba`}
                        </span>
                      </div>
                      <p className={styles.title_card}>
                        {block?.new5?.title || `Titulo ejemplo`}
                      </p>
                      <p className={styles.text_card}>
                        {block?.new5?.subTitle || `Sub titulo ejemplo`}
                      </p>
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    className={styles.container_card}
                    cover={
                      <img src={block?.new6?.imgBanner || imgNoExist} alt="" />
                    }
                  >
                    <div className={styles.container_text_card}>
                      <div className={styles.container_span}>
                        <span className={styles.reading_section}>
                          {moment(block?.new6?.createdAt)
                            .locale("es")
                            .startOf("day")
                            .fromNow() || `Tiempo prueba`}
                        </span>
                      </div>
                      <p className={styles.title_card}>
                        {block?.new6?.title || `Titulo ejemplo`}
                      </p>
                      <p className={styles.text_card}>
                        {block?.new6?.subTitle || `Sub titulo ejemplo`}
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleFour;
