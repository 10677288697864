import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import Menu from "../../../../components/Menu";
import { Collapse, Select, Form, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
} from "../../../../components/Alerts";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlaces,
  updatePlaces,
} from "../../../../redux/dashboard/barMenu/action";
import { getSections } from "../../../../redux/dashboard/section/action";

const MenuBar = () => {
  const { Panel } = Collapse;
  const { Option } = Select;

  const dispatch = useDispatch();
  const { places } = useSelector((state: any) => state.barMenu);
  const { sections } = useSelector((state: any) => state.sections);

  const handleCancel = () => {
    showAlertSucces("Menú Bar", "La edicion del menú bar ha sido cancelada");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    dispatch(getSections({ page: 1, per_page: 999 }));
    dispatch(getPlaces());
  }, []);

  const onFinish = (value: any, id: string) => {
    showAlertQuestion(
      "¿Estás seguro que desea guardar los cambios?",
      "Guardar",
      () => {
        dispatch(
          updatePlaces(
            { ...value, id },
            () => {
              showAlertSucces(
                "¡Espacio editado!",
                "El espacio ha sido editado correctamente."
              );
              window.location.reload();
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  return (
    <>
      <Menu>
        <div>
          <Collapse
            expandIcon={() => <EditOutlined />}
            expandIconPosition="right"
            bordered={false}
          >
            {places &&
              places.map((place: any, index: any) => (
                <Panel header={`Espacio ${index + 1}`} key={place.id}>
                  <Form
                    onFinish={(e) => onFinish(e, place.id)}
                    initialValues={{ section: place?.section?.id }}
                  >
                    <Form.Item name="section">
                      <Select
                        placeholder="Elegir sección"
                        className={styles.select_seccion}
                      >
                        <Option default className={styles.option_select}>
                          Elegir sección
                        </Option>
                        {sections?.data &&
                          sections.data.map((section: any) => (
                            <Option
                              key={section.id}
                              className={styles.option_select}
                              value={section.id}
                            >
                              {section.title || "section"}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <div className={styles.container_button_menu}>
                      <Button
                        className={styles.save_button}
                        block
                        htmlType="submit"
                      >
                        Guardar
                      </Button>
                      <Button
                        className={styles.cancel_button}
                        block
                        onClick={handleCancel}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </Form>
                </Panel>
              ))}
          </Collapse>
        </div>
      </Menu>
    </>
  );
};

export default MenuBar;
