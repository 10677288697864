import {
  Button,
  Card,
  Col,
  Collapse,
  Input,
  Popover,
  Progress,
  Row,
  Upload,
  Form,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import Menu from "../../../../components/Menu";
import {
  getThermometer,
  setThermNews,
  setThermometer,
} from "../../../../redux/dashboard/thermometer/action";
import ModuleThirteen from "../../../../components/Moduls/Module-thirteen/module_thirteen";
import Layout, { Content } from "antd/lib/layout/layout";
import { imgNoExist } from "../../../../helpers/img";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
} from "../../../../components/Alerts";
import { Field } from "rc-field-form";
import { formatStrategyValues } from "rc-tree-select/lib/utils/strategyUtil";
import { off } from "process";

const Thermometer = () => {
  const [formThermEdit] = Form.useForm();
  const [formThermEdit2] = Form.useForm();
  const [formThermEdit3] = Form.useForm();
  const [fileList, setFileList] = useState<any>([]);
  const therForms = [formThermEdit, formThermEdit2, formThermEdit3];
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const [newsImages, setNewsImages] = useState<any>([
    { img: "" },
    { img: "" },
    { img: "" },
  ]);
  const { thermometer } = useSelector((state: any) => state.thermometer);

  //----------------------------Upload image------------------------
  const onPreview = async (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (isJpgOrPng) {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow: any = window.open(src);
      imgWindow.document.write(image.outerHTML);
    }
  };
  function beforeUpload(file: { type: string; size: number }) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      showAlertError(
        "Ha ocurrido un error",
        "No se ha podido cargar correctamente el archivo, intentalo nuevamente."
      );
    }
    const isLt2M = file.size / 640 / 640 < 2;
    if (!isLt2M) {
      showAlertError("Ha ocurrido un error", "Solo permite archivos de 2MB.");
    }
    return false;
  }

  useEffect(() => {
    dispatch(getThermometer());
  }, []);

  //----------------------------Forms edit functions -------------------------------
  const onFinishTherEdit = (value: any) => {
    showAlertQuestion(
      "¿Estás seguro que desea los cambios realizados en el termómetro?",
      "Guardar",
      () => {
        dispatch(
          setThermNews(
            value,
            () => {
              dispatch(getThermometer());
              //therForms[value.index - 1].resetFields();
              showAlertSucces(
                "Termometro Actualizado",
                "El termometro ha sido actualizado correctamente"
              );
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  return (
    <>
      <Menu>
        <div>
          <Collapse
            expandIcon={() => <EditOutlined />}
            expandIconPosition="right"
            bordered={false}
          >
            {thermometer &&
              thermometer?.map((news: any, index: any) => (
                <Panel header={`Noticia ${index + 1}`} key={news.id}>
                  <div className={styles.container_upload}>
                    <Upload
                      accept={".png,.jpg.jpeg"}
                      listType="picture-card"
                      onPreview={onPreview}
                      fileList={fileList}
                      beforeUpload={beforeUpload}
                      onChange={({ fileList: newFileList }: any) => {
                        console.log(newFileList);
                        const newsimgcpy = [...newsImages];
                        newsimgcpy[index].img = newFileList[0];
                        setNewsImages(newsimgcpy);
                        setFileList(newFileList);
                      }}
                    >
                      {fileList.length === 1 ? null : "+ Upload"}
                    </Upload>
                    <span className={styles.span_text_upload}>
                      La resolución recomendada es de 1440*480, el archivo debe
                      pesar menos de 2MB y mantener todos los elementos
                      centrados
                    </span>
                  </div>
                  <div className={styles.text_container_ther}>
                    <span className={styles.text_ther}>Titulo</span>
                    <Form form={therForms[index]}>
                      <Form.Item name="title" initialValue={news.title}>
                        <TextArea
                          rows={2}
                          className={styles.text_area_ther}
                          onChange={(e: any) => {
                            const newscpy = [...thermometer];
                            newscpy[index].title = e.target.value;
                            //setNews(newscpy);
                            dispatch(setThermometer(newscpy));
                            console.log(thermometer);
                          }}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                  <div className={styles.text_container_ther}>
                    <span className={styles.text_ther}>Texto</span>
                    <Form form={therForms[index]}>
                      <Form.Item name="text" initialValue={news.text}>
                        <TextArea
                          rows={3}
                          className={styles.text_area_ther}
                          onChange={(e: any) => {
                            const newscpy = [...thermometer];
                            newscpy[index].text = e.target.value;
                            dispatch(setThermometer(newscpy));
                            //setNews(newscpy);
                          }}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                  <div className={styles.color_container_ther}>
                    <Popover
                      placement="bottomLeft"
                      content={
                        <div>
                          <span className={styles.text_color_sel_ther}>
                            Seleccione un color
                          </span>
                          <div className={styles.color_popover}>
                            <div
                              className={styles.color_ther}
                              style={{ backgroundColor: "#52B68F" }}
                              onClick={() => {
                                const colrs = [...thermometer];
                                colrs[index].color = "#52B68F";
                                dispatch(setThermometer(colrs));
                                //setNews(colrs);
                                console.log(colrs);
                              }}
                            />
                            <div
                              className={styles.color_ther}
                              style={{ backgroundColor: "#E84C4E" }}
                              onClick={() => {
                                console.log(news);
                                const colrs = [...thermometer];
                                colrs[index].color = "#E84C4E";
                                dispatch(setThermometer(colrs));
                                //setNews(colrs);
                              }}
                            />
                          </div>
                        </div>
                      }
                      trigger="click"
                    >
                      {thermometer[index].color === "#52B68F" ? (
                        <div
                          className={styles.color_ther}
                          style={{ backgroundColor: "#52B68F" }}
                        ></div>
                      ) : (
                        <div
                          className={styles.color_ther}
                          style={{ backgroundColor: "#E84C4E" }}
                        ></div>
                      )}
                    </Popover>
                    <span className={styles.text_color_ther}>
                      Cambiar de color
                    </span>
                  </div>

                  <div>
                    <Form
                      form={therForms[index]}
                      onFinish={(value: any) => {
                        value.id = index + 1;
                        value.color = news.color;
                        if (newsImages[index].img !== "") {
                          value.img = newsImages[index].img;
                          const newscpy = [...thermometer];
                          newscpy[index].img = newsImages[index].img.thumbUrl;
                          dispatch(setThermometer(newscpy));
                        }
                        /*console.log(value);
                        console.log(therForms);*/
                        onFinishTherEdit(value);
                      }}
                      layout="inline"
                    >
                      <Form.Item>
                        <Button
                          className={styles.save_button}
                          block
                          htmlType="submit"
                        >
                          Guardar
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button className={styles.cancel_button} block>
                          Cancelar
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </Panel>
              ))}
          </Collapse>
        </div>
      </Menu>
      {/* Thermometer Preview */}
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1060}>
            <div className={styles.container_module}>
              <div className={styles.container_line}>
                <div>
                  {thermometer && (
                    <div className={styles.therm}>
                      <div
                        style={{ backgroundColor: thermometer[0].color }}
                        className={styles.therm1}
                      />
                      <div
                        style={{ backgroundColor: thermometer[1].color }}
                        className={styles.therm1}
                      />
                      <div
                        style={{ backgroundColor: thermometer[2].color }}
                        className={styles.therm1}
                      />
                    </div>
                  )}
                </div>
              </div>
              <Row gutter={[39, 0]}>
                {thermometer &&
                  thermometer?.map((news: any, index: any) => (
                    <Col span={8} key={index}>
                      <Card
                        className={styles.container_card}
                        cover={
                          <img src={news.img.thumbUrl || news.img} alt="" />
                        }
                      >
                        <div>
                          <p className={styles.title_card}>{news.title}</p>
                          <p className={styles.subtitle_card}>{news.text}</p>
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default Thermometer;
