import api from "../../../helpers/api";

const newsServices = {
  getAll: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get("/news/getAll", {
      params: {
        page,
        per_page,
      },
    });
  },
  getAllWithOutPublicate: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get("/news/getAllWithOutPublicate", {
      params: {
        page,
        per_page,
      },
    });
  },

  getNewsWhere: (data: any) => {
    return api.post("/news/getAllWhere", data);
  },

  save: (data: any) => {
    return api.post("/news/save", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  publicate: (data: any) => {
    return api.put("/news/publicate", { data });
  },

  delete: (data: any) => {
    return api.delete("/news/delete", { data });
  },

  update: (data: any) => {
    return api.put("/news/update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default newsServices;
