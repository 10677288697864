import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Breadcrumb } from "antd";
import facebook from "../../../src/assets/img/facebook.svg";
import instagram from "../../../src/assets/img/instagram.svg";
import linkedln from "../../../src/assets/img/linkedln.svg";
import twitter from "../../../src/assets/img/twitter.svg";
import whatsapp from "../../../src/assets/img/whatsapp.svg";
import publicidad_uno from "../../../src/assets/img/publicidad_uno.svg";
import banner_principal from "../../../src/assets/img/banner_general_dos.svg";
import { imgNoExist } from "../../helpers/img";

const BannerSecond = ({ news }: any) => {
  const [imgBanner, setImgBanner] = useState<any>();

  const convertImg = async () => {
    let src: any;
    if (typeof news?.imgBanner === "string") {
      setImgBanner(news?.imgBanner);
      return;
    }

    if (news?.imgBanner) {
      const { imgBanner } = news;
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgBanner);
        reader.onload = () => resolve(reader.result);
      });
    }
    const img = new Image();
    img.src = src;
    setImgBanner(img.src);
  };

  useEffect(() => {
    convertImg();
  }, [news.imgBanner]);

  return (
    <>
      <div>
        <div className={styles.container_text_banner}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a>Home</a>
            </Breadcrumb.Item>
            {news?.section && (
              <Breadcrumb.Item>
                <a>{news?.section?.title || "Seccion"}</a>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </div>
        <div
          style={{
            background: `#fff url(${
              imgBanner || imgNoExist
            }) center center/cover no-repeat content-box`,
          }}
          className={styles.container_texto_banner}
        >
          <div>
            <div className={styles.container_title_banner_second}>
              {news?.preTitle && (
                <p className={styles.antetitulo_second}>
                  {news.preTitle || ``}
                </p>
              )}
              {news?.title && (
                <p className={styles.titulo_second}>{news.title}</p>
              )}
            </div>
          </div>
        </div>
        {news?.textImgBanner && (
          <div className={styles.container_text_pie}>
            <div className={styles.line_pie_foto}>
              <div className={styles.container_text_foto}>
                <span className={styles.text_pie_foto}>
                  {news?.textImgBanner}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className={styles.container_banner_second}>
          <div>
            {news?.subTitle && (
              <div className={styles.container_banner_text}>
                <p className={styles.subtitulo_banner}>
                  {news?.subTitle || ``}
                </p>
              </div>
            )}
            <div className={styles.container_share}>
              <span className={styles.text_share}>Compartir</span>
              <img
                src={facebook}
                height={22}
                alt="logo_facebook"
                className={styles.icon_logo}
              />
              <img
                src={instagram}
                height={22}
                alt="logo_instagram"
                className={styles.icon_logo}
              />
              <img
                src={linkedln}
                height={22}
                alt="logo_linkedln"
                className={styles.icon_logo}
              />
              <img
                src={twitter}
                height={22}
                alt="logo_twitter"
                className={styles.icon_logo}
              />
              <img
                src={whatsapp}
                height={22}
                alt="logo_whatsapp"
                className={styles.icon_logo}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerSecond;
