import { useSelector } from "react-redux";
import { showAlertError } from "../../../components/Alerts";
import sectionServices from "../section/services";
import newsConstants from "./constants";
import newsServices from "./services";

const {
  SET_NEWS,
  SET_EDIT_NEWS,
  SET_POST_NEWS,
  SET_NEWS_WITHOUT_PUBLICATE,
  SET_NEWSS,
  LOADING,
  SET_ERROR,
  SET_SECTIONS,
} = newsConstants;

export const setNews = (news: any) => ({
  type: SET_NEWS,
  news,
});

export const setEditNews = (news: any) => ({
  type: SET_EDIT_NEWS,
  news,
});

export const setPostNews = (news: any) => ({
  type: SET_POST_NEWS,
  news,
});

const setNewss = (newss: any) => ({
  type: SET_NEWSS,
  newss,
});

const setNewsWithoutPublicate = (news: any) => ({
  type: SET_NEWS_WITHOUT_PUBLICATE,
  news,
});

const setSections = (sections: any) => ({
  type: SET_SECTIONS,
  sections,
});

const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

export const saveNews = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
      const formData = new FormData();

      Object.keys(data).forEach((d) => {
        formData.append(d, data[d]);
      });

      const response = await newsServices.save(formData);
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const updateNews = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
      const formData = new FormData();
      Object.keys(data).forEach((d) => {
        formData.append(d, data[d]);
      });

      const response = await newsServices.update(formData);
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const getNewss = (dataPage?: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await newsServices.getAll(
        dataPage?.page,
        dataPage?.per_page
      );
      dispatch(setNewss(response.data.response));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const getNewsWithoutPublicate = (
  dataPage?: any,
  callback?: any,
  err?: any
) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await newsServices.getAllWithOutPublicate(
        dataPage?.page,
        dataPage?.per_page
      );
      dispatch(setNewsWithoutPublicate(response.data.response));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const getNewsWhere = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
      const response = await newsServices.getNewsWhere(data);
      dispatch(setNewss(response.data));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const publicateNews = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await newsServices.publicate(data);
      if (response)
        dispatch(getNewsWithoutPublicate({ page: 1, per_page: 999 }));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const deleteNews = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(true));
    try {
      const response = await newsServices.delete(data);
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      dispatch(setLoading(false));
      if (err) err();
    }
  };
};
