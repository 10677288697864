import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  CaretRightOutlined,
  CloseOutlined,
  EditOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Collapse,
  Select,
  Form,
  Input,
  Button,
  Dropdown,
  TreeSelect,
} from "antd";
import Menu from "../../../../components/Menu/index";
import {
  showAlertError,
  showAlertQuestion,
  showAlertSucces,
} from "../../../../components/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { getNewsWhere } from "../../../../redux/dashboard/news/action";
import moment from "moment";
import { getSections } from "../../../../redux/dashboard/section/action";
import {
  getSlider,
  updateSlider,
} from "../../../../redux/dashboard/slider/action";

const Slider = () => {
  const { Panel } = Collapse;
  const { Option } = Select;

  const dispatch = useDispatch();
  const [formSave] = Form.useForm();
  const [statusSelect, setStatusSelect] = useState<any>({
    select0: false,
    select1: false,
    select2: false,
    select3: false,
  });
  const [dataFilter, setDataFilter] = useState<any>({
    publicationDate: null,
    keywords: [],
    section: null,
  });

  const { newss } = useSelector((state: any) => state.news);
  const { sections } = useSelector((state: any) => state.sections);
  const { sliders } = useSelector((state: any) => state.slider);

  useEffect(() => {
    dispatch(getSlider());
    dispatch(getNewsWhere({}));
    dispatch(getSections({ page: 1, per_page: 999 }));
    console.log(newss);
    console.log(sections);
    
  }, []);

  useEffect(() => {
    dispatch(getNewsWhere(dataFilter));
  }, [dataFilter]);

  /* options btn */

  const handleCancel = () => {
    showAlertSucces("Slider", "La edicion del slider ha sido cancelada");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const onHandleSubmitFilter = (value: any) => {
    dispatch(getNewsWhere({ ...dataFilter, ...value }));
  };

  const onHandleSubmit = (value: any, id: any) => {
    showAlertQuestion(
      "¿Estás seguro que desea guardar los cambios en el slider?",
      "Guardar",
      () => {
        dispatch(
          updateSlider(
            { ...value, id },
            () => {
              showAlertSucces(
                "Slider editado!",
                "El slider ha sido editado correctamente."
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            },
            () => {
              showAlertError(
                "Ha ocurrido un error",
                "No se ha podido completar correctamente la acción, intentalo nuevamente."
              );
            }
          )
        );
      }
    );
  };

  return (
    <>
      <Menu>
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          bordered={false}
          expandIconPosition="right"
        >
          {sliders &&
            sliders?.map((slider: any, index: any) => (
              <Panel header={`Slider ${index + 1}`} key={slider.id}>
                <Form
                  onFinish={(value) => onHandleSubmit(value, slider.id)}
                  initialValues={{ news: slider?.news?.id }}
                >
                  <Form.Item
                    name="news"
                    rules={[
                      {
                        required: true,
                        message: "Es necesario seleccionar una noticia",
                      },
                    ]}
                    className={styles.spacing_item}
                  >
                    <Select
                      id="select-dropdown"
                      className={styles.inputs_select}
                      dropdownClassName={styles.dropdown_select}
                      placeholder="Seleccionar noticia 1"
                      suffixIcon={<CaretRightOutlined rotate={90} />}
                      dropdownRender={(menu: any) => (
                        <div>
                          <div className={styles.iconClose}>
                            <CloseOutlined style={{ cursor: "pointer" }} />
                          </div>
                          <div>
                            <Form
                              className={styles.container_search}
                              onFinish={onHandleSubmitFilter}
                            >
                              <Form.Item noStyle name={"title"}>
                                <Input
                                  placeholder="Escribe aquí"
                                  size="middle"
                                  allowClear
                                  className={styles.search_select}
                                  value={dataFilter.title}
                                />
                              </Form.Item>
                              <Form.Item noStyle>
                                <Button
                                  htmlType="submit"
                                  block
                                  className={styles.search_button}
                                >
                                  Buscar
                                </Button>
                              </Form.Item>
                            </Form>
                            <div className={styles.container_selects}>
                              <div className={styles.spacing_select_fecha}>
                                <span className={styles.span_select}>
                                  Fecha de publicación:
                                </span>
                                <Select
                                  placeholder="Cualquiera"
                                  className={styles.select_seccion}
                                  value={dataFilter.publicationDate}
                                  onChange={(e) =>
                                    setDataFilter({
                                      ...dataFilter,
                                      publicationDate: e,
                                    })
                                  }
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Option
                                    className={styles.option_select}
                                    value={7}
                                  >
                                    últimos 7 días
                                  </Option>
                                  <Option
                                    className={styles.option_select}
                                    value={30}
                                  >
                                    últimos 30 días
                                  </Option>
                                  <Option
                                    className={styles.option_select}
                                    value={30 * 6}
                                  >
                                    últimos 6 meses
                                  </Option>
                                  <Option
                                    className={styles.option_select}
                                    value={30 * 12}
                                  >
                                    último año
                                  </Option>
                                </Select>
                              </div>
                              <div className={styles.spacing_select_claves}>
                                <span className={styles.span_select}>
                                  Palabras claves:
                                </span>
                                <Select
                                  mode="tags"
                                  className={styles.select_seccion}
                                  value={dataFilter.keywords}
                                  onChange={(e) =>
                                    setDataFilter({
                                      ...dataFilter,
                                      keywords: e,
                                    })
                                  }
                                ></Select>
                              </div>
                              <div className={styles.spacing_select_seccion}>
                                <span className={styles.span_select}>
                                  Sección:
                                </span>
                                <Select
                                  placeholder="Seleccionar sección"
                                  className={styles.select_seccion}
                                  value={dataFilter.section}
                                  onChange={(e) =>
                                    setDataFilter({ ...dataFilter, section: e })
                                  }
                                >
                                  <Option className={styles.option_select}>
                                    Seleccione una opcion
                                  </Option>
                                  {sections?.data &&
                                    sections.data.map((section: any) => (
                                      <Option
                                        className={styles.option_select}
                                        value={section.id}
                                        key={section.id}
                                      >
                                        {section.title}
                                      </Option>
                                    ))}
                                </Select>
                              </div>
                            </div>
                          </div>
                          <br />
                          {menu}
                        </div>
                      )}
                    >
                      {newss?.data?.map((news: any) => (
                        <Option className={styles.option_select} key={news.id}>
                          <div>
                            <p className={styles.notice_title}>{news.title}</p>
                            <span className={styles.span_select}>
                              {`${news?.section?.title} | ${moment(
                                news.createdAt
                              ).format("LLL")}`}{" "}
                            </span>
                          </div>
                        </Option>
                              ))}
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className={styles.save_button}
                      block
                      htmlType="submit"
                    >
                      Guardar
                    </Button>
                  </Form.Item>
                </Form>
              </Panel>
            ))}
        </Collapse>
      </Menu>
    </>
  );
};

export default Slider;
