import api from "../../../helpers/api";

const legalWarningServices = {
  getAll: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get("/legalWarning/getAll", {
      params: {
        page,
        per_page,
      },
    });
  },

  save: (data: any) => {
    return api.post("/legalWarning/save", { data });
  },

  update: (data: any) => {
    return api.put("/legalWarning/update", {
      data,
    });
  },
};

export default legalWarningServices;
