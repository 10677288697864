import api from "../../../helpers/api";

const announcerServices = {
  getAll: () => {
    return api.get("/announcer/getAll");
  },

  createAnnouncer: (data: any) => {
    console.log(data);
    return api.post("/announcer/createAnnouncer", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  deleteAnnouncer: (data: any) => {
    console.log(data);
    return api.delete("/announcer/deleteAnnouncer", {
      data,
    });
  },

  updateAnnouncer: (data: any) => {
    console.log(data);
    return api.patch("/announcer/editAnnouncer",data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
    });
  },
};

export default announcerServices;
