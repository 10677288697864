import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Form, Input, Button, Alert } from "antd";
import { UserOutlined } from "@ant-design/icons";
import logo from "../../assets/img/logo_img.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { requestRecover } from "../../redux/auth/action";

const Request = () => {
  const [success, setSucces] = useState(false);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { emailRecover } = useSelector((state: any) => state.auth);

  const onFinish = (values: any) => {
    dispatch(
      requestRecover(values.email, () => {
        setSucces(true);
      })
    );
  };

  const reSendToken = () => {
    dispatch(requestRecover(emailRecover));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  if (success) {
    return (
      <>
        <div className={styles.container_1440}>
          <div className={styles.container_login_form}>
            <img src={logo} alt="Logo" className={styles.login_form_logo} />
            <Alert message="Email enviado" type="success" showIcon className={styles.alerta} />
            <Form.Item className={styles.space_item}>
              <span className={styles.login_text}>
                ¿Han pasado 10 minutos y no has recibido el mensaje?
              </span>
            </Form.Item>
            <Form.Item className={styles.space_item}>
              <Link className={styles.login_form_forgot} to="/recover/request">
                <span className={styles.login_text} onClick={reSendToken}>
                  Reenviar
                </span>
              </Link>
            </Form.Item>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container_1440}>
        <div className={styles.container_login_form}>
          <img src={logo} alt="Logo" className={styles.login_form_logo} />

          <Form
            name="login_medicina"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className={styles.login_form}
          >
            <Form.Item>
              <span className={styles.text_recover}>
                Ingrese su dirección de correo electrónico para recibir un
                correo electrónico de recuperación de contraseña.
              </span>
            </Form.Item>
            <Form.Item
              name="email"
              className={styles.space_item}
              rules={[
                { required: true, message: "Por favor introduce tu correo" },
                { type: "email", message: "Introduce un email válido" },
              ]}
              validateStatus={error && "error"}
              help={error || null}
            >
              <Input
                placeholder="Correo electrónico"
                disabled={loading}
                className={styles.inputs_login_form}
                style={{ color: "#ababab" }}
                autoComplete="off"
                prefix={<UserOutlined style={{ color: "#5BBCF7" }} />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className={styles.login_form_submit}
                disabled={loading}
                block
              >
                <span className={styles.login_text}>Enviar</span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Request;
