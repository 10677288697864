import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/img/logo_img.svg";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { updatePasswordWithToken } from "../../redux/auth/action";

const token = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const [success, setSucces] = useState(false);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    const { newPassword, newRepetPassword } = values;
    if (newPassword === newRepetPassword) {
      dispatch(
        updatePasswordWithToken(token, newPassword, () => {
          alert("cambio realizado con exito");
          navigate("/login");
        })
      );
    } else {
      //mostrar error
      alert("las contraseñas no coinciden");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className={styles.container_1440}>
        <div className={styles.container_login_form}>
          <img src={logo} alt="Logo" className={styles.login_form_logo} />

          <Form
            name="login_medicina"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className={styles.login_form}
          >
            <Form.Item>
              <span className={styles.text_recover_password}>
                Si cambia su contraseña anterior, ya no podrá usarla.
              </span>
            </Form.Item>
            <Form.Item
              className={styles.space_item}
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Por favor introduce tu nueva contraseña",
                },
              ]}
              validateStatus={error && "error"}
              help={error || null}
            >
              <Input.Password
                type="password"
                placeholder="Nueva contraseña"
                className={styles.inputs_login_form}
                autoComplete="off"
                prefix={<LockOutlined style={{ color: "#5BBCF7" }} />}
              />
            </Form.Item>
            <Form.Item
              className={styles.space_item}
              name="newRepetPassword"
              rules={[
                {
                  required: true,
                  message: "Por favor repite tu nueva contraseña",
                },
              ]}
            >
              <Input.Password
                type="password"
                placeholder="Repetir nueva contraseña"
                className={styles.inputs_login_form}
                autoComplete="off"
                prefix={<LockOutlined style={{ color: "#5BBCF7" }} />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className={styles.login_form_submit}
                disabled={loading}
                block
              >
                <span className={styles.login_text}>Cambiar</span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default token;
