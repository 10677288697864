import React from "react";
import styles from "./styles.module.scss";
import { Row, Col, Card } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { imgNoExist } from "../../../helpers/img";
import moment from "moment";
import "moment/locale/es";

const ModuleEight = ({ block }: any) => {
  return (
    <>
      <Layout>
        <div className={styles.container_bg}>
          <Content className={styles.container_1060}>
            {block?.title_active && (
              <>
                <span
                  className={styles.title_module}
                  style={{ color: block?.color || "#103783" }}
                >
                  {block?.title || "Modulo 8"}
                </span>
                <div className={styles.line_section}></div>
              </>
            )}

            <Row gutter={[26, 0]}>
              <Col span={16}>
                <div>
                  <div className={styles.container_span}>
                    <span className={styles.text_section}>
                      {block?.new2?.section?.title || `section`}
                    </span>
                    <span className={styles.reading_section}>
                      {moment(block?.new2?.createdAt)
                        .locale("es")
                        .startOf("day")
                        .fromNow() || `Tiempo prueba`}
                    </span>
                  </div>
                  <p className={styles.title}>
                    {block?.new2?.title || `Titulo ejemplo`}
                  </p>
                  <p className={styles.subtitle}>
                    {block?.new2?.subtitle || `Subtitulo ejemplo`}
                  </p>
                  <div className={styles.line_section}></div>
                </div>
                <Row gutter={[23, 0]}>
                  <Col span={12}>
                    <div
                      className={styles.container_video}
                      style={{
                        backgroundImage: `url(${
                          block?.new1?.imgBanner || imgNoExist
                        })`,
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <div>
                      <div className={styles.container_span}>
                        <span className={styles.text_section}>
                          {block?.new1?.section?.title || `section`}
                        </span>
                        <span className={styles.reading_section}>
                          {moment(block?.new1?.createdAt)
                            .locale("es")
                            .startOf("day")
                            .fromNow() || `Tiempo prueba`}
                        </span>
                      </div>
                      <p className={styles.title_two}>
                        {block?.new1?.title || `Titulo ejemplo`}
                      </p>
                      <p className={styles.subtitle_two}>
                        {block?.new1?.subtitle || `Subtitulo ejemplo`}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Card
                  className={styles.container_card}
                  cover={<img src={imgNoExist} alt="" />}
                >
                  <div className={styles.container_card_text}>
                    <p className={styles.title_card}>
                      Por qué la temporada de gripe podría ser peor este año
                    </p>
                    <p className={styles.text_card}>
                      Lorem ipsum dolor sit amet, consecte adipiscing elit.
                      Vivamus vulputate
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ModuleEight;
