import subSectionConstants from "./constants";

const {
  SET_SUB_SECTION,
  SET_SUB_SECTIONS,
  UPDATE_SUB_SECTION,
  DELETE_SUB_SECTION,
  LOADING,
  SET_ERROR,
} = subSectionConstants;

export const inicialState = {
  subSection: null,
};

const subSectionReducer = (state = inicialState, action: any) => {
  switch (action.type) {
    case SET_SUB_SECTION:
      return {
        ...state,
        subSection: action.subSection,
      };
    case SET_SUB_SECTIONS:
      return {
        ...state,
        subSections: action.subSections,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default subSectionReducer;
