import api from "../../../helpers/api";

const signatureServices = {
  getAll: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get("/signature/getAll", {
      params: {
        page,
        per_page,
      },
    });
  },
  getAllActives: (page?: number, per_page?: number) => {
    if (!page) page = 1;
    if (!per_page) per_page = 15;
    return api.get("/signature/getAllActives", {
      params: {
        page,
        per_page,
      },
    });
  },
  save: (data: any) => {
    return api.post("/signature/save", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  delete: (data: any) => {
    return api.delete("/signature/delete", {
      data,
    });
  },
  update: (data: any) => {
    return api.put("/signature/update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default signatureServices;
