import sliderConstants from "./constants";
import sliderServices from "./services";

const { SET_SLIDER, SET_SLIDERS, LOADING, SET_ERROR } = sliderConstants;

export const setSlider = (slider: any) => ({
  type: SET_SLIDER,
  slider,
});

export const setSliders = (sliders: any) => ({
  type: SET_SLIDERS,
  sliders,
});

export const setError = (error: any | null) => ({
  type: SET_ERROR,
  error,
});

export const setLoading = (loading: boolean) => ({
  type: LOADING,
  loading,
});

export const getSlider = (callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await sliderServices.getAll();
      const { response: res } = response.data;
      dispatch(setSliders(res));
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};

export const updateSlider = (data: any, callback?: any, err?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const response = await sliderServices.update(data);
      if (response) dispatch(getSlider());
      dispatch(setLoading(false));
      if (callback) callback();
    } catch (error) {
      dispatch(setError(error));
      if (err) err();
    }
  };
};
