import sectionConstants from "./constants";

const {
  SET_SECTION,
  SET_SECTIONS,
  UPDATE_SECTION,
  DELETE_SECTION,
  LOADING,
  SET_ERROR,
} = sectionConstants;

export const initialState = {
  sections: null,
};

const sectionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SECTION:
      return {
        ...state,
        section: action.section,
      };
    case SET_SECTIONS:
      return {
        ...state,
        sections: action.sections,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default sectionReducer;
