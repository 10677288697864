import React, { Fragment, useEffect, useRef, useState } from "react";
import "moment/locale/es";
import moment from "moment";
import { Row, Col, Card } from "antd";
import Parser from "html-react-parser";
import Related from "../Related/Related";
import styles from "./styles.module.scss";
import { imgNoExist } from "../../helpers/img";

const Content = ({ news }: any) => {
  const divContent = useRef<any>();

  return (
    <>
      <div className={styles.container_content}>
        <Row>
          <Row gutter={[20, 20]}>
            <Col md={24} lg={18} xl={18} xxl={18}>
              <div className={styles.container_text}>
                <div className={styles.container_firma_general}>
                  <div>
                    <p className={styles.firma}>
                      Por{" "}
                      <span className={styles.underline}>
                        {news?.signature1?.fullName || `Firma 1`}
                      </span>
                      {news?.signature2 && (
                        <span className={styles.underline}>
                          {", "}
                          {news?.signature2?.fullName || `Firma 2`}
                        </span>
                      )}
                      {news?.signature3 && (
                        <span className={styles.underline}>
                          {", "}
                          {news?.signature3?.fullName || `Firma 3`}
                        </span>
                      )}
                    </p>
                    <span className={styles.firma_noticia}>
                      {moment(news?.createdAt).format("LL")}
                    </span>
                  </div>
                  <span className={styles.firma_noticia}>
                    {moment(news?.createdAt)
                      .locale("es")
                      .startOf("day")
                      .fromNow() || `Lectura: 5 minutos`}
                  </span>
                </div>
                <div id={styles.container_parrafos} ref={divContent}>
                  {news?.text && Parser(news?.text)}

                  {/* file */}
                  {news?.file && (
                    <p className={styles.underline}>
                      <a href={news?.file} target="_blank" rel="noreferrer">
                        Click Aquí para descargar contenido
                      </a>
                    </p>
                  )}

                  <hr className={styles.hr_spacing} />
                </div>
              </div>
            </Col>
            {/* Relacionadas */}
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <Related />
            </Col>
          </Row>

          {/* te puede interezar */}
          <div className={styles.container_interes}>
            <div className={styles.container_tex_interes}>
              <span className={styles.title_interes}>Te puede interesar</span>
            </div>
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Card
                  className={styles.card_module}
                  cover={<img src={imgNoExist} alt="noticia_relacionada" />}
                >
                  <div className={styles.container_text_card}>
                    <span className={styles.text_card}>
                      Lorem ipsum dolor sit amer, consectetu adipiscing elit
                    </span>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Card
                  className={styles.card_module}
                  cover={<img src={imgNoExist} alt="noticia_relacionada" />}
                >
                  <div className={styles.container_text_card}>
                    <span className={styles.text_card}>
                      Lorem ipsum dolor sit amer, consectetu adipiscing elit
                    </span>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Card
                  className={styles.card_module}
                  cover={<img src={imgNoExist} alt="noticia_relacionada" />}
                >
                  <div className={styles.container_text_card}>
                    <span className={styles.text_card}>
                      Lorem ipsum dolor sit amer, consectetu adipiscing elit
                    </span>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </>
  );
};

export default Content;
